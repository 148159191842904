import { Controller } from '@hotwired/stimulus'

// Resets the form and select2 inputs
export default class extends Controller {
  static targets = ['select2']

  reset () {
    // Reset the form
    this.element.reset()

    // Reset select2 inputs
    this.select2Targets.forEach((select2) => {
      $(select2).trigger('change')
    })
  }
}
