import { Controller } from '@hotwired/stimulus'

import moment from 'moment'

import { withDaterangepicker } from '../src/mixins/with_daterangepicker'

export default class extends Controller {
  static values = {
    defaultStartAmount: { type: Number, default: 1 },
    defaultStartUnits: { type: String, default: 'month' },
    format: { type: String, default: 'DD MMM YYYY' }
  }

  connect () {
    this.daterangepicker = withDaterangepicker(this, this.element, {
      autoUpdateInput: false,
      linkedCalendars: false,
      locale: {
        format: this.formatValue,
        cancelLabel: 'Clear'
      },
      ranges: {
        'Last 1 Week': [moment().subtract(1, 'week'), moment()],
        'Last 1 Month': [moment().subtract(1, 'month'), moment()],
        'Last 3 Months': [moment().subtract(3, 'month'), moment()],
        'Last 6 Months': [moment().subtract(6, 'month'), moment()],
        'Last 1 Year': [moment().subtract(1, 'year'), moment()],
        'All Time': [moment('2012-01-01'), moment()]
      },
      alwaysShowCalendars: true,
      opens: 'left'
    })

    // Handle the Update Input Functions
    this.applyCallback = this.updateInputFromPicker.bind(this)
    this.clearCallback = this.clearInput.bind(this)
    $(this.element).on(
      'apply.daterangepicker', this.applyCallback
    ).on(
      'cancel.daterangepicker', this.clearCallback
    )

    // set the picker to a default of one month ago
    if ($(this.element).val() === '') {
      this.daterangepicker.setStartDate(moment().subtract(this.defaultStartAmountValue, this.defaultStartUnitsValue))
      this.daterangepicker.setEndDate(moment())
    } else {
      // Visually format the provided date
      this.updateInputFromPicker()
    }
  }

  disconnect () {
    // Disconnect the Handle Input Functions
    $(this.element).off(
      'apply.daterangepicker', this.applyCallback
    ).off(
      'cancel.daterangepicker', this.cancelCallback
    )
  }

  updateInputFromPicker () {
    this.updateInput(this.daterangepicker.startDate.format(this.formatValue) + ' - ' + this.daterangepicker.endDate.format(this.formatValue))
  }

  // Applies the value to the input element
  updateInput (value) {
    $(this.element).val(value).trigger('change')
  }

  clearInput () {
    this.updateInput('')
  }
}
