import { Controller } from '@hotwired/stimulus'

import MetisMenu from 'metismenu/dist/metisMenu'

export default class extends Controller {
  connect () {
    // init menu
    this.metismenu = new MetisMenu(this.element)

    // left-side-menu needs to rebuild scrollbar, use connect event
    this.dispatch('connect')
  }

  disconnect () {
    // teardown menu
    this.metismenu.dispose()
  }
}
