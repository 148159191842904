import { Controller } from '@hotwired/stimulus'

import { withSummaryItems } from '../src/mixins/with_summary_items'

export default class extends Controller {
  static targets = ['totalUsers', 'totalGroups']

  connect () {
    withSummaryItems(this)
  }

  update ({ detail: { recordsTotal, totalGroups } }) {
    this.presentSummaryItemNumber(this.totalUsersTarget, recordsTotal)
    this.presentSummaryItemNumber(this.totalGroupsTarget, totalGroups)
  }
}
