import { Controller } from '@hotwired/stimulus'

import FileInput from '@uppy/file-input'

import { uppyS3StorageInstance, uppyLocalStorageInstance, uploadedS3FileData, uploadedLocalFileData } from '../src/mixins/uppy'

export default class extends Controller {
  static targets = ['result', 'uppyContainer', 'uploadButton']
  static values = {
    server: Boolean
  }

  connect () {
    this.uploadButtonTarget.disabled = true
    this.uppy = this.createUppy()
  }

  disconnect () {
    this.uppy.close()
  }

  createUppy () {
    const uppyId = this.uppyContainerTarget.id
    let uppy
    if (this.serverValue) {
      uppy = uppyS3StorageInstance(uppyId)
        .on('upload-success', (file, response) => {
        // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
          this.resultTarget.value = uploadedS3FileData(file, response, this.serverValue)
          this.uploadButtonTarget.disabled = false
        })
    } else {
      uppy = uppyLocalStorageInstance(uppyId)
        .on('upload-success', (file, response) => {
        // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
          this.resultTarget.value = uploadedLocalFileData(file, response, this.serverValue)
          this.uploadButtonTarget.disabled = false
        })
    }

    uppy.use(FileInput, {
      target: '#' + uppyId,
      locale: { strings: { chooseFiles: 'Choose file' } }
    })

    return uppy
  }

  logUppyFile (e) {
    const file = e.target.files[0]
    this.uppy.addFile({
      source: 'file input',
      name: file.name,
      type: file.type,
      data: file
    })
  }
}
