/* global $ */
import { Controller } from '@hotwired/stimulus'

import '../../src/vendor/jquery-ui'

const ID_INDEX_REGEX = /_([0-9]+)_/
const NAME_INDEX_REGEX = /\[[0-9]+\]/

/**
 * Adds a new procedure steps to the form.
 *
 * Assumes an initial procedure step is always present in the form.
 */
export default class extends Controller {
  static classes = ['handler', 'placeholder']
  static targets = ['list', 'procedureStep', 'stepIndex']

  connect () {
    // Duplicate the last step in the form
    this.stepTemplate = $(this.procedureStepTargets.slice(-1)[0])
      .clone()

    // Reset all all form values
    $(this.stepTemplate)
      .find('select, input, textarea')
      .each((_idx, el) => {
        $(el).val('')
      })

    // Remove the data attributes that are used by the removeable_node controller
    $(this.stepTemplate).removeAttr('data-removeable-node-url-value')

    // Remove any errors
    $(this.stepTemplate).find('.invalid-feedback').remove()
    $(this.stepTemplate).find('.is-invalid, .form-group-invalid, [aria-invalid]').each(function () {
      $(this).removeClass('is-invalid')
        .removeClass('invalid-feedback')
        .removeAttr('aria-invalid')
    })

    // Hide the step template by default
    $(this.stepTemplate).hide()

    // Calculate the next index number
    let nextStepIndex = 100
    $(this.element).find('.duplicatable-procedure-step input').each(function () {
      const stepId = parseInt($(this).attr('id').match(ID_INDEX_REGEX)[1])
      nextStepIndex = Math.max(stepId + 1, 100)
    })
    this.nextStepIndex = nextStepIndex

    // Make the listTarget sortable
    $(this.listTarget).sortable({
      handle: `.${this.handlerClass}`,
      opacity: 0.8,
      placeholder: this.placeholderClass,
      start: function (event, ui) {

      },
      stop: function (event, ui) {

      }
    })

    this.reorderStepIndexes()
  }

  addStep (e) {
    e.preventDefault()

    // Clone the step template
    const newStep = this.$stepTemplate.clone()

    // Calculate a new unique index number
    const newStepIndex = this.nextNewStepIndex

    // Update all input ids and names to use the new index
    $(newStep)
      .find('select, input, textarea')
      .each(function () {
        const oldId = $(this).attr('id')
        const newId = oldId.replace(ID_INDEX_REGEX, '_' + newStepIndex + '_')
        $(this).attr('id', newId)
        const oldName = $(this).attr('name')
        const newName = oldName.replace(NAME_INDEX_REGEX, '[' + newStepIndex + ']')
        $(this).attr('name', newName)
      })

    // Update all labels to point to the new input ids
    $(newStep)
      .find('label')
      .each(function () {
        const oldLabel = $(this).attr('for')
        const newLabel = oldLabel.replace(ID_INDEX_REGEX, '_' + newStepIndex + '_')
        $(this).attr('for', newLabel)
      })

    // Add the new step to the form
    $(this.listTarget).append(newStep)

    // Display the new step
    $(newStep).slideDown(120, () => {
      // Reorder the indexes
      this.reorderStepIndexes()
    })
  }

  // Procedure Step was removed or reordered
  stepIndexTargetDisconnected (e) {
    // Reorder the indexes
    this.reorderStepIndexes()
  }

  // Procedure Step was added
  procedureStepTargetConnected (e) {
    // Use a timeout to ensure the element is added to the DOM before we update the removeable nodes
    setTimeout(this.updateRemoveableNodes.bind(this), 0)
  }

  // Procedure Step was removed
  procedureStepTargetDisconnected (e) {
    // Use a timeout to ensure the element is removed from the DOM before we update the removeable nodes
    setTimeout(this.updateRemoveableNodes.bind(this), 0)
  }

  // Enables/Disables the remove button for each step
  updateRemoveableNodes () {
    if (this.procedureStepTargets.length > 1) {
      // Enable the remove button for each step
      this.procedureStepTargets.forEach((step) => {
        this.dispatch('enableRemove', { target: step, detail: {} })
      })
    } else {
      // Disable the remove button for each step
      this.procedureStepTargets.forEach((step) => {
        this.dispatch('disableRemove', { target: step, detail: {} })
      })
    }
  }

  get $stepTemplate () {
    return $(this.stepTemplate)
  }

  get nextNewStepIndex () {
    const nextIndex = this.nextStepIndex

    // Increment the next index
    this.nextStepIndex += 1

    return nextIndex
  }

  reorderStepIndexes () {
    this.stepIndexTargets.forEach((input, index) => {
      input.value = index
    })
  }
}
