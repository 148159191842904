import { Controller } from '@hotwired/stimulus'

import { initDisplayMap } from '../src/init/curo-displaymap'

export default class extends Controller {
  static targets = ['displayMap']

  connect () {
    /* Setup the Display Map */
    this.displayMap = initDisplayMap(this.displayMapTarget, { paths: [], points: [], polygons: [] })
  }

  update ({ detail: { displayPoints } }) {
    this.displayMap.clearPoints()

    displayPoints.forEach((point) => {
      this.displayMap.addPoint({
        title: point.title,
        description: point.description,
        lat: point.lat,
        lng: point.lng
      })
    })

    this.displayMap.zoomToBounds()
  }
}
