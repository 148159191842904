import { Controller } from '@hotwired/stimulus'

/**
 * Broadcasts the Dock Status to any listeners
 */
export default class extends Controller {
  static targets = ['listener']

  broadcastStatus ({ detail }) {
    // Broadcast the Dock Status to any listeners
    this.listenerTargets.forEach(listener => {
      this.dispatch('statusUpdate', { target: listener, detail })
    })
  }
}
