import { Controller } from '@hotwired/stimulus'

import { digProperty } from '../src/mixins/dig'

// Displays an (optional) string message with an (optional) percent value from a Live Drone or Live Remote
export default class extends Controller {
  static values = {
    messageField: String,
    percentField: String,
    default: String
  }

  connect () {}

  disconnect () {}

  update ({ detail }) {
    const messageValue = digProperty(this.messageFieldValue, detail)
    const percentValue = digProperty(this.percentFieldValue, detail)

    const output = []
    messageValue && output.push(messageValue)
    if (percentValue) {
      if (output.length > 0) {
        output.push(`(${percentValue}%)`)
      } else {
        output.push(`${percentValue}%`)
      }
    }

    if (output.length > 0) {
      this.updateFromValue(output.join(' '))
    } else {
      this.updateFromValue(this.defaultValue)
    }
  }

  updateFromValue (message) {
    this.element.innerHTML = message
  }
}
