import { Controller } from '@hotwired/stimulus'

import 'jquery-slimscroll/jquery.slimscroll'

import { useDebounce, useResize } from 'stimulus-use'

export default class extends Controller {
  static debounces = ['reload']
  static targets = ['window']
  static values = {
    height: { type: String, default: 'auto' },
    wheelStep: { type: Number, default: 20 },
    touchScrollStep: { type: Number, default: 20 },
    width: { type: String, default: 'auto' }
  }

  connect () {
    // prevent spamming of the slimscroll menu reload
    useDebounce(this)

    // rebuild the slimscroll menu when the container changes size
    useResize(this)

    // reload the slimscroll window
    this.reload()
  }

  disconnect () {
    // teardown the controller
    $(this.windowTarget).slimScroll({ destroy: true })
  }

  // rebuild the slimscroll menu when the container changes size
  resize () {
    this.reload()
  }

  // reload the slimscroll window (try to do this when the window content changes)
  reload () {
    $(this.windowTarget).slimScroll({
      height: this.heightValue,
      position: 'right',
      size: '4px',
      color: '#9ea5ab',
      wheelStep: this.wheelStepValue,
      touchScrollStep: this.touchScrollStepValue,
      width: this.widthValue
    })
  }
}
