/* global $ */
import { Controller } from '@hotwired/stimulus'

/**
 * Wraps Bootstrap's tooltip component.
 *
 * Requires each element to have a `title` attribute.
 */
export default class extends Controller {
  connect () {
    $(this.element).tooltip()
  }

  disconnect () {
    $(this.element).tooltip('dispose')
  }

  hide () {
    $(this.element).tooltip('hide')
  }
}
