import { Controller } from '@hotwired/stimulus'

import { Turbo } from '@hotwired/turbo-rails'

// Connects to data-controller="turbo-redirect"
export default class extends Controller {
  static values = { url: String }

  connect () {
    Turbo.cache.clear()
    Turbo.visit(this.urlValue, { action: 'advance' })
  }
}
