import { GoogleMapsOverlay } from '@deck.gl/google-maps'
import { buildMapboxLayer } from './mapbox-layer'
import { buildGeoJSONLayer } from './geojson-layer'
import { incidentsUrlGenerator, mapboxUrlGenerator, rpasAdvisoriesUrlGenerator, rpasHeliportsUrlGenerator, notificationsUrlGenerator } from './url'
import moment from 'moment-timezone'
const Mustache = require('mustache')

// Array of [ layer_id, displayName ]
const OPERATION_TYPES = [
  ['reoc', 'ReOC'],
  ['excluded_rpa', 'Excluded RPA'],
  ['recreational', 'Recreational']
]

const DEFAULT_OPERATION_TYPE = 'excluded_rpa'

const NOTIFICATIONS_MENU_TEMPLATE = `
  <div class="pre-scrollable px-4 text-muted">
    {{#notifications}}
    <div class="py-3 border-bottom">
      <h6 class="mt-0 mb-0">
        {{#url}}
        <a href="{{url}}">{{title}} <i class="uil uil-external-link-alt"></i></a>
        {{/url}}
        {{^url}}
        {{title}}
        {{/url}}
      </h6>
      <p class="mt-2">{{description}}</p>
      <p class="mt-2 mb-0 text-muted small">Valid from: <span data-controller="datetime-moment datetime-tooltip" data-datetime-moment-format-value="YYYY MMM D HH:mm" datetime={{valid_from}}></p>
      <p class="m-0 text-muted small">Valid to: <span data-controller="datetime-moment datetime-tooltip" data-datetime-moment-format-value="YYYY MMM D HH:mm" datetime={{valid_to}}></p>
    </div>
    {{/notifications}}
  </div>
`

// Fix that dropdown will disappear when click content inside.
const fixBootstrapDropdownOnMap = function ($el) {
  $el.on('click', '.google-control.dropdown-menu', function (e) {
    e.stopPropagation()
  })
}

// Overlay for displaying a Mapbox Layer
class MapboxLayer {
  constructor (layerName, displayName) {
    this.visible = true
    this.layerName = layerName
    this.displayName = displayName
  }

  isVisible () {
    return this.visible
  }

  isHidden () {
    return !this.visible
  }

  show () {
    this.visible = true
  }

  hide () {
    this.visible = false
  }
}

// Overlay for displaying a GEOJSON Map Layer
class GeoJSONLayer {
  constructor (map, layerName, displayName, urlGenerator) {
    this.map = map
    this.layerName = layerName
    this.displayName = displayName
    this.urlGenerator = urlGenerator
    this.visible = true
  }

  show () {
    if (this.mapOverlay) this.mapOverlay.setMap(this.map)
    this.visible = true
  }

  hide () {
    if (this.mapOverlay) this.mapOverlay.setMap(null)
    this.visible = false
  }

  update (advisoryHost, operationType) {
    // NOTE: use finalize() if the overlay should be permanently removed
    // https://deck.gl/docs/api-reference/google-maps/google-maps-overlay
    if (this.mapOverlay) this.mapOverlay.finalize()

    const url = this.urlGenerator(advisoryHost, operationType)
    this.mapOverlay = new GoogleMapsOverlay({
      layers: [buildGeoJSONLayer(url, { id: this.layerName + '-layer' })],
      getTooltip: ({ object }) => object && object.properties.title && {
        html: `<div>${object.properties.title}</div>`,
        style: {
          width: '180px',
          fontSize: '0.85em',
          borderRadius: '2px'
        }
      }
    })

    if (this.visible) this.mapOverlay.setMap(this.map)
  }
}

const buildLayerCheckbox = function (layerName, displayName, clickHandler) {
  const inputId = inputIdFromLayerName(layerName)

  const checkbox = document.createElement('input')
  checkbox.type = 'checkbox'
  checkbox.checked = 'true'
  checkbox.id = inputId
  checkbox.name = layerName
  checkbox.setAttribute('data-layer', layerName)

  const label = document.createElement('label')
  label.className = 'm-0'
  label.innerText = displayName
  label.setAttribute('for', inputId)

  const row = document.createElement('div')
  row.className = 'row mb-2'

  const colInput = document.createElement('div')
  colInput.className = 'col-2'

  const colLabel = document.createElement('div')
  colLabel.className = 'col-10'

  colInput.append(checkbox)
  colLabel.append(label)
  row.append(colInput)
  row.append(colLabel)

  $(checkbox).on('click', clickHandler)

  return row
}

const inputIdFromLayerName = function (layerName) {
  return 'input-' + layerName + '-layer'
}

export const FlightAdvisoryOverlay = class {
  constructor ($el, { map, mapboxAccessToken, advisoryHost, operationType, isStandalone }) {
    this.$el = $el
    this.map = map
    this.isStandalone = isStandalone
    this.isStaging = $('.is-staging').length > 0

    this.mapboxAccessToken = mapboxAccessToken
    this.advisoryHost = advisoryHost
    this.operationType = operationType || DEFAULT_OPERATION_TYPE

    this.mapboxLayers = {}
    this.geojsonLayers = {}

    for (const [layerName, displayName] of this.mapbox_metadata()) {
      this.mapboxLayers[layerName] = new MapboxLayer(layerName, displayName)
    }

    for (const [layerName, displayName, urlGenerator] of this.geojson_metadata()) {
      this.geojsonLayers[layerName] = new GeoJSONLayer(map, layerName, displayName, urlGenerator)
    }

    this.initMapLayersDropdown($el)
    this.initOperationTypesDropdown($el)
    this.initNotificationsDropdown()

    this.reloadMapboxLayers()
    this.reloadGeoJSONLayers()
    this.reloadNotifications()

    fixBootstrapDropdownOnMap($el)

    window.displayNotifications = this.displayNotifications.bind(this)
  }

  reloadMapboxLayers () {
    // NOTE: use finalize() if the overlay should be permanently removed
    // https://deck.gl/docs/api-reference/google-maps/google-maps-overlay
    if (this.mapbox_overlay) this.mapbox_overlay.finalize()

    const visibleLayers = []
    for (const layerName in this.mapboxLayers) {
      if (this.mapboxLayers[layerName].isVisible()) visibleLayers.push(layerName)
    }

    const mapboxUrl = mapboxUrlGenerator(this.mapboxAccessToken, this.operationType, this.isStaging)

    this.mapbox_overlay = new GoogleMapsOverlay({
      layers: [buildMapboxLayer(mapboxUrl, visibleLayers)],
      getTooltip: ({ object }) => object && object.properties.title && {
        html: `<div>${object.properties.title}</div>`,
        style: {
          width: '180px',
          fontSize: '0.85em',
          borderRadius: '2px'
        }
      }
    })

    this.mapbox_overlay.setMap(this.map)
  }

  reloadGeoJSONLayers () {
    for (const layerName in this.geojsonLayers) {
      this.geojsonLayers[layerName].update(this.advisoryHost, this.operationType)
    }
  }

  reloadNotifications () {
    const lastOperationType = this.operationType

    $.ajax({ url: notificationsUrlGenerator(this.advisoryHost, this.operationType) })
      .fail(function (msg) {
        console.log('Unable to retrieve Notifications!')
      })
      .done((results) => {
        // Do not display notifications when the operation type has changed
        if (this.operationType === lastOperationType) {
          this.displayNotifications(results.data)
        }
      })
  }

  operationDate () {
    if (this.isStandalone) {
      return this.$faOpsDate.val()
    }
  }

  // NOTE: Field can be set to empty, just set it to current time if that happens
  operationTime () {
    if (this.isStandalone) {
      let time = this.$faOpsStartTime.val()
      if (!time) {
        time = moment().format('HH:mm')
        this.$faOpsStartTime.val(time)
      }
      return time
    }
  }

  maxAltitude () {
    if (this.isStandalone) {
      return this.$faOpsMaxAltitude.val()
    }
  }

  changeOperationType (operationType) {
    // Trigger the click to update the operation type + events
    this.$el.find('input#' + operationType).click()
  }

  initMapLayersDropdown ($el) {
    const wrapper = document.createElement('div')
    wrapper.className = 'dropdown'

    const gmButton = '<button class="btn btn-default btn-sm btn-gmaps google-control dropdown-toggle" id="curo-map-layers" data-toggle="dropdown" aria-expanded="false">Map</button>'

    $(wrapper).append(gmButton)

    const mainContent = document.createElement('div')
    mainContent.className = 'google-control dropdown-menu dropdown-menu-advisory-layers'
    mainContent.setAttribute('aria-labelledby', 'curo-map-layers')
    wrapper.append(mainContent)
    const layerAdded = []

    // Add Mapbox Layers
    for (const layerName in this.mapboxLayers) {
      const mapboxLayer = this.mapboxLayers[layerName]

      mainContent.append(buildLayerCheckbox(mapboxLayer.layerName, mapboxLayer.displayName, this.handleClickLayerCheckbox.bind(this)))
      layerAdded.push(mapboxLayer.layerName)
    }

    // Add GeoJSON layers
    for (const layerName in this.geojsonLayers) {
      // Prevent adding duplicated layer select option
      if (layerAdded.includes(layerName)) continue

      const geojsonLayer = this.geojsonLayers[layerName]
      mainContent.append(buildLayerCheckbox(geojsonLayer.layerName, geojsonLayer.displayName, this.handleClickLayerCheckbox.bind(this)))
    }

    $(mainContent).prepend(this.buildLayerToggleAllButtons())
    $(mainContent).prepend(this.buildMapTypeSelect())

    // Add menu to Map
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(wrapper)

    // Add events when show/hide the dropdown
    $(wrapper).on('show.bs.dropdown', () => { $el.trigger('flight-advisory-hide') })
    $(wrapper).on('hide.bs.dropdown', () => { $el.trigger('flight-advisory-resume') })
  };

  initOperationTypesDropdown ($el) {
    const div = document.createElement('div')

    const button = document.createElement('button')
    button.className =
      'btn btn-default btn-sm btn-gmaps google-control dropdown-toggle'
    button.id = 'curo-map-operation-type'
    button.setAttribute('data-toggle', 'dropdown')
    button.innerText = 'Operation'

    div.append(button)
    div.className = 'dropdown'

    const opsTypeDropSelect = document.createElement('div')
    opsTypeDropSelect.className = 'google-control dropdown-menu dropdown-menu-operation-type'
    opsTypeDropSelect.setAttribute('aria-labelledby', 'curo-map-operation-type')

    for (const [opType, opName] of OPERATION_TYPES) {
      const radio = document.createElement('input')
      radio.type = 'radio'
      radio.checked = (opType === this.operationType)
      radio.id = opType
      radio.value = opType
      radio.name = 'operation_type'

      const label = document.createElement('label')
      label.className = 'm-0'
      label.innerText = opName
      label.setAttribute('for', opType)

      const row = document.createElement('div')
      row.className = 'row mb-2'

      const colInput = document.createElement('div')
      colInput.className = 'col-2'

      const colLabel = document.createElement('div')
      colLabel.className = 'col-10'

      colInput.append(radio)
      colLabel.append(label)
      row.append(colInput)
      row.append(colLabel)

      opsTypeDropSelect.append(row)

      $(radio).on('click', (e) => {
        this.handleClickOperationTypeRadio(e)
        $el.trigger('flight-advisory-reload')
      })
    }

    if (this.isStandalone) {
      const opsOption = document.createElement('div')
      opsOption.classList.add('mt-4')

      $(opsOption).html(this.buildDatetimeHtml())

      this.$faOpsMaxAltitude = $(opsOption).find('#faOpsMaxAltitude')
      this.$faOpsDate = $(opsOption).find('#faOpsDate')
      this.$faOpsStartTime = $(opsOption).find('#faOpsStartTime')

      opsTypeDropSelect.append(opsOption)
    }

    div.append(opsTypeDropSelect)

    $(div).on('show.bs.dropdown', () => {
      $el.trigger('flight-advisory-hide')
    })

    $(div).on('hide.bs.dropdown', () => {
      $el.trigger('flight-advisory-reload')
      $el.trigger('flight-advisory-resume')
    })

    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(div)
  }

  initNotificationsDropdown () {
    const notificationsDropdown = document.createElement('div')
    notificationsDropdown.className = 'dropdown'

    const button = document.createElement('button')
    button.className =
      'btn btn-default btn-sm btn-gmaps google-control dropdown-toggle'
    button.id = 'curo-map-notifications'
    button.setAttribute('data-toggle', 'dropdown')
    button.innerText = 'Notifications'

    notificationsDropdown.append(button)

    const notificationsMenu = document.createElement('div')
    notificationsMenu.className = 'google-control dropdown-menu p-0'
    notificationsMenu.setAttribute('aria-labelledby', 'curo-map-notifications')
    notificationsDropdown.append(notificationsMenu)

    this.notificationsMenu = notificationsMenu
    this.notificationsDropdown = notificationsDropdown

    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(notificationsDropdown)

    $(notificationsDropdown).hide()
  }

  handleShowAllLayers () {
    // Geojson layers
    const geojsonLayers = Object.values(this.geojsonLayers)
    geojsonLayers.map(layer => layer.show())

    // Mapbox layers
    const mapboxLayers = Object.values(this.mapboxLayers)
    const hasHiddenMapboxLayer = mapboxLayers.some(e => e.isHidden()) // Remove unnecessary reload

    if (hasHiddenMapboxLayer) {
      mapboxLayers.map(layer => layer.show())
      this.reloadMapboxLayers()
    }

    // Check all the overlays
    $(".dropdown-menu-advisory-layers input[type='checkbox']").each(function () {
      $(this).prop('checked', true)
    })
  }

  handleHideAllLayers () {
    // Geojson layers
    const geojsonLayers = Object.values(this.geojsonLayers)
    geojsonLayers.map(layer => layer.hide())

    // Mapbox layers
    const mapboxLayers = Object.values(this.mapboxLayers)
    mapboxLayers.map(layer => layer.hide())
    this.reloadMapboxLayers()

    // Uncheck all the overlays
    $(".dropdown-menu-advisory-layers input[type='checkbox']").each(function () {
      $(this).prop('checked', false)
    })
  }

  handleClickLayerCheckbox (e) {
    const layerName = $(e.target).data('layer')
    const showLayer = $(e.target).prop('checked')

    const mapboxLayer = this.mapboxLayers[layerName]
    const geojsonLayer = this.geojsonLayers[layerName]

    if (showLayer) {
      if (mapboxLayer) { mapboxLayer.show() }
      if (geojsonLayer) { geojsonLayer.show() }
    } else {
      if (mapboxLayer) { mapboxLayer.hide() }
      if (geojsonLayer) { geojsonLayer.hide() }
    }

    if (mapboxLayer) this.reloadMapboxLayers()
    if (geojsonLayer) this.reloadGeoJSONLayers()
  }

  handleMapTypeRadio (mapId) {
    this.map.setMapTypeId(mapId)
  }

  handleClickOperationTypeRadio (e) {
    this.operationType = e.target.value
    this.reloadMapboxLayers()
    this.reloadGeoJSONLayers()
    this.reloadNotifications()
  }

  // Notifications
  displayNotifications (rawNotifications) {
    const notifications = rawNotifications.map(function (n) { return n.attributes })

    this.notificationsMenu.innerHTML = Mustache.render(NOTIFICATIONS_MENU_TEMPLATE, { notifications })

    // Show/Hide depending on Notifications Count
    if (notifications.length === 0) {
      $(this.notificationsDropdown).fadeOut('fast')
    } else {
      $(this.notificationsDropdown).slideDown('fast')
    }
  }

  // Array of [ layerName, displayName ]
  mapbox_metadata () {
    return [
      ['airspaces', 'Airspaces'],
      ['controlled_aerodromes', 'Controlled Aerodromes'],
      ['uncontrolled_aerodromes', 'Uncontrolled Aerodromes'],
      ['heliports', 'Heliports'],
      ['national_parks', 'Marine Parks'],
      ['power_lines', 'Power Lines']
    ]
  }

  geojson_metadata () {
    const layers = [
      ['heliports', 'Heliports', rpasHeliportsUrlGenerator],
      ['incidents', 'Incidents', incidentsUrlGenerator],
      ['rpas_advisories', 'Advisories', rpasAdvisoriesUrlGenerator]
    ]
    return layers
  }

  buildDatetimeHtml () {
    const currentTime = moment().format('h:mm A')
    const currentDate = moment().format('YYYY-MM-DD')

    return `
      <div class="form-group row align-items-center">
        <label for="faOpsMaxAltitude" class="col-sm-4 col-form-label"><abbr title="Maximum Altitude">Max Alt</abbr></label>
        <div class="col-sm-8">
          <input type="number" class="form-control form-control-sm" id="faOpsMaxAltitude" value="120" placeholder="Max Altitude AGL in meters">
          <small class="text-muted">Meters Above Ground Level</small>
        </div>
      </div>
      <small class="form-text text-muted">Planned Date/Time of Operation</small>
      <div class="form-group row align-items-center mb-0">
        <label for="faOpsDate" class="col-sm-4 col-form-label">Date</label>
        <div class="col-sm-8">
          <input required class="form-control form-control-sm" data-controller="datepicker" id="faOpsDate" value="${currentDate}" placeholder="Operation Date">
        </div>
      </div>
      <div class="form-group row align-items-center mb-0">
        <label for="faOpsStartTime" class="col-sm-4 col-form-label">Time</label>
        <div class="col-sm-8">
          <input required class="form-control form-control-sm" autocomplete="false" data-controller="timepicker" value="${currentTime}" id="faOpsStartTime" placeholder="Operation Time">
        </div>
      </div>
    `
  }

  buildLayerToggleAllButtons () {
    const buttonGroup = `
      <h6 class='mt-4'>Airspace Layer</h6>
      <div class="btn-group w-100 mb-2" role="group" aria-label="map-type-select">
        <button type="button" id="overlay-show-all" class="btn btn-default btn-xs">All</button>
        <button type="button" id="overlay-hide-all" class="btn btn-default btn-xs">None</button>
      </div>
    `
    const wrapper = document.createElement('div')
    wrapper.innerHTML = buttonGroup

    $(wrapper).on('click', '#overlay-hide-all', this.handleHideAllLayers.bind(this))
    $(wrapper).on('click', '#overlay-show-all', this.handleShowAllLayers.bind(this))

    return wrapper
  }

  buildMapTypeSelect () {
    const buttonGroup = `
    <h6 class='mt-0'>Map Base</h6>
    <div class="btn-group w-100" role="group" aria-label="gmap-type-select" id='gmap-type-select'>
      <button type="button" data-gmap-id='hybrid' class="btn btn-default btn-xs active">Satellite</button>
      <button type="button" data-gmap-id='roadmap' class="btn btn-default btn-xs">Roadmap</button>
      <button type="button" data-gmap-id='terrain' class="btn btn-default btn-xs">Terrain</button>
    </div>
    `

    const wrapper = document.createElement('div')
    wrapper.innerHTML = buttonGroup

    $(wrapper).on('click', 'button', this.handleMapBaseType.bind(this))

    return wrapper
  }

  handleMapBaseType (e) {
    const googleMapId = $(e.target).data('gmapId')
    this.map.setMapTypeId(googleMapId)

    $('#gmap-type-select button').removeClass('active')
    $(e.target).addClass('active')
  }
}
