import {
  getGeodesicDistance,
  getSeparationAltitude,
  getCartographicEntityPosition
} from '../../helpers/cesium_helper'

// TODO: Moving this into use_adsb_layer.js
export const useAdsbDataSourceTable = (controller, { cesiumViewer, dataSourceId, toolbar }) => {
  let tableItems = []
  let tableContainer
  let toggleButton
  let table
  let sepTargetEntity
  const tableFilter = {}

  const initTable = () => {
    let cssDisplay = 'none'
    if (tableContainer) {
      cssDisplay = tableContainer.css('display')
      tableContainer.remove()
      toggleButton.remove()
    }

    toggleButton = $('<div class="cesium-button adsb-table-control-button"><i class="uil uil-list-ul"></i></div>')
    toolbar.prepend(toggleButton)

    const filterHtml = `
      <div class="bg-dark adsb-filter-container p-2">
        <p class='mb-2 text-center text-white' >Show aircraft within</p>

        <div class="adsb-search-container">
          <div class="row">
            <div class="col-sm-6">
              <div class="input-group input-group-sm mb-3">
                <input type="text" id="adsb-altitude-sep-filter-input" class="form-control" placeholder="Altitude Sep. (m)" aria-label="Altitude Sep">
                <div class="input-group-append">
                  <button id="adsb-clear-altitude-btn" class="btn btn-outline-light" type="button">X</button>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="input-group input-group-sm mb-3">
                <input type="text" id="adsb-distance-sep-filter-input" class="form-control" placeholder="Distance Sep. (m)" aria-label="Distance Sep">
                <div class="input-group-append">
                  <button id="adsb-clear-distance-btn" class="btn btn-outline-light" type="button">X</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      `

    const sepHeaders =
      `<th scope="col" class='text-center'>Alt.Sep (m)</th>
      <th scope="col" class='text-center'>Dist.Sep (m)</th>`

    const dataTableHtml =
      `<div class='adsb-table-container' style='
      display: ${cssDisplay}'>
      ${sepTargetEntity ? filterHtml : ''}
      <table class='table table-hover table-sm table-dark '>
        <thead>
          <tr class='adsb-row-label-caption'>
            <th scope="col" class='text-center'></th>
            <th scope="col" class='text-center'>ICAO </th>
            <th scope="col" class='text-center'>Altitude (m)</th>
            <th scope="col" class='text-center'>H.Vel (m/s)</th>
            <th scope="col" class='text-center'>V.Vel (m/s)</th>
            ${sepTargetEntity ? sepHeaders : ''}
          </tr>
        </thead>
        <tbody>
        </tbody>
      </table>
    </div>`

    tableContainer = $(dataTableHtml)
    toolbar.append(tableContainer)
    table = tableContainer.find('table')

    toggleButton.on('click', () => {
      tableContainer.toggle()
    })

    const filterAltitudeInput = toolbar.find('#adsb-altitude-sep-filter-input')
    const filterDistanceInput = toolbar.find('#adsb-distance-sep-filter-input')
    const clearAltitudeButton = toolbar.find('#adsb-clear-altitude-btn')
    const clearDistanceButton = toolbar.find('#adsb-clear-distance-btn')

    filterAltitudeInput.on('input', function (e) {
      if (e.target.value.length > 0) {
        tableFilter.sepAltitude = e.target.value
      } else {
        filterAltitudeInput.val(null)
        clearFilterResult('sepAltitude')
        updateTable()
      }
    })

    filterDistanceInput.on('input', function (e) {
      if (e.target.value.length > 0) {
        tableFilter.sepDistance = e.target.value
      } else {
        clearFilterResult('sepDistance')
        updateTable()
      }
    })

    filterAltitudeInput.keypress(function (event) {
      const keycode = (event.keyCode ? event.keyCode : event.which)
      if (keycode === 13) {
        updateTable()
      }
    })

    filterDistanceInput.keypress(function (event) {
      const keycode = (event.keyCode ? event.keyCode : event.which)
      if (keycode === 13) {
        updateTable()
      }
    })

    clearDistanceButton.on('click', (e) => {
      filterDistanceInput.val(null)
      clearFilterResult('sepDistance')
      updateTable()
    })

    clearAltitudeButton.on('click', (e) => {
      filterAltitudeInput.val(null)
      clearFilterResult('sepAltitude')
      updateTable()
    })
  }

  const clearFilterResult = (col) => {
    delete tableFilter[col]

    if (!Object.values(tableFilter).length > 0) {
      const source = cesiumViewer.dataSources.getByName(dataSourceId)
      if (source.length === 0) {
        return
      }
      const entities = source[0].entities.values
      entities.forEach((entity) => { entity.show = true })
    }
  }

  const updateTable = () => {
    const dataSource = cesiumViewer.dataSources.getByName(dataSourceId)

    const tableBody = table.find('tbody')
    const entities = dataSource[0].entities.values
    const newTableItems = []
    entities.forEach((entity) => {
      if (entity.isAvailable(cesiumViewer.clock.currentTime)) {
        newTableItems.push(createTableItem(entity))
      }
    })

    if (sepTargetEntity) { newTableItems.sort(sortByAttribute('sepDistance')) }

    if (arraysEqual(newTableItems, tableItems)) {
      tableItems = newTableItems
      tableItems.forEach((item) => {
        updateTableValues(item)
      })
    } else {
      tableItems = newTableItems
      tableBody.children().remove()
      tableItems.forEach((item) => {
        const rowItem = createTableRow(item)
        tableBody.append(rowItem)
      })
    }
  }

  const createTableItem = (entity) => {
    const entityValues = entity.properties.getValue('', cesiumViewer.clock.currentTime)

    const item = {
      id: entity.id,
      altitude: entityValues.altitude,
      horVelocity: entityValues.horVelocity,
      verVelocity: entityValues.verVelocity,
      entity,
      filteredOut: null
    }

    if (sepTargetEntity && isEntityAvailable(sepTargetEntity)) {
      const adsbPoint = getCartographicEntityPosition(cesiumViewer, entity)
      const targetPoint = getCartographicEntityPosition(cesiumViewer, sepTargetEntity)

      if (adsbPoint && targetPoint) {
        const sepDistance = getGeodesicDistance(adsbPoint, targetPoint)
        const sepAltitude = getSeparationAltitude(adsbPoint, targetPoint)
        item.sepDistance = sepDistance
        item.sepAltitude = sepAltitude

        if (tableFilter.sepAltitude < sepAltitude ||
          tableFilter.sepDistance < sepDistance) {
          item.filteredOut = true
        }
      }
    }

    return item
  }

  // User can change the clock time like by dragging timeline, so we need to check if the entity is available on the current time
  const isEntityAvailable = (entity) => {
    const isEntityAvailable = entity.isAvailable(cesiumViewer.clock.currentTime)

    let shownInTable = false
    const index = tableItems.findIndex(e => e.id === entity.id)
    if (index > -1) { shownInTable = true }

    // if entity is not available and but shown in table, remove it
    if (!isEntityAvailable && shownInTable) {
      const rowId = `#adsb-row-${entity.id}`
      table.find('tbody').children().remove(rowId)
      tableItems.splice(index, 1)
    }

    return isEntityAvailable && !shownInTable
  }

  const updateTableValues = (item) => {
    updateCellValue(item.id, 'altitude', item.altitude.toFixed())
    updateCellValue(item.id, 'horVelocity', item.horVelocity.toFixed())
    updateCellValue(item.id, 'verVelocity', item.verVelocity.toFixed())

    if (sepTargetEntity) {
      updateCellValue(item.id, 'sepDistance', item.sepDistance.toFixed())
      updateCellValue(item.id, 'sepAltitude', item.sepAltitude.toFixed())

      const isFilterApplied = Object.values(tableFilter).length > 0
      const checkBoxItem = table.find(`#check-${item.id}`)

      const entity = item.entity
      if (isFilterApplied) {
        if (item.filteredOut === true) {
          checkBoxItem.prop('checked', false)
          entity.show = false
        } else {
          entity.show = true
          checkBoxItem.prop('checked', true)
        }
      } else {
        checkBoxItem.prop('checked', entity.show)
      }
    }
  }

  const updateCellValue = (entityId, attr, value) => {
    const cellItem = table.find(`#${entityId}_${attr}`)
    if (cellItem.length > 0) {
      cellItem[0].innerHTML = value
    }
  }

  const createTableRow = (item) => {
    const entity = item.entity
    const selectedRowClassName = cesiumViewer.selectedEntity?.id === entity.id ? 'selected-row' : ''
    const isFilterApplied = Object.values(tableFilter).length > 0

    if (isFilterApplied) {
      entity.show = !item.filteredOut
    }

    const rowItemHtml =
    `<tr id='adsb-row-${entity.id}' class='asdb-row-label-text ${selectedRowClassName}'>
      <td>
        <div class='form-check ml-2'>
          <input type='checkbox' id='check-${entity.id}' class='form-check-input' ${entity.show ? 'checked' : ''} />
        </div>
      </td>
      <td>
        ${entity.name}
      </td>
      <td id=${entity.id + '_altitude'} class='text-right pr-3'>
        ${item.altitude.toFixed()}
      </td>
      <td id=${entity.id + '_horVelocity'} class='text-right pr-3'>
        ${item.horVelocity.toFixed()}
      </td>
      <td id=${entity.id + '_verVelocity'} class='text-right pr-3'>
        ${item.verVelocity.toFixed()}
      </td>
      ${sepTargetEntity
      ? `<td id=${entity.id + '_sepAltitude'} class='text-right pr-3'>
            ${item.sepAltitude?.toFixed()}
          </td>
          <td id=${entity.id + '_sepDistance'} class='text-right pr-3'>
          ${item.sepDistance?.toFixed()}
        </td>`
      : ''
    }
    </tr>`

    const rowItem = $(rowItemHtml)

    rowItem.on('click', (e) => tableRowOnClick(e, entity))

    return rowItem
  }

  const tableRowOnClick = (e, entity) => {
    if (e.target.type === 'checkbox') {
      entity.show = e.target.checked
    } else {
      e.preventDefault()
      cesiumViewer.selectedEntity = entity
    }
  }

  const sortByAttribute = (attr) => {
    return function (a, b) {
      if (a[attr] > b[attr]) {
        return 1
      } else if (a[attr] < b[attr]) {
        return -1
      } else {
        return 0
      }
    }
  }

  // Check if two arrays are equal, for both item value and item order
  function arraysEqual (a, b) {
    if (a === b) return true
    if (a == null || b == null) return false
    if (a.length !== b.length) return false

    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) return false
    }
    return true
  }

  // Listen Cesium Viewer Data Source, After Data Source Loaded Drop It
  const cesiumEventHelper = new Cesium.EventHelper()
  const cesiumDataSourceListener = () => {
    const adsbDataSource = cesiumViewer.dataSources.getByName(dataSourceId)
    if (adsbDataSource.length === 1) {
      cesiumEventHelper.removeAll()
      // Listen Data Source Changes then Update Table
      adsbDataSource[0].changedEvent.addEventListener((e) => {
        updateTable()
      })
    }
  }
  cesiumEventHelper.add(cesiumViewer.dataSources.dataSourceAdded, cesiumDataSourceListener, cesiumEventHelper)

  cesiumViewer.selectedEntityChanged.addEventListener((selectedEntity) => {
    highlightSelectedRow(selectedEntity)
  })

  const timelineBar = document.getElementsByClassName('cesium-viewer-timelineContainer')
  if (timelineBar && timelineBar.length > 0) {
    timelineBar[0].addEventListener('mouseup', () => {
      cesiumViewer.clock.shouldAnimate = true
      updateTable()
    })
  }

  const highlightSelectedRow = (selectedEntity) => {
    const selectedRows = table.find('tbody>.adsb-selected-row')
    selectedRows.removeClass('adsb-selected-row')

    if (selectedEntity) {
      const selectedRow = table.find(`#adsb-row-${selectedEntity.id}`)
      selectedRow.addClass('adsb-selected-row')
    }
  }

  initTable()

  Object.assign(controller, {
    setAdsbTableSeparationTarget (entity) {
      sepTargetEntity = entity
      initTable()
    }
  })
}
