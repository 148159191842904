import { Controller } from '@hotwired/stimulus'
import createScrollManager from '../src/vendor/scroll-manager'

export default class extends Controller {
  static targets = ['badgeTitle']

  connect () {
    if (!this.hasBadgeTitleTarget) return

    this.scrollManager = createScrollManager()
    this.scrollManager.add(() => {
      this.toggleStatusBadgeTitleFixedTop()
    })
  }

  disconnect () {
    if (this.scrollManager) {
      this.scrollManager.destroy()
    }
  }

  toggleStatusBadgeTitleFixedTop () {
    const pageTitle = this.element
    const badges = this.badgeTitleTarget

    // If we can't see the badges, make it sticky on the top
    if (pageTitle.getBoundingClientRect().top < 0) {
      if (!badges.classList.contains('status-badge-title--fixed-top')) {
        badges.classList.add('status-badge-title--fixed-top')
      }
    } else {
      if (badges.classList.contains('status-badge-title--fixed-top')) {
        badges.classList.remove('status-badge-title--fixed-top')
      }
    }
  }
}
