import { Controller } from '@hotwired/stimulus'

import moment from 'moment'

import { withDaterangepicker } from '../src/mixins/with_daterangepicker'

export default class extends Controller {
  static values = {
    maxDate: String,
    format: { type: String, default: 'YYYY-MM-DD' }
  }

  connect () {
    this.daterangepicker = withDaterangepicker(this, this.element, {
      autoUpdateInput: false,
      showDropdowns: true,
      singleDatePicker: true,
      locale: {
        format: this.formatValue,
        cancelLabel: 'Clear'
      },
      maxDate: this.maxDateValue
    })

    // Handle the Update Input Functions
    this.applyCallback = this.updateInputFromPicker.bind(this)
    this.clearCallback = this.clearInput.bind(this)
    $(this.element).on(
      'apply.daterangepicker', this.applyCallback
    ).on(
      'cancel.daterangepicker', this.clearCallback
    )

    // Set the picker to a default of today
    if ($(this.element).val() === '') {
      this.daterangepicker.setStartDate(moment())
    } else {
      // Visually Format the Provided Date
      this.updateInputFromPicker()
    }
  }

  disconnect () {
    // Disconnect the Handle Input Functions
    $(this.element).off(
      'apply.daterangepicker', this.applyCallback
    ).off(
      'cancel.daterangepicker', this.cancelCallback
    )
  }

  updateInputFromPicker () {
    this.updateInput(this.daterangepicker.startDate.format(this.formatValue))
  }

  // Applies the value to the input element
  updateInput (value) {
    $(this.element).val(value).trigger('change')
  }

  clearInput () {
    this.updateInput('')
  }
}
