import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="local-datatables--remove-row"
export default class extends Controller {
  static values = {
    tableId: String,
    rowId: String
  }

  connect () {
    // Find the local-datatable element
    const datatable = document.getElementById(this.tableIdValue)
    const localDatatableElement = datatable.closest("[data-controller^='local-datatable']")

    // Find the local-datatable controller
    const localDatatableController = this.application.getControllerForElementAndIdentifier(
      localDatatableElement,
      'local-datatable'
    )

    // Remove the row from the table
    localDatatableController.datatable.row(`#${this.rowIdValue}`).remove().draw()

    // Remove this element from the DOM
    this.element.remove()
  }
}
