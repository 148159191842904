import { Controller } from '@hotwired/stimulus'

/**
 * Generates events similar to the live status provider, but provided from fixtures
 */
export default class extends Controller {
  static targets = ['listener']
  static values = {
    flightPath: Array
  }

  connect () {
    this.processMarkerBind = this.processMarker.bind(this)

    this.reset()
  }

  disconnect () {
    clearTimeout(this.processMarkerTimeout)
  }

  reset () {
    clearTimeout(this.processMarkerTimeout)

    // Duplicate the stack
    this.flightPathStack = [...this.flightPathValue]

    // Enqueue the first marker
    this.initialTimestamp = new Date().getTime()
    this.processMarkerTimeout = setTimeout(this.processMarkerBind, Math.max(0, this.flightPathStack[0].timestamp))
  }

  processMarker () {
    // Grab the current marker
    const processingStep = this.flightPathStack.shift()
    const processingTimestamp = processingStep.timestamp

    // Set the timestamp relative to the initial time
    processingStep.last_timestamp = this.initialTimestamp + processingTimestamp

    // Broadcast the current marker
    this.broadcastStatusUpdate(processingStep)

    // Enqueue the next marker
    if (this.flightPathStack.length > 0) {
      const nextTimeout = Math.max(0, this.flightPathStack[0].timestamp - processingTimestamp)
      this.processMarkerTimeout = setTimeout(this.processMarkerBind, nextTimeout)
    } else {
      // Let all listeners know we are finished
      this.processMarkerTimeout = setTimeout(this.broadcastFinished.bind(this), 1000)
    }
  }

  broadcastStatusUpdate (droneStatus) {
    this.listenerTargets.forEach(listener => {
      this.dispatch('statusUpdate', {
        target: listener,
        detail: droneStatus || {} // unknown drones return null, do not allow null to propagate
      })
    })
  }

  broadcastFinished () {
    this.listenerTargets.forEach(listener => {
      this.dispatch('finished', {
        target: listener
      })
    })
  }
}
