import MarkerDock from '../../../images/marker-dock.svg'

export const withDockMarker = (_controller, { cesiumViewer }) => {
  let entity = null
  let dockName = null
  let dockLatitude = null
  let dockLongitude = null

  const updateEntity = () => {
    if (dockLatitude && dockLongitude) {
      const entityPosition = Cesium.Cartesian3.fromDegrees(dockLongitude, dockLatitude)

      if (entity) {
        // Update the existing entity
        entity.name = dockName
        entity.position = entityPosition
      } else {
        // Create the entity and add it to the viewer
        entity = cesiumViewer.entities.add({
          position: entityPosition,
          id: 'Dock',
          name: dockName,
          billboard: {
            image: MarkerDock,
            scaleByDistance: new Cesium.NearFarScalar(1.5e2, 0.2, 1.5e7, 0.1)
          }
        })
      }
    }
  }

  return {
    update: ({ name, latitude, longitude }) => {
      dockName = name
      dockLatitude = latitude
      dockLongitude = longitude
      updateEntity()
    },
    getEntity: () => entity
  }
}
