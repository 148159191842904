import { Controller } from '@hotwired/stimulus'
import moment from 'moment'
import { Calendar } from '@fullcalendar/core'

import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import multiMonthPlugin from '@fullcalendar/multimonth'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'

export default class extends Controller {
  static targets = ['calendarElement', 'addMissionRecurrenceModal', 'editMissionRecurrenceModal']
  static values = {
    recurrenceNewUrl: String,
    recurrenceBaseUrl: String,
    recurrenceIndexPath: String,
    calendarSlotDuration: {
      type: String,
      default: '00:30:00' // default 30 mins per slot, thus users can only create mission at 1:30, 2:00, 2:30, 3:00...
    },
    timeZone: String
  }

  connect () {
    this.calendarSetup()
    this.calendar.render()
  }

  disconnect () {
    this.calendar.destroy()
  }

  calendarSetup () {
    const calendarEl = this.calendarElementTarget
    this.calendar = new Calendar(calendarEl, {
      timeZone: this.timeZoneValue,
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, multiMonthPlugin, momentTimezonePlugin],
      events: {
        url: this.recurrenceIndexPathValue,
        method: 'GET'
      },
      initialView: 'timeGridDay',
      headerToolbar: {
        left: 'prev,next today', // < > today
        center: 'title', // display the current select date
        right: 'multiMonthYear,dayGridMonth,timeGridWeek,timeGridDay listMonth,listWeek,listDay'
      },
      slotDuration: this.calendarSlotDurationValue,
      editable: false,
      eventResizableFromStart: true,
      dateClick: (info) => this.openRecurrenceCreationForm(info),
      eventClick: (info) => this.openRecurrenceEditForm(info),
      views: {
        multiMonthYear: { buttonText: 'year' },
        listDay: { buttonText: 'list day' },
        listWeek: { buttonText: 'list week' },
        listMonth: { buttonText: 'list month' }
      },
      dayMaxEvents: true,
      navLinks: true,
      nowIndicator: true,
      titleRangeSeparator: ' - ' // Used for month and year view, the separator between the two days, such as 2021-01-01 - 2021-01-31
    })
  }

  refetchEvents () {
    this.calendar.refetchEvents()
  }

  openRecurrenceCreationForm (info) {
    const startStr = moment(info.dateStr).format('YYYY-MM-DD HH:mm:ss')
    const endStr = moment(info.dateStr).add(15, 'minutes').format('YYYY-MM-DD HH:mm:ss')
    const params = new URLSearchParams()

    const defaultRecurrenceParam = {
      recurrence: {
        starts: startStr,
        ends: endStr
      }
    }

    for (const [key, value] of Object.entries(defaultRecurrenceParam.recurrence)) {
      params.append(`recurrence[${key}]`, value)
    }

    const url = new URL(this.recurrenceNewUrlValue)
    url.search = params.toString()
    this.addMissionRecurrenceModalTarget.src = url
  }

  openRecurrenceEditForm (info) {
    const metadata = info.event.extendedProps.metadata // The current recurrence that is going to be edited
    const oldRecurrenceData = metadata.rule
    const id = metadata.id

    oldRecurrenceData.starts = info.event.startStr
    oldRecurrenceData.ends = info.event.endStr

    const params = new URLSearchParams()

    for (const [key, value] of Object.entries(oldRecurrenceData)) {
      params.append(`recurrence[${key}]`, value)
    }

    const baseURL = new URL(this.recurrenceBaseUrlValue)
    const fullUrl = new URL(`${id}/edit`, baseURL)

    fullUrl.search = params.toString()
    this.editMissionRecurrenceModalTarget.src = fullUrl
  }
}
