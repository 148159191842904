const buildInfoWindow = ({ title, content }) => {
  return new google.maps.InfoWindow({
    content: content ? `<h6 class="mt-0">${title}</h6> ${content}` : `<h6 class="my-0">${title}</h6>`
  })
}

export const buildSimpleInfoWindow = (title) => {
  return buildInfoWindow({ title })
}

export const buildInfoWindowWithCoordinates = ({ title, latitude, longitude }) => {
  return buildInfoWindow({
    title,
    content: `
      <div><b>Latitude:</b> ${latitude.toFixed(7)}</div>
      <div><b>Longitude:</b> ${longitude.toFixed(7)}</div>
    `
  })
}

export const buildInfoWindowWithDescription = ({ title, description }) => {
  return buildInfoWindow({
    title,
    content: description && `<div>${description}</div>`
  })
}
