import 'daterangepicker/daterangepicker'

const DATERANGE_INPUTS = 'th.month, th.next, th.prev'

/**
 * Creates a Daterangepicker safe for use in modals and other known cases
 */
export const withDaterangepicker = (controller, element, options) => {
  // Prevents Daterange Picker from being Dismissed when used in a modal or popover
  const blockDismissFunction = function (e) {
    e.stopPropagation()
  }
  const blockDismiss = function () {
    $(document).on('click', DATERANGE_INPUTS, blockDismissFunction)
  }
  const unblockDismiss = function () {
    $(document).off('click', DATERANGE_INPUTS, blockDismissFunction)
  }

  // Setup the daterangepicker
  const daterangepicker = $(element).daterangepicker(
    options,
    function (start) {}
  ).data('daterangepicker')

  // Add the block dismiss to the daterangepicker
  $(element)
    .on('show.daterangepicker', blockDismiss)
    .on('hide.daterangepicker', unblockDismiss)

  const controllerDisconnect = controller.disconnect.bind(controller)

  Object.assign(controller, {
    disconnect () {
      unblockDismiss()

      // Remove the block dismiss from the daterangepicker
      $(element)
        .off('show.daterangepicker', blockDismiss)
        .off('hide.daterangepicker', unblockDismiss)

      // Remove the daterangepicker
      daterangepicker.remove()

      controllerDisconnect()
    }
  })

  return daterangepicker
}
