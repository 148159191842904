import { polygonOpt } from './polygon-utils'

const circleOpt = {
  fillColor: '#00dafb',
  fillOpacity: 0.33,
  strokeColor: '#00dafb',
  strokeWeight: 3,
  zIndex: 1
}

export const circleEditOpt = {
  ...circleOpt,
  editable: true
}

// Convert Google Circle into Google Polygon
export function convertCircleToPolygon (googleCircle, resPoints = 60) {
  const path = []
  for (let i = 0; i < resPoints; i++) {
    path.push(google.maps.geometry.spherical.computeOffset(googleCircle.getCenter(), googleCircle.getRadius(), i * 360 / resPoints))
  }

  return new google.maps.Polygon({
    paths: [path],
    ...polygonOpt
  })
}
