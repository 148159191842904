import toastr from './toastr'

// Utility functions for Curo Map

export const addClearShapeButton = (map, title, position = google.maps.ControlPosition.TOP_CENTER) => {
  const clearButton = document.createElement('button')
  clearButton.title = title
  clearButton.classList.add('google-control')
  clearButton.classList.add('google-control-btn')
  clearButton.classList.add('bg-danger')
  clearButton.classList.add('text-white')
  clearButton.classList.add('clear-area')
  clearButton.id = 'clear-polygon'
  clearButton.style.display = 'none'
  clearButton.type = 'button'

  const icon = document.createElement('i')
  icon.classList.add('uil', 'uil-trash-alt')
  clearButton.appendChild(icon)

  map.controls[position].push(clearButton)
  return clearButton
}

export const addClearMarkersButton = (map, position = google.maps.ControlPosition.TOP_CENTER) => {
  const clearButton = document.createElement('button')
  clearButton.title = 'Clear All Markers'
  clearButton.classList.add('google-control')
  clearButton.classList.add('google-control-btn')
  clearButton.classList.add('bg-danger')
  clearButton.classList.add('text-white')
  clearButton.classList.add('clear-area')
  clearButton.id = 'clear-all-markers'
  clearButton.style.display = 'none'
  clearButton.type = 'button'

  const icon = document.createElement('i')
  icon.classList.add('uil', 'uil-map-marker-slash')
  clearButton.appendChild(icon)

  map.controls[position].push(clearButton)
  return clearButton
}

// Return polygon points as a json string: "[ {lat: 11, lng: -31}, {lat: 12, lng: -32} ... ]"
export const serializeGooglePolygon = (googlePolygon) => {
  const points = googlePolygon.getPath().getArray()
  return JSON.stringify(
    points.map((p) => {
      return { lat: p.lat(), lng: p.lng() }
    })
  )
}

export const addSearchBox = (map, onSuccess = () => {}) => {
  const searchBoxInput = document.createElement('input')
  searchBoxInput.classList.add('google-control')
  searchBoxInput.classList.add('google-control-input')
  searchBoxInput.classList.add('google-search-box')

  const searchBox = new google.maps.places.SearchBox(searchBoxInput)
  map.controls[google.maps.ControlPosition.TOP_RIGHT].push(searchBoxInput)
  // Bias the SearchBox results towards current map's viewport.
  map.addListener('bounds_changed', () => {
    searchBox.setBounds(map.getBounds())
  })

  // prevent form to be submitted on enter
  const ENTER_KEY = 13
  google.maps.event.addDomListener(searchBoxInput, 'keydown', function (e) {
    if (e.keyCode === ENTER_KEY) {
      e.preventDefault()
    }
  })

  // Position map to the searched result
  searchBox.addListener('places_changed', (e) => {
    const inputValue = searchBoxInput.value
    const replacedValue = inputValue.trim().replace(/[\s,]+/g, ',')
    const [lat, lng] = replacedValue.split(',').map(p => p.trim())
    let places = searchBox.getPlaces()

    // For lat, lng input, we just show it
    if (isLatLon(lat, lng)) {
      const place = {
        geometry: {
          location: new google.maps.LatLng(Number(lat), Number(lng))
        },
        formatted_address: places[0] ? places[0].formatted_address : null
      }

      places = [place]
    }

    onSuccess(places)
  })
}

const isLatLon = (lat, lng) => {
  const regexLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/
  const regexLng = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/

  const validLat = regexLat.test(lat)
  const validLng = regexLng.test(lng)
  return validLat && validLng
}

export const addGeolocationButton = (map) => {
  const locationButton = document.createElement('button')
  locationButton.title = 'Go to my current location'
  locationButton.classList.add('google-control')
  locationButton.classList.add('google-control-btn')
  locationButton.type = 'button'

  const locationIcon = document.createElement('i')
  locationIcon.classList.add('uil', 'uil-hunting')
  locationButton.appendChild(locationIcon)

  map.controls[google.maps.ControlPosition.TOP_RIGHT].push(locationButton)
  locationButton.addEventListener('click', (e) => {
    toastr.info('Locating...')

    // Try HTML5 geolocation.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
          map.setCenter(pos)
          map.setZoom(15)
          toastr.clear()
        },
        () => {
          // Geolocation not allowed
          toastr.error('The Geolocation service failed.')
        }
      )
    } else {
      // Geolocation not supported
      toastr.error("Your browser doesn't support geolocation.")
    }
  })
}

export const addInfoButton = (map) => {
  const button = document.createElement('button')
  button.title = 'Useful Links'
  button.classList.add('google-control')
  button.classList.add('google-control-btn')
  button.type = 'button'
  button.dataset.toggle = 'modal'
  button.dataset.target = 'mapInfoModal'

  const icon = document.createElement('i')
  icon.classList.add('uil', 'uil-info-circle')
  button.appendChild(icon)

  map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(button)
  button.addEventListener('click', () => {
    $('#mapInfoModal').modal('toggle')
  })
}
