import { FlightAdvisory } from '../flight-advisory/flight-advisory'

import {
  buildInfoWindowWithCoordinates,
  buildInfoWindowWithDescription
} from '../display-map/info-window'

import {
  buildPolygonFromDataPoints,
  extendGetBoundsForPolygon
} from '../display-map/polygon-utils'

import { buildMapOfAustralia, fitViewportToBounds, resetViewportToAustralia } from '../display-map/map-utils'

import {
  buildHighlightedMarker
} from '../display-map/marker-utils'

class CuroDisplayMap {
  constructor (element) {
    this.$el = $(element)
    // init the map
    this.map = buildMapOfAustralia(element)

    this.flightAdvisory = null
    this.pointsBounds = null
    this.polygonBounds = null
    this.markers = []
  }

  addFlightAdvisory ({ mapboxAccessToken, advisoryHost, operationType, advisoriesLookup }) {
    this.flightAdvisory = new FlightAdvisory(this.$el, {
      map: this.map,
      mapboxAccessToken,
      advisoryHost,
      operationType,
      advisoriesLookup,
      isStandalone: false
    })

    // Remove Satellite/Terrain Control buttons
    this.map.setOptions({ mapTypeControl: false })

    this.reloadFlightAdvisories()
  }

  addKMLLayer (title, kmlUrl, { preserveViewport }) {
    const kmlLayer = new google.maps.KmlLayer(kmlUrl, {
      map: this.map,
      preserveViewport
    })

    kmlLayer.addListener('click', function (event) {
      event.featureData.infoWindowHtml = title
    })
  }

  addPoint (point) {
    const map = this.map

    if (!this.pointsBounds) {
      this.pointsBounds = new google.maps.LatLngBounds()
    }

    if (!this.selectedPoint) {
      // Only assign the first point as the selected point
      this.selectedPoint = { latitude: point.lat, longitude: point.lng }
    }

    const googleLatLng = new google.maps.LatLng(point.lat, point.lng)
    this.pointsBounds.extend(googleLatLng)

    const infoWindow = point.description ? buildInfoWindowWithDescription({ title: point.title, description: point.description }) : buildInfoWindowWithCoordinates({ title: point.title, latitude: googleLatLng.lat(), longitude: googleLatLng.lng() })
    infoWindow.setPosition(googleLatLng)

    const marker = buildHighlightedMarker({
      map,
      title: point.title,
      latitude: point.lat,
      longitude: point.lng
    })
    marker.setMap(map)

    marker.addListener('click', () => {
      this.#showInfoWindow(infoWindow)
    })

    this.markers.push(marker)
  }

  clearPoints () {
    this.markers.forEach((marker) => { marker.setMap(null) })
    this.markers = []
    this.pointsBounds = null

    this.selectedPoint = null
  }

  operationType () {
    if (this.flightAdvisory) {
      return this.flightAdvisory.operationType()
    }
    return null
  }

  reloadFlightAdvisories () {
    if (this.flightAdvisory && this.selectedPolygon) {
      this.flightAdvisory.checkPolygon(this.selectedPolygon)
    } else if (this.flightAdvisory && this.selectedPoint) {
      this.flightAdvisory.checkPoint(this.selectedPoint)
    }
  }

  setPoints (points) {
    this.clearPoints()
    points.forEach((point) => this.addPoint(point))
  }

  setPolygons (polygons) {
    extendGetBoundsForPolygon()

    this.selectedPolygon = null

    const map = this.map
    const polygonBounds = new google.maps.LatLngBounds()

    polygons.forEach((poly) => {
      const polygon = buildPolygonFromDataPoints(poly.points)
      polygon.setMap(map)

      const infoWindow = buildInfoWindowWithDescription({ title: poly.title, description: poly.description })
      polygon.addListener('click', (e) => {
        infoWindow.setPosition(e.latLng)
        this.#showInfoWindow(infoWindow)
      })

      polygonBounds.union(polygon.getBounds())
    })
    this.polygonBounds = polygonBounds

    // Check polygon matches expected minimum
    if (polygons.length > 0 && polygons[0].points.length >= 3) {
      this.selectedPolygon = PolygonFromCuro(polygons[0].points)
    }

    this.reloadFlightAdvisories()
  }

  zoomToBounds () {
    if (!this.polygonBounds && !this.pointsBounds) {
      resetViewportToAustralia(this.map)
      return
    }

    const mapBounds = new google.maps.LatLngBounds()

    if (this.pointsBounds) {
      mapBounds.union(this.pointsBounds)
    }

    if (this.polygonBounds) {
      mapBounds.union(this.polygonBounds)
    }

    fitViewportToBounds(this.map, mapBounds)
  }

  #showInfoWindow (infoWindow) {
    if (this.infoWindow) this.infoWindow.close()

    this.infoWindow = infoWindow
    infoWindow.open(this.map)
  }
}

/**
 * Displays a simple map with paths, points, and/or polygons.
 *
 * Does not include any advisory overlays
 *
 * @param {*} $el
 */
export const initDisplayMap = function (element, { paths, points, polygons }) {
  // init the map
  const displayMap = new CuroDisplayMap(element)

  // Add stuff
  if (points.length > 0) displayMap.setPoints(points)
  if (polygons.length > 0) displayMap.setPolygons(polygons)

  // fitBounds will override zoom. TODO: consider smart zoom.
  displayMap.zoomToBounds()

  // KML will override fitBounds unless we ready all the points in KML
  for (const path of paths) {
    displayMap.addKMLLayer(path.title, path.kml_url, { preserveViewport: paths.length !== 1 }) // only zoom when one path is provided
  }

  return displayMap
}

const PolygonFromCuro = (points) => {
  return points.map((p) => { return [p.lat, p.lng] })
}
