export const withSummaryItems = (controller) => {
  function summaryItemControllerForTarget (target) {
    return controller.application.getControllerForElementAndIdentifier(target, 'summary-item')
  }

  Object.assign(controller, {
    // Presents a number in a summary item (with a bounce effect)
    presentSummaryItemNumber (target, number) {
      summaryItemControllerForTarget(target).presentNumber({ params: { value: number } })
    }
  })
}
