import { Controller } from '@hotwired/stimulus'

import PhotoSwipe from 'photoswipe'
import PhotoSwipeLightbox from 'photoswipe/lightbox'

export default class extends Controller {
  static targets = ['image']

  connect () {
    const lightbox = new PhotoSwipeLightbox({
      gallery: this.element,
      children: this.imageTargets,
      wheelToZoom: true,
      pswpModule: PhotoSwipe
    })
    lightbox.init()
  }
}
