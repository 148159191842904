// app/javascript/controllers/redirect_controller.js
import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  connect () {
    this.submitBinding = this.redirect.bind(this)
    this.element.addEventListener('turbo:submit-end', this.submitBinding)
  }

  disconnect () {
    this.element.removeEventListener('turbo:submit-end', this.submitBinding)
  }

  redirect (event) {
    if (event.detail.success) {
      const url = event.detail.fetchResponse.response.url
      if (url) Turbo.visit(url, { action: 'advance' })
    }
  }
}
