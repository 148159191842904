import { Controller } from '@hotwired/stimulus'

import '../src/init/datatables'

/**
 * Decorates a table with DataTables.
 *
 * Provide clickable rows by adding the visit action to each table row:
 *
 * `<tr data-action="click->local-datatable#visit">`
 */
export default class extends Controller {
  // datatable is a separate target as the element is replaced on init
  static targets = ['datatable']
  static values = {
    paging: { type: Boolean, default: true },
    saveState: { type: Boolean, default: true },
    searching: { type: Boolean, default: true }
  }

  connect () {
    this.datatable = $(this.datatableTarget).DataTable({
      ordering: $(this.datatableTarget).is('[data-order]'),
      paging: this.pagingValue, // add pagination controls
      pageLength: 10, // add a data-page-length attribute to override
      serverSide: false, // local rendering only
      stateDuration: -1, // use session (per tab) storage
      stateSave: this.saveStateValue, // remember the current configuration
      searching: this.searchingValue // add search box
    })
  }

  disconnect () {
    this.datatable.destroy()
  }

  // Finds the most relevant link to the user, and visits it (using Turbo)
  visit (event) {
    if (event.target.tagName === 'A') return // let links handle themselves

    // Find the current cell of the click
    const $cellElement = $(event.target).closest('th,td')

    // Attempt to visit the link in the current cell
    const $cellLinks = $cellElement.find('a:first')
    if ($cellLinks.length > 0) {
      event.preventDefault()
      const newUrl = $cellLinks.attr('href')
      if (event.ctrlKey || event.metaKey) {
        window.open(newUrl, '_blank')
      } else {
        Turbo.visit(newUrl)
      }

      return
    }

    // Attempt to visit the first link in the current row
    const $defaultLink = $cellElement.closest('tr').find('th:first a, td:first a')
    if ($defaultLink.length > 0) {
      event.preventDefault()
      const newUrl = $defaultLink.attr('href')
      if (event.ctrlKey || event.metaKey) {
        window.open(newUrl, '_blank')
      } else {
        Turbo.visit(newUrl)
      }
    }
  }
}
