import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['markReadForm']

  markRead (event) {
    if (this.hasMarkReadFormTarget) {
      this.markReadFormTarget.requestSubmit()
    }
  }
}
