import { Controller } from '@hotwired/stimulus'

/**
 * Displays a live indicator for connected drones
 */
export default class extends Controller {
  static classes = ['connected']

  connect () {
    this.currentMessage = null
  }

  update ({ detail: { meta: { last_timestamp } } }) { // eslint-disable-line
    if (last_timestamp && this.isConnected(last_timestamp)) { // eslint-disable-line
      this.element.classList.add(this.connectedClass)
      this.#updateTooltip('Live in operation')
    } else {
      this.element.classList.remove(this.connectedClass)
      this.#updateTooltip('Live in the past 24 hours')
    }
  }

  #updateTooltip (message) {
    if (this.currentMessage !== message) {
      $(this.element).tooltip('dispose').attr('title', message).tooltip()
      this.currentMessage = message
    }
  }

  isConnected (lastTimestamp) {
    // No message received in 30 seconds is probably a timeout (messages every 2 seconds at a min)
    const cutoffThreshold = Date.now() - (30 * 1000)
    return lastTimestamp > cutoffThreshold
  }
}
