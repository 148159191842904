import toastr from '../init/toastr'

import CircleManager from './circle-manager'
import MarkerManager from './marker-manager'
import PolygonManager from './polygon-manager'

import {
  addClearShapeButton,
  addClearMarkersButton
} from '../init/curo-map-utility'

import { highlightMarkerOpt } from './marker-utils'

import { polygonEditOpt } from './polygon-utils'
import { circleEditOpt } from './circle-utils'

const createDrawingManager = (map, drawingModes, defaultDrawingMode) => {
  const drawingManager = new google.maps.drawing.DrawingManager({
    drawingControl: true,
    drawingControlOptions: {
      position: google.maps.ControlPosition.TOP_CENTER,
      drawingModes
    },
    drawingMode: defaultDrawingMode,
    polygonOptions: polygonEditOpt,
    circleOptions: circleEditOpt,
    markerOptions: highlightMarkerOpt()
  })
  drawingManager.setMap(map)

  return drawingManager
}

class DrawingTool {
  // Private variables
  #map
  #enableMarker
  #drawingManager
  #onMarkerSelected
  #onPolygonSelected
  #onClearShapes
  #drawingModes

  constructor ({ map, title, enableMarker, onMarkerSelected, onPolygonSelected, onClearShapes }) {
    this.#map = map
    this.#enableMarker = enableMarker

    this.#onMarkerSelected = onMarkerSelected
    this.#onPolygonSelected = onPolygonSelected
    this.#onClearShapes = onClearShapes
    this.#drawingModes = enableMarker ? ['marker', 'polygon', 'circle'] : ['polygon', 'circle']

    // Setup Drawing Manager
    const defaultMode = enableMarker ? 'marker' : 'polygon'
    this.#drawingManager = createDrawingManager(this.#map, this.#drawingModes, defaultMode)

    // Setup Shape Managers
    this.circleManager = new CircleManager({
      map: this.#map,
      title,
      onCircleSelected: this.#circleWasSelected.bind(this),
      onCircleAdded: this.#refreshClearButtons.bind(this),
      onCircleRemoved: this.#refreshClearButtons.bind(this)
    })
    this.markerManager = new MarkerManager({
      map: this.#map,
      onMarkerSelected: this.#markerWasSelected.bind(this),
      onMarkersAdded: this.#refreshClearButtons.bind(this),
      onMarkersRemoved: this.#refreshClearButtons.bind(this)
    })
    this.polygonManager = new PolygonManager({
      map: this.#map,
      title,
      onPolygonSelected: this.#polygonWasSelected.bind(this),
      onPolygonAdded: this.#refreshClearButtons.bind(this),
      onPolygonRemoved: this.#refreshClearButtons.bind(this)
    })

    // When a marker is drew on map
    google.maps.event.addListener(
      this.#drawingManager,
      'markercomplete',
      this.placeMarker.bind(this)
    )

    // When a polygon is drew on map
    google.maps.event.addListener(
      this.#drawingManager,
      'polygoncomplete',
      this.placePolygon.bind(this)
    )

    // When a circle is drew on map
    google.maps.event.addListener(
      this.#drawingManager,
      'circlecomplete',
      this.placeCircle.bind(this)
    )
  }

  getBounds () {
    const bounds = new google.maps.LatLngBounds()
    bounds.union(this.circleManager.getBounds())
    bounds.union(this.markerManager.getBounds())
    bounds.union(this.polygonManager.getBounds())
    return bounds
  }

  placeCircle (googleCircle) {
    // Remove all previous shapes
    this.#removeAllShapes()

    // Display (and Select) the Placed Circle
    this.circleManager.placeCircle(googleCircle)
    this.circleManager.selectCircle()

    // Allow the user to move/resize the circle
    this.#selectHandDrawingTool()
  }

  placeMarker (googleMarker) {
    // Remove all previous shapes
    this.#removeAllShapes()

    // Display (and Select) the Placed Marker
    this.markerManager.addPlacedMarker(googleMarker)
    this.markerManager.selectMarker(googleMarker)

    // User is already placing markers, let them keep doing that
  }

  placePolygon (googlePolygon) {
    // Remove all previous shapes
    this.#removeAllShapes()

    // Display (and Select) the Placed Polygon
    this.polygonManager.showPlacedPolygon(googlePolygon)
    this.polygonManager.selectPolygon()

    // Allow the users to reshape the polygon
    this.#selectHandDrawingTool()
  }

  placeSearchResults (googlePlaces) {
    const placesWithGeometry = googlePlaces.filter((place) => place.geometry)

    if (placesWithGeometry.length === 0) {
      toastr.info('Nothing found. Maybe you can zoom to a more specific state/city and search again')
      return
    }

    if (this.#enableMarker) {
      // Only one may survive, so remove all shapes
      this.#removeAllShapes()
    } else {
      // Remove all previous Search Results
      this.markerManager.removeAllMarkers()
    }

    // Show all markers and select the first
    this.markerManager.removeAllMarkers()
    this.markerManager.addSearchResults(placesWithGeometry)
    this.markerManager.selectMarkerAtIndex(0)

    // Allow users to select other markers or previously drawn shapes
    this.#selectHandDrawingTool()
  }

  resizeToWidth (width) {
    if (width < 768) {
      const position = google.maps.ControlPosition.LEFT_TOP

      this.#drawingManager.setOptions({
        drawingControlOptions: {
          position,
          drawingModes: this.#drawingModes
        }
      })

      this.rebuildClearButtons(position)
    } else {
      const position = google.maps.ControlPosition.TOP_CENTER

      this.#drawingManager.setOptions({
        drawingControlOptions: {
          position,
          drawingModes: this.#drawingModes
        }
      })
      this.rebuildClearButtons(position)
    }
  }

  /*****************************************
   * Clear Button Callbacks
   *****************************************/
  rebuildClearButtons (position = google.maps.ControlPosition.TOP_CENTER) {
    // Remove the old buttons
    if (this.clearShapeButton) this.clearShapeButton.remove()
    if (this.clearMarkersButton) this.clearMarkersButton.remove()

    // Add the new buttons with new position, but not showing
    this.clearShapeButton = addClearShapeButton(this.#map, 'Clear Area Shape', position)
    this.clearMarkersButton = addClearMarkersButton(this.#map, position)

    // Add the new callbacks
    $(this.clearShapeButton).on('click', this.clearShapes.bind(this))
    $(this.clearMarkersButton).on('click', this.clearSearchMarkers.bind(this))

    // Let UI to decide showing or not
    this.#refreshClearButtons()
  }

  clearShapes () {
    // Reset the drawing manager to the previously used drawing tool
    this.#resetDrawingManager()

    // Remove all shapes
    this.#removeAllShapes()

    // Update the UI
    this.#onClearShapes()
  }

  clearSearchMarkers () {
    if (this.#enableMarker) {
      // Remove Markers not selected by the user
      this.markerManager.removeIdleMarkers()
    } else {
      // Remove All Markers
      this.markerManager.removeAllMarkers()
    }

    // Select/ReSelect the other visible shape
    if (this.markerManager.hasMarkers) {
      // NOTE: the last survivor is already selected
    } else if (this.polygonManager.hasPolygon) {
      this.polygonManager.selectPolygon()
    } else if (this.circleManager.hasCircle) {
      this.circleManager.selectCircle()
    }
  }

  #removeAllShapes () {
    this.circleManager.removeCircle()
    this.polygonManager.removePolygon()
    this.markerManager.removeAllMarkers()
  }

  /*****************************************
   * Shape Selection Callbacks
   *****************************************/

  #circleWasSelected (googleCircle, googlePolygon) {
    this.markerManager.deselectMarkers()
    this.polygonManager.deselectPolygon()

    // Update the UI
    this.#onPolygonSelected(googlePolygon)
  }

  #markerWasSelected (marker) {
    this.circleManager.deselectCircle()
    this.polygonManager.deselectPolygon()

    // Update the UI
    this.#onMarkerSelected(marker)
  }

  #polygonWasSelected (googlePolygon) {
    this.circleManager.deselectCircle()
    this.markerManager.deselectMarkers()

    // Update the UI
    this.#onPolygonSelected(googlePolygon)
  }

  #refreshClearButtons () {
    // Update the Clear Shapes Button
    if (this.polygonManager.hasPolygon || this.circleManager.hasCircle) {
      $(this.clearShapeButton).show()
    } else if (this.#enableMarker && this.markerManager.hasMarkers) {
      $(this.clearShapeButton).show()
    } else {
      $(this.clearShapeButton).hide()
    }

    // Update Clear Markers Button
    if (this.markerManager.hasMultipleMarkers) {
      $(this.clearMarkersButton).show()
    } else if (!this.#enableMarker && this.markerManager.hasMarkers) {
      $(this.clearMarkersButton).show()
    } else {
      $(this.clearMarkersButton).hide()
    }
  }

  /*****************************************
   * Drawing Manager Controls
   *****************************************/

  /**
   * Revert the Drawing Manager to be in the Drawing Mode as the previosly removed shape
   * i.e. circle -> circle, polygon -> polygon, marker -> marker, etc
   */
  #resetDrawingManager () {
    if (this.polygonManager.hasPolygon) {
      this.#selectPolygonDrawingTool()
    } else if (this.circleManager.hasCircle) {
      this.#selectCircleDrawingTool()
    } else if (this.#enableMarker) {
      this.#selectMarkerDrawingTool()
    } else {
      this.#selectPolygonDrawingTool()
    }
  }

  #selectHandDrawingTool () {
    this.#drawingManager.setDrawingMode(null)
  }

  #selectCircleDrawingTool () {
    this.#drawingManager.setDrawingMode('circle')
  }

  #selectMarkerDrawingTool () {
    this.#drawingManager.setDrawingMode('marker')
  }

  #selectPolygonDrawingTool () {
    this.#drawingManager.setDrawingMode('polygon')
  }
}

export default DrawingTool
