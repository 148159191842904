/* global tinymce */
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  initialize () {
    this.defaults = {
      plugins: 'autoresize lists image link table',
      toolbar:
        'undo redo | styleselect | bullist numlist bold italic underline | link image',
      menubar: false,
      statusbar: false,
      style_formats: [
        { title: 'Paragraph', format: 'p' },
        { title: 'Heading 1', format: 'h1' },
        { title: 'Heading 2', format: 'h2' },
        { title: 'Heading 3', format: 'h3' }
      ]
    }
  }

  connect () {
    const config = Object.assign({ target: this.inputTarget }, this.defaults)
    tinymce.init(config)
  }

  disconnect () {
    tinymce.get(this.inputTarget.id).remove()
  }
}
