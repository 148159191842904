import { Controller } from '@hotwired/stimulus'
import { useWindowScrolled } from '../src/mixins/use_window_scrolled'

export default class extends Controller {
  static classes = ['hide']

  connect () {
    useWindowScrolled(this)

    this.cancelFrameCallback = () => {
      cancelAnimationFrame(this.scrollToTopReq)
    }

    window.addEventListener('mousewheel', this.cancelFrameCallback)
    window.addEventListener('touchstart', this.cancelFrameCallback)
  }

  disconnect () {
    window.removeEventListener('mousewheel', this.cancelFrameCallback)
    window.removeEventListener('touchstart', this.cancelFrameCallback)

    this.cancelFrameCallback()
  }

  scrollToTop () {
    // Let's set a variable for the number of pixels we are from the top of the document.
    const c = document.documentElement.scrollTop || document.body.scrollTop

    // If that number is greater than 0, we'll scroll back to 0, or the top of the document.
    // We'll also animate that scroll with requestAnimationFrame:
    // https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
    if (c > 0) {
      this.scrollToTopReq = window.requestAnimationFrame(this.scrollToTop.bind(this))
      // ScrollTo takes an x and a y coordinate.
      // Increase the '10' value to get a smoother/slower scroll!
      window.scrollTo(0, c - c / 10)
    }
  }

  windowScrolled (scrollX, scrollY) {
    // If the scroll value is greater than the window height, let's add a class to the scroll-to-top button to show it!
    if (scrollY > 1200) {
      this.element.classList.remove(this.hideClass)
    } else {
      this.element.classList.add(this.hideClass)
    }
  }
}
