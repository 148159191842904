import { Controller } from '@hotwired/stimulus'

/**
 * A Stimulus controller class that handles collapsing and expanding a list of jQuery Collapse items.
 * @extends Controller
 */
export default class extends Controller {
  static targets = ['item']

  /**
   * Examples all the collapse items
   */
  expandAll () {
    // Iterate over each item and show it.
    this.itemTargets.forEach((item) => {
      $(item).collapse('show')
    })
  }

  /**
   * Hides all the collapse items
   */
  hideAll () {
    // Iterate over each item and hide it.
    this.itemTargets.forEach((item) => {
      $(item).collapse('hide')
    })
  }
}
