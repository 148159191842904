import icon from '../../images/icon_flight_advisory.svg'
import { FlightAdvisoryResponse } from './flight-advisory-response'
import moment from 'moment'
const Mustache = require('mustache')

export const FlightAdvisoryPanel = class {
  ADVISORY_PANEL_HTML =
    "<div class='advisory-on-map card'>" +
        "<div class='card-body p-0'>" +
          "<div class='advisory-header bg-dark'>" +
            `<img src="${icon}" class='advisory-icon' alt='Curo Flight Advisory'/>` +
            "<h5 class='text-white mb-0 p-3'> Flight Advisory </h5>" +
            "<i class='uil uil-angle-up'></i>" +
          '</div>' +
          "<div class='advisory-content'></div>" +
        '</div>' +
    '</div>'

  DEFAULT_HTML = '<p class="p-3 mb-0">Draw a shape on the map to check the flight advisory</p>'
  PENDING_HTML = '<p class="p-3 mb-0"><i>Checking...</i></p>'
  ERROR_HTML = '<p class="p-3 mb-0 text-danger">Something went wrong</p>'

  CAN_FLY_HTML = "<div class='advisory-result advisory-can-fly'>" +
                  "<div class='advisory-dataset-header text-success'>" +
                    "<i class='uil uil-check-circle'></i>" +
                    '<span>Clear to Fly</span>' +
                  '</div>' +
                 '</div>'

  constructor ($mapContainer) {
    $mapContainer.append(this.ADVISORY_PANEL_HTML)
    this.$panelHeader = $mapContainer.find($('.advisory-header'))
    this.$panelHeaderIcon = $mapContainer.find($('.advisory-header i'))
    this.$panelContent = $mapContainer.find($('.advisory-content'))
    this.$panelContent.hide() // Hide panel content by default

    this.setContent(this.DEFAULT_HTML)
    this.$panelHeader.on('click', this.toggle.bind(this))

    this.visible = false

    this.initPopover()
  }

  // Setting a "selector" for dynamic HTML content.
  initPopover () {
    const POPOVER_TEMPLATE = '<div class="popover advisory-dataset" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'

    if ($(window).width() > 480) {
      $('body').popover({
        boundary: 'viewport',
        html: true,
        trigger: 'hover',
        selector: '.advisory-dataset-content [data-popover]',
        template: POPOVER_TEMPLATE
      })
    }
  }

  buildAdvisory (faResponse) {
    let noFlyHtml = ''
    let cautionHtml = ''
    let warningHTML = ''
    let informationHTML = ''
    const metaHTML = this.buildMetaResult(faResponse.meta)

    faResponse.noFly.ruleSets.forEach((ruleSet, index) => {
      noFlyHtml += this.buildDatasetResult('no-fly', ruleSet, 'no-fly-' + index)
    })

    faResponse.caution.ruleSets.forEach((ruleSet, index) => {
      cautionHtml += this.buildDatasetResult('caution', ruleSet, 'caution-' + index)
    })

    faResponse.information.ruleSets.forEach((ruleSet, index) => {
      informationHTML += this.buildDatasetResult('info', ruleSet, 'information-' + index)
    })
    informationHTML += metaHTML

    faResponse.warnings.ruleSets.forEach((ruleSet, index) => {
      warningHTML += this.buildDatasetResult('caution', ruleSet, 'warning-' + index)
    })

    const totalNoFly = faResponse.noFly.ruleSets.length
    const totalCaution = faResponse.caution.ruleSets.length
    const totalWarning = faResponse.warnings.ruleSets.length
    const totalInformation = faResponse.information.ruleSets.length

    let advisoryContent = ''

    if (totalNoFly + totalCaution === 0) {
      advisoryContent += this.CAN_FLY_HTML
    }

    if (totalWarning > 0) {
      advisoryContent +=
      "<div class='advisory-result'>" +
        "<div class='advisory-dataset-content'>" +
          warningHTML +
        '</div>' +
      '</div>'
    }

    if (totalNoFly > 0) {
      advisoryContent += this.buildAdvisoryContent({
        textClass: 'text-danger',
        iconClass: 'uil-ban',
        title: 'No Fly',
        totalCount: totalNoFly,
        contentHTML: noFlyHtml
      })
    }

    if (totalCaution > 0) {
      advisoryContent += this.buildAdvisoryContent({
        textClass: 'text-warning',
        iconClass: 'uil-exclamation-circle',
        title: 'Caution',
        totalCount: totalCaution,
        contentHTML: cautionHtml
      })
    }

    if (totalInformation > 0) {
      advisoryContent += this.buildAdvisoryContent({
        textClass: 'text-default',
        iconClass: 'uil-info-circle',
        title: 'Information',
        totalCount: totalInformation,
        contentHTML: informationHTML
      })
    }

    return advisoryContent
  }

  buildAdvisoryContent ({ textClass, iconClass, title, totalCount, contentHTML }) {
    return "<div class='advisory-result'>" +
              `<div class='advisory-dataset-header ${textClass} mb-2'>` +
                `<i class='uil ${iconClass}'></i>` +
                `<span>${title}</span>` +
                `<span class='total'>${totalCount}</span>` +
              '</div>' +
              "<div class='advisory-dataset-content'>" +
                contentHTML +
              '</div>' +
            '</div>'
  }

  buildDatasetResult (datasetClass, ruleSet, key) {
    // Set can only contains unique element.
    const pointTitles = new Set(ruleSet.items.map((value) => { return value.title }))

    const template = `
      <div class='mb-2 advisory-dataset {{datasetClass}}'>
        <span data-content="{{title}}" data-popover data-toggle="collapse" data-target="#{{key}}" aria-expanded="false">
          {{dataset.title}}
          {{#dataset.itemsTotal}}
            ({{dataset.itemsTotal}})
          {{/dataset.itemsTotal}}
        </span>
      </div>
      <div class="advisory-dataset-points collapse" id="{{key}}">
        {{#points}}
        <p>{{title}}</p>
        {{/points}}
        {{#extras}}
        <p>&hellip;</p>
        {{/extras}}
      </div>
    `

    return Mustache.render(template, {
      dataset: ruleSet,
      datasetClass,
      key,
      title: escapeHtml(ruleSet.description),
      points: Array.from(pointTitles).map((title) => { return { title } }),
      extras: ruleSet.itemsTotal > pointTitles.size
    })
  }

  buildMetaResult (metaInfo) {
    const sunrise = metaInfo.civil_twilight_begin && moment.parseZone(metaInfo.civil_twilight_begin).format('LT Z')
    const sunset = metaInfo.civil_twilight_end && moment.parseZone(metaInfo.civil_twilight_end).format('LT Z')

    // TODO: format include a timezone name abbreviation
    const startAt = metaInfo.operation_start_at && moment.parseZone(metaInfo.operation_start_at).format('YYYY-MM-DD LT Z')

    const startDate = metaInfo.advisory_start_date && moment.parseZone(metaInfo.advisory_start_date).format('YYYY-MM-DD')
    const endDate = metaInfo.advisory_end_date && moment.parseZone(metaInfo.advisory_end_date).format('YYYY-MM-DD')

    let endAt
    if (metaInfo.operation_start_at && metaInfo.operation_start_at !== metaInfo.operation_end_at) {
      endAt = moment.parseZone(metaInfo.operation_end_at).format('YYYY-MM-DD LT Z')
    }

    const timezone = !metaInfo.fault && metaInfo.reference_timezone

    const template = `
      <div class='mb-2 advisory-dataset'>
        <span data-popover data-toggle="collapse" data-target="#metaInfo" aria-expanded="true">
          {{#startAt}}
          <span>Daylight Hours</span>
          {{/startAt}}
          {{^startAt}}
          <span>Assessment Details</span>
          {{/startAt}}
        </span>
      </div>

      <div class="advisory-dataset-points collapse show" id="metaInfo">
        {{#sunrise}}
        <p>Civil Twilight Begin: <strong>{{sunrise}}</strong></p>
        {{/sunrise}}
        {{#sunset}}
        <p>Civil Twilight End: <strong>{{sunset}}</strong></p>
        {{/sunset}}
        {{#startAt}}
        <p>Operation Start: <strong>{{startAt}}</strong></p>
        {{/startAt}}
        {{#startDate}}
        <p>Start Date: <strong>{{startDate}}</strong></p>
        {{/startDate}}
        {{#endAt}}
        <p>Operation End: <strong>{{endAt}}</strong></p>
        {{/endAt}}
        {{#endDate}}
        <p>End Date: <strong>{{endDate}}</strong></p>
        {{/endDate}}
        {{#timezone}}
        <p>Timezone: <strong>{{timezone}}</strong></p>
        {{/timezone}}
      </div>
    `

    return Mustache.render(template, {
      metaInfo,
      sunrise,
      sunset,
      startAt,
      startDate,
      endAt,
      endDate,
      timezone
    })
  }

  show (onComplete = () => {}) {
    if (!this.isVisible()) {
      this.visible = true
      this.$panelHeaderIcon.addClass('rotated')
      this.$panelContent.slideDown(320, onComplete)
    } else {
      onComplete()
    }
  }

  hide (onComplete = () => {}) {
    if (this.isVisible()) {
      this.visible = false
      this.$panelHeaderIcon.removeClass('rotated')
      this.$panelContent.slideUp(320, onComplete)
    } else {
      onComplete()
    }
  }

  reset () {
    this.setContent(this.DEFAULT_HTML)
  }

  toggle () {
    this.isVisible() ? this.hide() : this.show()
  }

  setContent (html) {
    this.$panelContent.html(html)
  }

  handleAdvisorySuccess (res) {
    const parsedRes = new FlightAdvisoryResponse(res)
    const advisory = this.buildAdvisory(parsedRes)
    this.setContent(advisory)
  }

  handleAdvisoryError (jqXHR) {
    let errorMsg = this.ERROR_HTML
    try { // in case server return unexpected result like 500
      const errors = (jqXHR.responseJSON ? jqXHR.responseJSON.errors : [])
      if (errors[0] && errors[0].title) errorMsg += `<p class='px-3'>${errors[0].title}</p>`
    } finally {
      this.setContent(errorMsg)
    }
  }

  isVisible () {
    return this.visible
  }
}

// https://stackoverflow.com/questions/6234773/can-i-escape-html-special-chars-in-javascript
function escapeHtml (unsafe) {
  if (!unsafe) return ''

  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
}
