import { Controller } from '@hotwired/stimulus'

import toastr from '../src/init/toastr'

export default class extends Controller {
  static targets = ['pilotDropdown']
  static values = {
    path: String
  }

  connect () {
    const pilotSelectElement = this.element.querySelector('#operation_sf_pilot_id')

    pilotSelectElement.onchange = () => {
      const pilotId = this.pilotDropdownTarget.value
      const showRecencyPath = this.pathValue + '/' + pilotId

      $.get(showRecencyPath, { pilot_id: pilotId }, () => {
      }).done((msg) => {
        if (msg?.has_flights_within_3_months === false) {
          toastr.warning('This pilot has no flight record in past 3 months.')
        }
      })
    }
  }
}
