export const useAdsbLayer = (controller, { cesiumViewer, toolbar }) => {
  const adsbDataSource = new Cesium.CzmlDataSource('adsb-data-source')

  cesiumViewer.dataSources.add(adsbDataSource)

  const setupLayerToggle = () => {
    const buttonWrapper = $('<div/>', { class: 'cesium-button adsb-toggle-button' })
    const adsbToggleLabel = $('<label/>', { for: 'adsb-layer-toggle', class: 'mb-0 d-flex', text: 'ADSB Aircraft' })
    const adsbToggleInput = $('<input/>', { type: 'checkbox', id: 'adsb-layer-toggle', class: 'mr-2', checked: true })

    adsbToggleLabel.prepend(adsbToggleInput)
    buttonWrapper.append(adsbToggleLabel)
    toolbar.append(buttonWrapper)

    // TODO: teardown the event listener when the controller is disconnected
    adsbToggleInput.on('change', (e) => {
      if (e.target.checked) {
        controller.showAdsbLayer()
      } else {
        controller.hideAdsbLayer()
      }
    })
  }

  Object.assign(controller, {
    showAdsbLayer () {
      adsbDataSource.show = true
    },

    hideAdsbLayer () {
      adsbDataSource.show = false
    },

    updateAdsb ({ detail }) {
      adsbDataSource.process(buildCzmlPackage(detail))
    }
  })

  setupLayerToggle()
}

// data is an array of adsbItem like this:
// {
//   altitude_mm: 1531620
//   heading_de2: 11646
//   hor_velocity_cms: 13375
//   icao_address: '7C80AE'
//   lat_dd: -34.054451
//   lon_dd: 151.274752
//   time: 1705376735194
//   ver_velocity_cms:-812
//   emitter_type: 0
// }
const buildCzmlPackage = (adsbList) => {
  const currentTime = new Date()
  const currentTimeIso = currentTime.toISOString()

  // 1 hour ago
  const startTimeIso = new Date(currentTime.getTime() - 60 * 60 * 1000).toISOString()
  // 3 hour from now
  const endTimeIso = new Date(currentTime.getTime() + 3 * 60 * 60 * 1000).toISOString()

  const documentInterval = startTimeIso + '/' + endTimeIso

  const aircraftList = adsbList.map((adsbItem) => {
    const icaoAddress = adsbItem.icao_address
    const lat = adsbItem.lat_dd
    const lon = adsbItem.lon_dd
    const alt = adsbItem.altitude_mm / 1000
    const timestamp = adsbItem.time
    const horVelocity = adsbItem.hor_velocity_cms / 100
    const verVelocity = adsbItem.ver_velocity_cms / 100

    const description = getAircraftDescription(adsbItem)

    const aircraftTime = new Date(timestamp)
    const aircraftTimeIso = aircraftTime.toISOString()

    // 10 min ago.
    const startTimeIso = new Date(aircraftTime.getTime() - 10 * 60 * 1000).toISOString()

    // 1 min from now
    const endTimeIso = new Date(aircraftTime.getTime() + 60 * 1000).toISOString()

    const itemAvailability = startTimeIso + '/' + endTimeIso

    // const heading = adsbItem.heading_de2 / 100

    const props = {
      horVelocity,
      verVelocity,
      altitude: alt,
      lon,
      lat
    }
    const result = {
      id: icaoAddress,
      properties: props,
      availability: itemAvailability,
      name: icaoAddress,
      description,
      path: {
        material: {
          solidColor: {
            color: {
              rgba: [255, 255, 0, 188]
            }
          }
        },
        width: 2,
        leadTime: 30,
        trailTime: 120
      },
      // Position is an interpolatable property: https://github.com/AnalyticalGraphicsInc/czml-writer/wiki/InterpolatableProperty
      position: {
        forwardExtrapolationType: 'HOLD',
        interpolationAlgorithm: 'LAGRANGE',
        interpolationDegree: 2,
        epoch: aircraftTimeIso,
        cartographicDegrees: [
          aircraftTimeIso, lon, lat, alt
        ]
      }
    }

    result.point = {
      color: {
        rgba: [255, 20, 20, 220]
      },
      pixelSize: 16,
      scaleByDistance: {
        nearFarScalar: [2000, 1, 20000, 0.4]
      }
    }

    return result
  })

  // Construct the CZML object for the path
  return [
    {
      id: 'document',
      version: '1.0',
      clock: {
        interval: documentInterval,
        currentTime: currentTimeIso,
        multiplier: 1,
        clockRange: Cesium.ClockRange.UNBOUNDED,
        clockStep: Cesium.ClockStep.SYSTEM_CLOCK_MULTIPLIER
      }
    },
    ...aircraftList
  ]
}

const getAircraftDescription = (adsbItem) => {
  const icaoAddress = adsbItem.icao_address
  const lat = adsbItem.lat_dd
  const lon = adsbItem.lon_dd
  const alt = adsbItem.altitude_mm / 1000
  const heading = adsbItem.heading_de2 / 100
  const horVelocity = adsbItem.hor_velocity_cms / 100
  const verVelocity = adsbItem.ver_velocity_cms / 100

  return `
    <table class="cesium-infoBox-defaultTable">
      <tbody>
        <tr>
          <th>ICAO Address</th>
          <td>${icaoAddress}</td>
        </tr>
        <tr>
          <th>Latitude</th>
          <td>${lat}</td>
        </tr>
        <tr>
          <th>Longitude</th>
          <td>${lon}</td>
        </tr>
        <tr>
          <th>Altitude</th>
          <td>${alt} m</td>
        </tr>
        <tr>
          <th>Heading</th>
          <td>${heading} deg</td>
        </tr>
        <tr>
          <th>Horizontal Velocity</th>
          <td>${horVelocity} m/s</td>
        </tr>
        <tr>
          <th>Vertical Velocity</th>
          <td>${verVelocity} m/s</td>
        </tr>
      </tbody>
    </table>
  `
}
