import { Controller } from '@hotwired/stimulus'

import { withSummaryItems } from '../src/mixins/with_summary_items'

export default class extends Controller {
  static targets = ['totalAssets', 'totalDrones', 'totalBatteries', 'totalOthers']

  connect () {
    withSummaryItems(this)
  }

  update ({ detail: { recordsTotal, totalDrones, totalBatteries, totalOthers } }) {
    this.presentSummaryItemNumber(this.totalAssetsTarget, recordsTotal)
    this.presentSummaryItemNumber(this.totalDronesTarget, totalDrones)
    this.presentSummaryItemNumber(this.totalBatteriesTarget, totalBatteries)
    this.presentSummaryItemNumber(this.totalOthersTarget, totalOthers)
  }
}
