/* global $ */
import { Controller } from '@hotwired/stimulus'

// Hide the sections if use does not need to see, based on user select
export default class extends Controller {
  static targets = ['toggle']

  connect () {
    const $toggleTarget = $(this.toggleTarget)

    // TODO: This code was copied from jQuery and needs to be refactored
    $(this.element).on('select2:select select2:clear', '[data-hide-on-select]', function () {
      const $select = $(this)
      const selectedValue = $select.val()
      const shouldHide = selectedValue === '' || selectedValue === $select.data('hide-on-select')
      const toggleValue = shouldHide ? 'hide' : 'show'

      $toggleTarget.collapse(toggleValue)
    })
  }
}
