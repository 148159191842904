import { Controller } from '@hotwired/stimulus'

import anime from 'animejs/lib/anime.es.js'

function numberWithCommas (x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
}

export default class extends Controller {
  presentNumber ({ params: { value } }) {
    this._presentValue(numberWithCommas(value))
  }

  _presentValue (value) {
    // Stop current animation
    anime.remove(this.element)

    // Hide the element value
    anime.set(this.element, {
      opacity: 0,
      translateY: 0
    })

    // Update the element value (hidden)
    this.element.textContent = value

    // Animate in the new value
    anime({
      targets: this.element,
      translateY: -10,
      loop: false,
      easing: 'spring(1, 75, 15, 10)',
      opacity: 1,
      delay: () => {
        return anime.random(0, 180)
      }
    })
  }
}
