import { Controller } from '@hotwired/stimulus'

import { initDisplayMap } from '../src/init/curo-displaymap'

export default class extends Controller {
  static values = {
    paths: Array,
    points: Array,
    polygons: Array,
    mapTypeId: { type: String, default: 'hybrid' }
  }

  connect () {
    this.displayMap = initDisplayMap(this.element, {
      paths: this.pathsValue || [],
      points: this.pointsValue || [],
      polygons: this.polygonsValue || []
    })

    this.displayMap.map.setOptions({ mapTypeId: this.mapTypeIdValue })
  }
}
