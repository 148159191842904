/* global $ */
import { Controller } from '@hotwired/stimulus'

import toastr from '../src/init/toastr'

const READ_ONLY_INPUTS = 'input[name="incident_user[name]"], input[name="incident_user[email]"]'

/**
 * Populates the incident user form with the details of the selected contact
 *
 * TODO: This code was copied from jQuery and needs to be refactored
 */
export default class extends Controller {
  static values = {
    contactsUrl: String
  }

  connect () {
    $(this.element).on('select2:select', '#incident_user_sf_contact_id', this.selectUser.bind(this))
    $(this.element).on('select2:unselect', '#incident_user_sf_contact_id', this.unselectUser.bind(this))
  }

  get $user_form () {
    return $(this.element)
  }

  selectUser (event) {
    const data = event.params.data
    const contactSfId = data.id
    const contactUrl = [this.contactsUrlValue, contactSfId].join('/')

    this.clearForm()

    this.$user_form.find(READ_ONLY_INPUTS).attr('readonly', true).attr('disabled', true)

    $.ajax({ url: contactUrl })
      .fail(function (msg) {
        toastr.error('Unable to retreive Contact details!')
      })
      .done((data) => {
        this.assignContact(data.contact)
      })
  }

  unselectUser (event) {
    this.$user_form.find(READ_ONLY_INPUTS).attr('readonly', false).attr('disabled', false)
    this.clearForm()
  }

  clearForm () {
    this.element.querySelectorAll('input:not([type="submit"])').forEach(input => {
      input.value = ''
    })
  }

  assignContact (contact) {
    for (const attribute in contact) {
      const value = contact[attribute]
      const $input = this.$user_form.find(`[name="incident_user[${attribute}]"]`)

      if ($input.is('input[type="checkbox"]')) {
        $input.prop('checked', value)
      } else if (value) {
        $input.val(value)
      } else {
        $input.val('')
      }
    }
  }
}
