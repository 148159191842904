import { Controller } from '@hotwired/stimulus'

import moment from 'moment'

// Displays the time for a date on the current day, otherwise falls back to day month yeaer
export default class extends Controller {
  static values = {
    dateFormat: { type: String, default: 'DD MMM YYYY' },
    timeFormat: { type: String, default: 'h:mm a' }
  }

  connect () {
    const timeInZone = moment(this.element.getAttribute('datetime'))
    if (timeInZone.isSame(new Date(), 'day')) {
      this.element.innerHTML = timeInZone.format(this.timeFormatValue)
    } else {
      this.element.innerHTML = timeInZone.format(this.dateFormatValue)
    }
  }
}
