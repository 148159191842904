export const useLocalTimeClock = (controller, { cesiumViewer }) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  function twoDigits (num) {
    return num.toString().padStart(2, '0')
  }

  // Override the AnimationViewModel.DateFormatter - Gets or sets the default date formatter
  // Update the clock with local date
  // https://github.com/CesiumGS/cesium/blob/1.115/packages/widgets/Source/Animation/AnimationViewModel.js#L387
  cesiumViewer.animation.viewModel.dateFormatter = function (date, _viewModel) {
    // Cesium.JulianDate.toDate(date) converts the JulianDate to a local time Date by using js Date() constructor
    date = Cesium.JulianDate.toDate(date)
    const month = date.getMonth()
    const day = date.getDate()
    const year = date.getFullYear()

    return `${monthNames[month]} ${day} ${year}`
  }

  // Override the AnimationViewModel.TimeFormatter - A function that formats a time for display.
  // update the clock with local time
  // https://github.com/CesiumGS/cesium/blob/1.115/packages/widgets/Source/Animation/AnimationViewModel.js#L438
  cesiumViewer.animation.viewModel.timeFormatter = function (date, _viewModel) {
    date = Cesium.JulianDate.toDate(date)
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()

    return `${twoDigits(hour)}:${twoDigits(minute)}:${twoDigits(second)}`
  }

  // Override Timeline.makeLabel function
  // Update the Timeline with local time
  // https://github.com/CesiumGS/cesium/blob/1.115/packages/widgets/Source/Timeline/Timeline.js#L380
  Cesium.Timeline.prototype.makeLabel = function (time) {
    const localDate = Cesium.JulianDate.toDate(time)
    const month = localDate.getMonth()
    const day = localDate.getDate()
    const year = localDate.getFullYear()
    const hour = localDate.getHours()
    const minute = localDate.getMinutes()
    const second = localDate.getSeconds()
    const millisecond = localDate.getMilliseconds()

    let millisecondString = ''
    if (millisecond > 0 && this._timeBarSecondsSpan < 3600) {
      millisecondString = Math.floor(millisecond).toString()
      while (millisecondString.length < 3) {
        millisecondString = `0${millisecondString}`
      }
      millisecondString = `.${millisecondString}`
    }

    return `${monthNames[month]} ${day} ${year
      } ${twoDigits(hour)}:${twoDigits(minute)}:${twoDigits(
        second
      )}${millisecondString}`
  }

  // refresh timeline
  cesiumViewer.timeline.zoomTo(cesiumViewer.clock.startTime, cesiumViewer.clock.stopTime)
}
