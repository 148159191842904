import { Controller } from '@hotwired/stimulus'

// This controller is used to reload a turbo frame from a turbo stream.
//
// Usage:
//   <template data-controller="reload-turbo-frame" data-reload-turbo-frame-turbo-frame-id-value="my-turbo-frame-id"></template>
//
//   # Forces the turbo frame with id "my-turbo-frame-id" to reload
//   <turbo-frame id="my-turbo-frame-id" src="/my-turbo-frame-target"></turbo-frame>
export default class extends Controller {
  static values = { frameId: String }

  connect () {
    this.reloadTurboFrame()
    this.element.remove()
  }

  reloadTurboFrame () {
    const turboFrameElement = document.getElementById(this.frameIdValue)
    if (turboFrameElement) {
      turboFrameElement.reload()
    } else {
      console.error(`Turbo frame with id ${this.frameIdValue} not found.`)
    }
  }
}
