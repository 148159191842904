import { Controller } from '@hotwired/stimulus'

/**
 * Allows a form to be displayed within a collapsable panel.
 *
 * The focusToggle event will display a form with the referenced element selected.
 */
export default class extends Controller {
  static classes = ['active', 'collapse']
  static targets = ['content', 'toggleButton']

  connect () {
    const $contentTarget = $(this.contentTarget)

    $contentTarget.on('show.bs.collapse', () => {
      $(this.toggleButtonTarget).addClass(this.activeClass)
    })

    $contentTarget.on('hide.bs.collapse', () => {
      $(this.toggleButtonTarget).removeClass(this.activeClass)
    })

    // NOTE: hiding using html prevents select2 from working
    setTimeout(() => {
      $contentTarget.addClass(this.collapseClass)
    }, 0)
  }

  toggle (event) {
    $(this.contentTarget).collapse('toggle')
  }

  focusToggle (event) {
    const $contentTarget = $(this.contentTarget)
    const $clickTarget = $(this.element).find('#' + event.params.target)

    // Display select2 or datepicker when clicking on a filter
    $contentTarget.off('shown.bs.collapse').on('shown.bs.collapse', () => {
      if ($clickTarget.is('select')) {
        $clickTarget.select2('open') // select2 input
      } else {
        $clickTarget.trigger('click')
      }
    })
    $contentTarget.on('hidden.bs.collapse', () => {
      // Prevent UI from opening on next click
      $contentTarget.off('hidden.bs.collapse').off('shown.bs.collapse')
    })

    this.toggle(event)
  }
}
