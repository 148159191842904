import { Controller } from '@hotwired/stimulus'

// disable mousewheel on a input number field when in focus
// (to prevent browsers change the value when scrolling)
export default class extends Controller {
  connect () {
    this.disableCallback = this.preventScrollEvent.bind(this)
  }

  disconnect () {
    this.enableScroll()
  }

  disableScroll () {
    this.element.addEventListener('wheel', this.disableCallback)
  }

  enableScroll () {
    this.element.removeEventListener('wheel', this.disableCallback)
  }

  preventScrollEvent (event) {
    event.preventDefault()
  }
}
