import { Controller } from '@hotwired/stimulus'

import { withStatusPolling } from '../../src/mixins/with_status_polling'
/**
 * Retrieves the HubX Status from the Hetero Connect
 *
 * Broadcasts it to the page (via a statusUpdate event)
 */
export default class extends Controller {
  static values = {
    refreshRate: {
      type: Number,
      default: 1_000
    },
    url: String
  }

  connect () {
    withStatusPolling(this, this.urlValue, this.refreshRateValue, this.dispatchStatusUpdate.bind(this))
  }

  dispatchStatusUpdate (res) {
    const hubStatus = res || {}

    // Broadcast a statusUpdate event to the page
    this.dispatch('statusUpdate', { detail: hubStatus })
  }
}
