import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.originalPosition = this.element.innerHTML
  }

  disconnect () {
  }

  update ({ detail: { position } }) {
    this.#updatePosition(position || {})
  }

  #updatePosition ({ latitude, longitude }) {
    if (latitude && longitude) {
      const lat = parseFloat(latitude).toFixed(3)
      const lng = parseFloat(longitude).toFixed(3)

      this.element.innerHTML = `${lat},${lng}`
    } else {
      this.element.innerHTML = this.originalPosition
    }
  }
}
