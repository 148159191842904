import { Controller } from '@hotwired/stimulus'

import { withStatusPolling } from '../../src/mixins/with_status_polling'

/**
 * Retrieves the Adsb data (from our aws lambda)
 *
 * Broadcasts it to the page (via a AdsbUpdate event)
 */
export default class extends Controller {
  static values = {
    refreshRate: {
      type: Number,
      default: 3_000
    },
    url: String
  }

  connect () {
    withStatusPolling(this, this.urlValue, this.refreshRateValue, this.dispatchAdsbUpdate.bind(this))
  }

  dispatchAdsbUpdate (res) {
    const adsbList = res.data

    // === Start of testing locally ===
    // Fly from -33.965440, 151.183419 to -33.852845, 151.210504 over 1 min based on current second value
    // const startLat = -33.965440
    // const startLon = 151.183419

    // const endLat = -33.852845
    // const endLon = 151.210504

    // const adsbList = [{
    //   altitude_mm: 33000,
    //   heading_de2: 11646,
    //   hor_velocity_cms: 13375,
    //   icao_address: 'Aircraft-1',
    //   lat_dd: startLat + (endLat - startLat) * (new Date().getSeconds() / 60),
    //   lon_dd: startLon + (endLon - startLon) * (new Date().getSeconds() / 60),
    //   time: Date.now(),
    //   ver_velocity_cms: -812,
    //   emitter_type: 0
    // },
    // {
    //   altitude_mm: 1291900,
    //   heading_de2: 11646,
    //   hor_velocity_cms: 13375,
    //   icao_address: 'Aircraft-2',
    //   lat_dd: startLat + (endLat - startLat) * (new Date().getSeconds() / 60) + 0.02,
    //   lon_dd: startLon + (endLon - startLon) * (new Date().getSeconds() / 60),
    //   time: Date.now(),
    //   ver_velocity_cms: -812,
    //   emitter_type: 0
    // }
    // ]
    // === End of testing locally ===

    if (!adsbList) {
      return
    }

    // Broadcast a adsbUpdate event to the page
    this.dispatch('adsbUpdate', { detail: adsbList })
  }
}
