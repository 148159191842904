export const mapboxUrlGenerator = (accessToken, operationType, isStaging = false) => {
  if (isStaging) {
    return `https://api.mapbox.com/v4/curo.${operationType}_stg/{z}/{x}/{y}.vector.pbf?sku=101i8kmos7hzu&access_token=${accessToken}`
  }

  return `https://api.mapbox.com/v4/curo.${operationType}/{z}/{x}/{y}.vector.pbf?sku=101i8kmos7hzu&access_token=${accessToken}`
}

export const rpasAdvisoriesUrlGenerator = (advisoryHost, operationType) => {
  return `${advisoryHost}/operations/${operationType}/rpas_advisory.geojson`
}

export const rpasHeliportsUrlGenerator = (advisoryHost, operationType) => {
  return `${advisoryHost}/operations/${operationType}/datasets/rpas_platform_hls_with_instrument_approach.geojson`
}

export const incidentsUrlGenerator = (advisoryHost, operationType) => {
  return `${advisoryHost}/operations/${operationType}/emergencies.geojson`
}

export const datasetsUrlGenerator = (advisoryHost, operationType) => {
  return `${advisoryHost}/operations/${operationType}/datasets/filter`
}

export const notificationsUrlGenerator = (advisoryHost, operationType) => {
  return `${advisoryHost}/operations/${operationType}/notifications.json`
}
