import { Controller } from '@hotwired/stimulus'

import { digProperty } from '../src/mixins/dig'

import moment from 'moment'

// Displays a timestamp from a Live Drone or Live Remote
export default class extends Controller {
  static values = {
    // Tuesday 03 December 2023, 02:40pm +10:00
    format: { type: String, default: 'dddd DD MMMM YYYY, h:mma Z' },
    field: String
  }

  connect () {
    this.currentTimestamp = 0
    this.reload()
  }

  disconnect () {
    $(this.element).tooltip('dispose')
    if (this.timeoutRef) clearTimeout(this.timeoutRef)
  }

  update ({ detail }) {
    const value = digProperty(this.fieldValue, detail)
    if (value) {
      this.updateFromValue(value)
    }
  }

  updateFromValue (timestamp) {
    const roundedTimestamp = this.roundTimestamp(timestamp)

    if (roundedTimestamp && roundedTimestamp !== this.currentTimestamp) {
      // Update the current datetime
      this.datetime = new Date(timestamp)

      // Reload the display text and tooltip
      this.reload()

      // Don't change unless the timestamp has changed
      this.currentTimestamp = roundedTimestamp
    }
  }

  get datetime () {
    return this.element.getAttribute('datetime')
  }

  /**
   * @param {{ toISOString: () => string; }} datetime
   */
  set datetime (datetime) {
    this.element.setAttribute('datetime', datetime.toISOString())
  }

  reload () {
    const momentTime = moment(this.datetime)
    this.element.innerHTML = momentTime.fromNow()

    const tooltipText = momentTime.format(this.formatValue)
    this.element.setAttribute('title', tooltipText)
    $(this.element).tooltip('dispose').tooltip({ title: tooltipText })
  }

  // time is only displayed in minute intervals, so clear seconds
  roundTimestamp (timestamp) {
    if (timestamp) {
      const roundedTimestamp = new Date(timestamp)
      roundedTimestamp.setSeconds(0, 0)
      return roundedTimestamp.valueOf()
    }
  }
}
