import { Controller } from '@hotwired/stimulus'

/**
 * Sets up a Boostrap 4 Popover
 *
 * @see https://getbootstrap.com/docs/4.0/components/popovers/
 */
export default class extends Controller {
  connect () {
    $.fn.popover && $(this.element).popover()
  }

  disconnect () {
    $.fn.popover && $(this.element).popover('dispose')
  }
}
