import { Controller } from '@hotwired/stimulus'

import 'select2/dist/js/select2'

export default class extends Controller {
  static values = {
    url: String,
    placeholder: String
  }

  connect () {
    const $el = $(this.element)
    const multipleSelect = $el.is('[multiple]')

    $el.select2({
      ajax: {
        url: this.urlValue,
        dataType: 'json',
        delay: 321,
        cache: true,
        data: function (params) {
          const query = {
            search: params.term,
            page: params.page || 1
          }
          return query
        }
      },
      placeholder: this.placeholderValue,
      allowClear: !multipleSelect,
      closeOnSelect: !multipleSelect
    })
  }

  disconnect () {
    $(this.element).select2('destroy')
  }
}
