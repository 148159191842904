import { Controller } from '@hotwired/stimulus'
import consumer from '../channels/consumer'

export default class extends Controller {
  static values = {
    url: String,
    interval: {
      type: Number,
      default: 5000
    },
    channelArguments: Object
  }

  connect () {
    this.connectToChannel()
    this.sendRequestInterval = setInterval(() => {
      this.sendRequest()
    }, this.intervalValue)
  }

  disconnect () {
    clearInterval(this.sendRequestInterval)
    this.channel.unsubscribe()
  }

  sendRequest () {
    $.ajax({
      method: 'PUT',
      url: this.urlValue
    }).fail(err => {
      console.log(err)
    })
  }

  connectToChannel () {
    this.channel = consumer.subscriptions.create(
      this.channelArgumentsValue
    )
  }
}
