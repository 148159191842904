import { Controller } from '@hotwired/stimulus'

import moment from 'moment-timezone'

import { PolygonPicker } from '../src/display-map/polygon-picker'
import { FlightAdvisoryService } from '../src/flight-advisory/flight-advisory-service'

const CATEGORY_OPERATION_TYPES = {
  recreational: 'recreational',
  commercial_included: 'reoc',
  commercial_excluded: 'excluded_rpa'
}

export default class extends Controller {
  static targets = ['assessmentDuration', 'categorySelect', 'polygonPicker']

  connect () {
    const $polygonPicker = $(this.polygonPickerTarget)
    const advisoryHost = $polygonPicker.data('curo-advisory-url')
    const flightAdvisoryService = new FlightAdvisoryService(advisoryHost)

    const polygonPicker = new PolygonPicker($polygonPicker)
    polygonPicker.setupBasicDrawingManager()
    polygonPicker.setupFlightAdvisory($polygonPicker, {
      advisoryHost,
      mapboxAccessToken: $polygonPicker.data('mapbox-access-token'),
      operationType: $polygonPicker.data('operation-type'),
      advisoriesLookup: ({ point, areas, operationType }) => {
        const assessmentDuration = this.assessmentDurationTarget.value
        const startDate = assessmentDuration.split(' - ')[0] || moment().format('YYYY-MM-DD')
        const endDate = assessmentDuration.split(' - ')[1] || startDate

        // NOTE: As operating conditions are optional and dynamic; using jQuery selector as fallback
        const maxAltitude = $('input.operating_conditions_max_altitude').val()

        return flightAdvisoryService.checkAssessment({
          point,
          areas,
          operationType,
          startDate,
          endDate,
          maxAltitude
        })
      }
    })
    polygonPicker.init()
    polygonPicker.zoomToFitBounds()

    // Listen to changes for the Operation Type
    // NOTE: Can use StimulusJS actions, but calling a stop on further refactoring (10/11/2022)
    $(this.categorySelectTarget).on('change', function () {
      const operationType = CATEGORY_OPERATION_TYPES[$(this).val()]
      if (operationType) {
        polygonPicker.setOperationType(operationType)
      }
    })
    $(this.assessmentDurationTarget).on('change', function () {
      polygonPicker.reloadFlightAdvisories()
    })
    $('input.operating_conditions_max_altitude').on('change', function () {
      polygonPicker.reloadFlightAdvisories()
    })
  }
}
