import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    accessToken: String,
    removeBackground: Boolean,
    pathUrl: String
  }

  connect () {
    Cesium.Ion.defaultAccessToken = this.accessTokenValue

    this.viewer = new Cesium.Viewer(this.element, {
      fullscreenElement: this.element
    })

    this.beautifyCredit()
    if (this.removeBackgroundValue) this.removeBackground()

    if (this.hasPathUrlValue) {
      this.addCzml(this.pathUrlValue)
    }
  }

  disconnect () {
    this.viewer.destroy()
  }

  addCzml (czmlUrl) {
    // TODO: zoom to bounding box
    const dataSource = new Cesium.CzmlDataSource()
    const czmlPromise = dataSource.load(czmlUrl)
    this.viewer.dataSources.add(czmlPromise)
    this.viewer.homeButton.viewModel.command.beforeExecute.addEventListener(
      (e) => {
        e.cancel = true
        this.viewer.zoomTo(czmlPromise)
      })
    this.viewer.zoomTo(czmlPromise)
  }

  removeBackground () {
    this.viewer.scene.globe.baseColor = Cesium.Color.LIGHTGREY
    this.viewer.scene.backgroundColor = Cesium.Color.ALICEBLUE

    this.viewer.scene.imageryLayers.removeAll()
    this.viewer.scene.sun.destroy()
    this.viewer.scene.sun = undefined

    this.viewer.scene.skyBox.destroy()
    this.viewer.scene.skyBox = undefined

    this.viewer.scene.skyAtmosphere.show = false
    this.viewer.scene.fog.enabled = false
    this.viewer.scene.globe.showGroundAtmosphere = false
  }

  beautifyCredit () {
    this.viewer.scene.postRender.addEventListener(function (scene, time) {
      $('.cesium-credit-logoContainer a').html('Imagery: Cesium ION')
    })
  }
}
