import { Controller } from '@hotwired/stimulus'

/**
 * Adds search params from the field that triggered the encode event to all anchor tags
 *
 * @see https://thoughtbot.com/blog/dynamic-forms-with-turbo
 */
export default class extends Controller {
  static targets = ['anchor']

  encode ({ target: { name, value } }) {
    for (const anchor of this.anchorTargets) {
      anchor.search = new URLSearchParams({ [name]: value })
    }
  }
}
