import { Controller } from '@hotwired/stimulus'

import moment from 'moment'

// Formats and displays the datetime value
export default class extends Controller {
  static values = {
    format: String
  }

  connect () {
    const timeInZone = moment(this.element.getAttribute('datetime'))
    this.element.innerHTML = timeInZone.format(this.formatValue)
  }
}
