import { MVTLayer } from '@deck.gl/geo-layers'
import { getLineColor, getFillColor } from './colors'

// url: url of the mapbox map
// layer_names: array of layers' name to render
export const buildMapboxLayer = (url, layerNames) => {
  const loaderOptions = {
    mvt: {
      layers: layerNames
    }
  }

  return new MVTLayer({
    data: url,
    getLineWidth: 2,
    lineWidthMinPixels: 1,
    loadOptions: loaderOptions,
    pickable: true,
    getLineColor: (object) => getLineColor(object),
    getFillColor: (object) => getFillColor(object),
    onClick: ({ object }) => console.log(object)
  })
}
