// TODO: get 2 better designs for the marker
const markerUrl = 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
const markerHighlightedUrl = 'http://maps.google.com/mapfiles/ms/icons/orange-dot.png'

// There is no "icon" option for the marker anymore, so we have to create an element and pass it to the marker according to the document https://developers.google.com/maps/documentation/javascript/advanced-markers/graphic-markers#javascript

const markerIconElement = () => {
  const element = document.createElement('img')
  element.src = markerUrl

  return element
}

const markerHighlightedIconElement = () => {
  const element = document.createElement('img')
  element.src = markerHighlightedUrl

  return element
}

export const markerOpt = () => {
  return {
    content: markerIconElement()
  }
}

export const highlightMarkerOpt = () => {
  return {
    content: markerHighlightedIconElement()
  }
}

// Builds a dull blue Marker, hopefully it has brighter friends
export const buildDefaultMarker = ({ map, title, latitude, longitude }) => {
  const marker = new google.maps.marker.AdvancedMarkerElement({
    map,
    title,
    position: new google.maps.LatLng(latitude, longitude),
    ...markerOpt()
  })

  marker.content.classList.add('drop') // Drop animation is now require a css as described in the document https://developers.google.com/maps/documentation/javascript/examples/advanced-markers-animation#maps_advanced_markers_animation-css
  return marker
}

// Builds a Fancy Marker in (close to) Curo Orange
export const buildHighlightedMarker = ({ map, title, latitude, longitude }) => {
  const marker = new google.maps.marker.AdvancedMarkerElement({
    map,
    title,
    position: new google.maps.LatLng(latitude, longitude),
    ...highlightMarkerOpt()
  })

  marker.content.classList.add('drop')
  return marker
}

export const hightlightMarker = (marker) => {
  marker.content = markerHighlightedIconElement()
}

export const removeMarkerHighlight = (marker) => {
  marker.content = markerIconElement()
}
