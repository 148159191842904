import { Controller } from '@hotwired/stimulus'

import 'select2/dist/js/select2'

// Fixes an issue where search results where being excluded from the search results,
// when only the group matches the search term
function matchGroupAndTitle (params, data) {
  // If there are no search terms, return all of the data
  if ($.trim(params.term) === '') {
    return data
  }

  // Do not display the item if there is no 'text' property
  if (typeof data.text === 'undefined') {
    return null
  }

  // check if groups parent matches the search term
  if (data.text.toUpperCase().indexOf(params.term.toUpperCase()) > -1) {
    return data
  }

  // `data.children` contains the actual options that we are matching against
  if (typeof data.children !== 'undefined') {
    const filteredChildren = []
    $.each(data.children, function (idx, child) {
      if (child.text.toUpperCase().indexOf(params.term.toUpperCase()) > -1) {
        filteredChildren.push(child)
      }
    })

    // If we matched any of the group's children, then set the matched children on the group
    // and return the group object
    if (filteredChildren.length) {
      const modifiedData = $.extend({}, data, true)
      modifiedData.children = filteredChildren
      return modifiedData
    }
  }

  // Return `null` if the term should not be displayed
  return null
}

export default class extends Controller {
  static values = {
    placeholder: String,
    includeBlank: { type: Boolean, default: true }
  }

  connect () {
    $(this.element).select2({
      placeholder: this.placeholderValue,
      allowClear: this.includeBlankValue && !$(this.element).is('[readonly]'),
      closeOnSelect: !$(this.element).is('[multiple]'),
      matcher: matchGroupAndTitle,
      dropdownParent: $(this.element).closest('.modal').length ? $(this.element).closest('.modal') : ''
    })
  }

  disconnect () {
    $(this.element).select2('destroy')
  }
}
