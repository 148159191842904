import { Controller } from '@hotwired/stimulus'

const BUFFER_TO_BOTTOM = 60 // Hardcode margin, not sure why @lyu?

export default class extends Controller {
  static targets = ['acceptButton', 'closeButton', 'modalBody', 'modalFooter']

  connect () {
    if (this.hasNotAcceptedTerms) {
      // Remove any menu items that would prevent the user from accepting the terms
      $(this.closeButtonTarget).hide()
      $(this.acceptButtonTarget).attr('disabled', true)
      $(this.modalFooterTarget).show()

      this.scrollHandler = () => {
        if (this.hasScrolledToBottom) {
          $(this.acceptButtonTarget).attr('disabled', false)
          $(this.modalFooterTarget).tooltip('dispose')
        }
      }

      // Enable the accept button once the user has scrolled to the bottom
      $(this.modalBodyTarget).on('scroll', this.scrollHandler)

      // Show the modal
      this.showMandatoryTermsOfUse()
    } else {
      // Allow the user to dismiss the modal
      $(this.closeButtonTarget).show()
      $(this.modalFooterTarget).hide()
    }
  }

  disconnect () {
    if (this.scrollHandler) {
      $(this.modalBodyTarget).off('scroll', this.scrollHandler)
      this.scrollHandler = undefined
    }
  }

  acceptTerms () {
    // Flag the Cookie as terms accepted
    document.cookie = 'curo_terms_agreed=true; expires=Fri, 31 Dec 2222 23:59:59 GMT; path=/'

    // Hide the Modal and remove all accept terms elements
    $(this.element).modal('hide')
    $(this.closeButtonTarget).show()
    $(this.modalFooterTarget).hide()
  }

  showMandatoryTermsOfUse () {
    $(this.element).modal({
      show: true,
      focus: true,
      backdrop: 'static',
      keyboard: false
    })
  }

  show () {
    $(this.element).modal('show')
  }

  get hasNotAcceptedTerms () {
    return document.cookie.indexOf('curo_terms_agreed=true') < 0
  }

  get hasScrolledToBottom () {
    return (this.modalBodyTarget.clientHeight + this.modalBodyTarget.scrollTop) >= (this.modalBodyTarget.scrollHeight - BUFFER_TO_BOTTOM)
  }
}
