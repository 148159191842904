import { method } from '../utils'

export const useWindowScrolled = (controller) => {
  const scrollFunc = () => {
    method(controller, 'windowScrolled').call(controller, window.scrollX, window.scrollY)
  }

  const controllerDisconnect = controller.disconnect.bind(controller)

  const observe = () => {
    window.addEventListener('scroll', scrollFunc)
  }
  const unobserve = () => {
    window.removeEventListener('scroll', scrollFunc)
  }

  Object.assign(controller, {
    disconnect () {
      unobserve()
      controllerDisconnect()
    }
  })

  observe()
}
