import { Controller } from '@hotwired/stimulus'

class Signer {
  constructor (url) {
    this.url = url
  }

  getSignedURL () {
    return this.url
  }
}

export default class extends Controller {
  static targets = [
    'remoteView',
    'bitRate',
    'frameRate',
    'packageLost',
    'status',
    'networkLatency'
  ]

  static values = {
    clientId: String,
    url: String,
    iceServers: Array,
    endpointsByProtocol: Object
  }

  async connect () {
    this.setupHtmlElementProperties()
    this.setupSignalingClient()
    this.setupIceCandidate()
    this.setupVideoSource()
    this.viewer.signalingClient.open()
    this.reportInterval = setInterval(() => {
      this.reportStats()
    }, 2000)
  }

  disconnect () {
    clearInterval(this.reportInterval)
    this.viewer.signalingClient.close()
    this.viewer.peerConnection.close()
  }

  setupHtmlElementProperties () {
    // <video> HTML elements to use to display the local webcam stream and remote stream from the master
    this.viewer = {}

    this.viewer.remoteView = this.remoteViewTarget
    this.viewer.iceServers = this.iceServersValue
    this.viewer.endpointsByProtocol = this.endpointsByProtocolValue

    this.bitRateTarget.textContent = '-'
    this.frameRateTarget.textContent = '-'
    this.packageLostTarget.textContent = '-'
    this.networkLatencyTarget.textContent = '-'
  }

  setupSignalingClient () {
    this.viewer.peerConnection = new window.RTCPeerConnection({ iceServers: this.viewer.iceServers })
    this.viewer.peerConnection.onerror = error => {
      if (error) {
        this.statusTarget.textContent = 'Real time connection build failed ...'
      }
    }
    this.viewer.signalingClient = new window.KVSWebRTC.SignalingClient({
      channelARN: 'default',
      channelEndpoint: 'default',
      region: 'default',
      clientId: this.clientIdValue,
      role: window.KVSWebRTC.Role.VIEWER,
      requestSigner: new Signer(this.urlValue)
    })
    this.viewer.signalingClient.on('open', async () => {
      const offer = await this.viewer.peerConnection.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true
      })
      await this.viewer.peerConnection.setLocalDescription(offer)
      this.viewer.signalingClient.sendSdpOffer(this.viewer.peerConnection.localDescription)
    })

    // When the SDP answer is received back from the master, add it to the peer connection.
    this.viewer.signalingClient.on('sdpAnswer', async answer => {
      await this.viewer.peerConnection.setRemoteDescription(answer)
    })

    // When an ICE candidate is received from the master, add it to the peer connection.
    this.viewer.signalingClient.on('iceCandidate', candidate => {
      this.viewer.peerConnection.addIceCandidate(candidate)
      // TODO: addIceCandidate
    })

    this.viewer.signalingClient.on('close', () => {
      // Handle client closures
    })

    this.viewer.signalingClient.on('error', error => {
      console.log(error)

      this.statusTarget.textContent = 'Error connecting to KVS signaling client, probably there are more than 10 viewers at the same time.'
    })
  }

  setupIceCandidate () {
    this.viewer.peerConnection.onicecandidate = event => {
      if (event.candidate) {
        this.statusTarget.textContent = 'Looking for the video source (1/4)'
        // this.viewer.stateMachine.dispatch('next')
      }
    }
    this.viewer.peerConnection.oniceconnectionstatechange = () => {
      if (this.viewer.peerConnection.iceConnectionState === 'connected') {
        this.viewer.remoteView.oncanplaythrough = () => {
          this.statusTarget.textContent = 'Ready to play stream (4/4)'
        }

        this.viewer.remoteView.onplaying = () => {
          this.statusTarget.textContent = ''
        }

        this.viewer.remoteView.onwaiting = () => {
          this.statusTarget.textContent = 'Waiting for more data to play ... (3/4)'
        }
      }
    }
    this.viewer.peerConnection.addEventListener('icecandidate', ({ candidate }) => {
      if (candidate) {
        this.viewer.signalingClient.sendIceCandidate(candidate)
      } else {
        // No more ICE candidates will be generated
      }
    })
  }

  setupVideoSource () {
    // As remote tracks are received, add them to the remote view
    this.viewer.peerConnection.addEventListener('track', event => {
      if (this.viewer.remoteView.srcObject) {
        return
      }
      this.statusTarget.textContent = 'Setting up video source (2/4)'
      this.viewer.remoteView.srcObject = event.streams[0]
    })
  }

  reportStats () {
    this.viewer.peerConnection.getStats(null).then(statsCollection => {
      statsCollection.forEach((report) => {
        if (report.type === 'inbound-rtp' && report.kind === 'video') {
        // only video track
          if (this.lastReportTimestamp && this.lastReportBytesReceived && report.timestamp > this.lastReportTimestamp) {
            this.updateBitrate(report)
            this.updateFrameRate(report)
            this.updatePackageLost(report)
          }

          this.lastReportTimestamp = report.timestamp
          this.lastReportBytesReceived = report.bytesReceived
        }

        if (report.type === 'candidate-pair' && report.nominated && report.state === 'succeeded' && report.bytesReceived > 0) {
          this.updateNetworkLatency(report)
        }
      })
    })
  }

  updateBitrate (report) {
    const bytes = report.bytesReceived - this.lastBytesReceived
    const bitrate = Math.round(bytes * 8 / (report.timestamp - this.lastReportTimestamp))
    this.bitRateTarget.textContent = `${bitrate || 0}`
  }

  updateFrameRate (report) {
    const frameRate = report.framesPerSecond
    this.frameRateTarget.textContent = `${frameRate || 0}`
  }

  updatePackageLost (report) {
    const packageLost = report.packetsLost
    this.packageLostTarget.textContent = `${packageLost || 0}`
  }

  updateNetworkLatency (report) {
    const networkLatency = ((report.totalRoundTripTime / report.responsesReceived) * 1000).toFixed(2)
    this.networkLatencyTarget.textContent = `${networkLatency}`
  }
}
