const ID_PREFIX = 'datatables_filter_'
const DATATABLES_FILTER_SELECTOR = '[id^=' + ID_PREFIX + ']'

export const withDatatableFilters = (controller, filtersTarget, reloadCallback) => {
  // Listen to legacy change events
  $(filtersTarget).on('change', DATATABLES_FILTER_SELECTOR, reloadCallback)

  const controllerDisconnect = controller.disconnect.bind(controller)

  Object.assign(controller, {
    disconnect () {
      $(filtersTarget).off('change', DATATABLES_FILTER_SELECTOR, reloadCallback)
      controllerDisconnect()
    }
  })

  return {
    get filters () {
      const filterData = {}

      $(filtersTarget).find(DATATABLES_FILTER_SELECTOR).each(function () {
        const filterName = this.id.substring(ID_PREFIX.length)
        filterData[filterName] = $(this).val()
      })

      return filterData
    }
  }
}
