const COLOR = {
  BLOCK: [255, 25, 25],
  ADVISE: [255, 190, 11],
  INFO: [10, 110, 222],
  NO_ACTION: [180, 180, 180],
  DEFAULT: [10, 110, 222]
}
const LINE_OPACITY = 200
const FILL_OPACITY = 40
const TRANSPARENT_COLOR = [255, 255, 255, 0]

const getObjectColor = (object) => {
  if (object &&
    object.properties &&
    object.properties.severity in COLOR) {
    return COLOR[object.properties.severity]
  } else {
    return COLOR.DEFAULT
  }
}

export const getLineColor = (object) => {
  if (object.properties && object.properties.line_color) {
    return getObjectColor(object).concat(LINE_OPACITY)
  } else {
    return TRANSPARENT_COLOR
  }
}

export const getFillColor = (object) => {
  if (object.properties && object.properties.fill_color) {
    return getObjectColor(object).concat(FILL_OPACITY)
  } else {
    return TRANSPARENT_COLOR
  }
}
