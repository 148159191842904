import { Controller } from '@hotwired/stimulus'

import jQuery from 'jquery'

// Connects to data-controller="local-datatables--insert-row"
export default class extends Controller {
  static values = {
    tableId: String,
    rowId: String
  }

  connect () {
    // Find the local-datatable element
    const datatable = document.getElementById(this.tableIdValue)
    const localDatatableElement = datatable.closest(
      "[data-controller^='local-datatable']"
    )

    // Find the local-datatable controller
    const localDatatableController =
      this.application.getControllerForElementAndIdentifier(
        localDatatableElement,
        'local-datatable'
      )

    // Build the row data from the element's innerHTML
    const rowData = jQuery(this.element.innerHTML)

    // Do not add the row if one already exists
    const existingRow = localDatatableController.datatable.row(`#${this.rowIdValue}`)
    if (existingRow.length > 0) {
      return
    }

    // Insert the row into the table
    localDatatableController.datatable.row.add(rowData).draw()

    // Remove the element from the DOM
    this.element.remove()
  }
}
