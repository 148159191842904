import { Controller } from '@hotwired/stimulus'

import { withDaterangepicker } from '../src/mixins/with_daterangepicker'

// Add a date & time picker to input elements, by adding [data-controller="datetimepicker"]
export default class extends Controller {
  static values = {
    format: { type: String, default: 'YYYY-MM-DD HH:mm' }
  }

  connect () {
    this.daterangepicker = withDaterangepicker(this, this.element, {
      autoUpdateInput: false,
      timePicker: true,
      singleDatePicker: true,
      timePicker24Hour: true,
      locale: {
        format: this.formatValue,
        cancelLabel: 'Clear'
      }
    })

    // Handle the Update Input Functions
    this.applyCallback = this.updateInputFromPicker.bind(this)
    this.clearCallback = this.clearInput.bind(this)
    $(this.element).on(
      'apply.daterangepicker', this.applyCallback
    ).on(
      'cancel.daterangepicker', this.clearCallback
    )
  }

  disconnect () {
    // Disconnect the Handle Input Functions
    $(this.element).off(
      'apply.daterangepicker', this.applyCallback
    ).off(
      'cancel.daterangepicker', this.cancelCallback
    )
  }

  updateInputFromPicker () {
    this.updateInput(this.daterangepicker.startDate.format(this.formatValue))
  }

  // Applies the value to the input element
  updateInput (value) {
    $(this.element).val(value).trigger('change')
  }

  clearInput () {
    this.updateInput('')
  }
}
