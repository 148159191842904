import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['label', 'input']

  changeFile () {
    const filename = this.inputTarget.value.split('\\').pop()
    this.labelTarget.classList.add('selected')
    this.labelTarget.innerHTML = filename
  }
}
