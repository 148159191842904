import { Controller } from '@hotwired/stimulus'

// Creates a Select2 Select Input that allows for selection of Salesforce Accounts
export default class extends Controller {
  static values = {
    url: String,
    placeholder: { type: String, default: 'Search for Accounts...' }
  }

  connect () {
    $(this.element).select2({
      placeholder: this.placeholderValue,
      ajax: {
        url: this.urlValue,
        dataType: 'json',
        delay: 321,
        cache: true,
        minimumInputLength: 1,
        data: function (params) {
          const query = {
            name: params.term
          }
          return query
        },
        processResults: function (res) {
          const accounts = res.data
          const results = accounts.map((account) => {
            return {
              id: account.id,
              text: account.attributes.name
            }
          })

          return {
            results
          }
        }
      }
    })
  }

  disconnect () {
    $(this.element).select2('destroy')
  }
}
