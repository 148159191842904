/* global $ confirm */
import { Controller } from '@hotwired/stimulus'

import toastr from '../src/init/toastr'

/**
 * Removes a node from a list, and posts an AJAX request to delete the node from the database.
 */
export default class extends Controller {
  static classes = ['disabled']
  static targets = ['orderHandler', 'removeButton']
  static values = {
    url: String,
    confirmationMessage: String,
    successMessage: String
  }

  remove (event) {
    event.preventDefault()

    // Perform the remove after the current event loop has finished.
    setTimeout(this.promptRemove.bind(this), 0)
  }

  disable () {
    this.removeButtonTarget.classList.add(this.disabledClass)
    this.orderHandlerTarget.classList.add(this.disabledClass)
  }

  enable () {
    this.removeButtonTarget.classList.remove(this.disabledClass)
    this.orderHandlerTarget.classList.remove(this.disabledClass)
  }

  promptRemove () {
    // Confirm the user wants to delete the node.
    if (!confirm(this.confirmationMessageValue)) return

    if (this.hasUrlValue) {
      // remove the node from the database
      $.ajax({
        url: this.urlValue,
        type: 'DELETE',
        success: () => {
          toastr.clear()
          toastr.success(this.successMessageValue)
          this.element.remove()
        },
        error: function () {
          console.log('Error')
        }
      })
    } else {
      // remove the new node from the DOM
      this.element.remove()
    }
  }
}
