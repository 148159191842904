import { Controller } from '@hotwired/stimulus'

import toastr from '../src/init/toastr'

export default class extends Controller {
  static targets = ['stepDescription', 'stepInput', 'stepHeader', 'updatedLabel']
  static values = {
    failed: String,
    contactName: String,
    url: String
  }

  connect () {
    this.updateStepStatus()
    this.updateStepHeader()
  }

  // Update the step checkbox status based on the value
  updateStepStatus () {
    if (this.failedValue === 'true') this.stepInputTarget.indeterminate = true
  }

  show () {
    $(this.stepDescriptionTarget).collapse('show')
  }

  hide () {
    $(this.stepDescriptionTarget).collapse('hide')
  }

  toggle () {
    $(this.stepDescriptionTarget).collapse('toggle')
  }

  // Update the step header based on the value
  updateStepHeader () {
    const input = this.stepInputTarget
    const header = this.stepHeaderTarget

    if (input.checked) {
      this.hide()
      $(header).removeClass('step-failed').addClass('step-passed')
    } else if (input.indeterminate) {
      this.show()
      $(header).removeClass('step-passed').addClass('step-failed')
    } else {
      this.show()
      $(header).removeClass('step-failed step-passed')
    }
  }

  submitState () {
    const el = this.stepInputTarget
    const url = this.urlValue

    // readOnly state is not changed on click, so we use it to rotate the state:
    // 1. Check an checkbox works as usual (checked)
    // 2. Uncheck an checkbox 1st time will set it to indeterminate state (unchecked + indeterminate)
    // 3. Uncheck an checkbox 2nd time will set it to unchecked state (unchecked)
    if (el.readOnly) el.checked = el.readOnly = false
    else if (!el.checked) el.readOnly = el.indeterminate = true

    const status = el.checked ? 'passed' : (el.indeterminate ? 'failed' : 'incomplete')

    $.ajax({
      method: 'PUT',
      url,
      data: {
        salesforce_checklist_step: {
          status__c: status
        }
      }
    })
      .done((msg) => {
        const badges = msg.checklist_badges
        $('.status-badge-title').html(badges)
      })
      .fail((msg) => {
        console.log(msg)

        // revert to previous state
        if (el.indeterminate) {
          el.checked = true
          el.indeterminate = el.readOnly = false
        } else if (el.checked) el.checked = false
        else el.readOnly = el.indeterminate = true

        toastr.clear()
        toastr.error('Update failed. Please try again later.')
      })
      .always(() => {
        this.updateStepUpdatedLabel()
        this.updateStepHeader()
      })
  }

  updateStepUpdatedLabel () {
    this.updatedLabelTarget.innerHTML = 'Updated by ' + this.contactNameValue
  }
}
