import { Controller } from '@hotwired/stimulus'

import { onModalShown } from '../src/mixins/on_modal_shown'

// Creates a Select2 Select Input that allows for selection of Salesforce SKUs
export default class extends Controller {
  static values = {
    url: String,
    placeholder: { type: String, default: 'Search for SKUs...' }
  }

  connect () {
    onModalShown(this, this.connectSelect2.bind(this))
  }

  connectSelect2 () {
    $(this.element).select2({
      placeholder: this.placeholderValue,
      ajax: {
        url: this.urlValue,
        dataType: 'json',
        delay: 321,
        cache: true,
        minimumInputLength: 1,
        data: function (params) {
          const query = {
            name: params.term
          }
          return query
        },
        processResults: function (res) {
          const skus = res.data
          const results = skus.map((sku) => {
            return {
              id: sku.id,
              text: sku.attributes.supplier_name__c
            }
          })

          return {
            results
          }
        }
      },
      allowClear: true,
      closeOnSelect: true
    })
  }

  disconnect () {
    $(this.element).select2('destroy')
  }
}
