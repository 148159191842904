import { Controller } from '@hotwired/stimulus'

import toastr from '../src/init/toastr'

/**
 * Displays a simple toastr message to the user.
 */
export default class extends Controller {
  static values = {
    type: String
  }

  connect () {
    // Clear any existing toastr messages
    toastr.clear()

    // Get the text value from this.element as message
    const message = this.element.textContent.trim()

    // Display the toastr message
    switch (this.typeValue) {
      case 'notice':
      case 'success':
        toastr.success(message)
        break
      case 'info':
        toastr.info(message)
        break
      case 'warning':
        toastr.warning(message)
        break
      case 'alert':
      case 'error':
        toastr.error(message)
        break
      default:
        toastr.success(message)
    }

    // Remove the element from the DOM
    this.element.remove()
  }
}
