import { Controller } from '@hotwired/stimulus'

/**
 * Applies a class to the label of a checkbox or radio button when it is selected
 */
export default class extends Controller {
  static classes = ['selected']
  static targets = ['input', 'label']

  connect () {
    this.update()
  }

  update () {
    if (this.inputTarget.checked) {
      this.labelTarget.classList.add(this.selectedClass)
    } else {
      this.labelTarget.classList.remove(this.selectedClass)
    }
  }
}
