import { Controller } from '@hotwired/stimulus'

import moment from 'moment'

// Adds a Tooltip using the datetime attribute
// Tooltip is displayed in the user's current time zone
export default class extends Controller {
  static values = {
    // Tuesday 03 December 2023, 02:40 pm +10:00
    format: { type: String, default: 'dddd DD MMMM YYYY, h:mma Z' }
  }

  connect () {
    this.reload()
  }

  disconnect () {
    $(this.element).tooltip('dispose')
  }

  reload () {
    const timeInZone = moment(this.element.getAttribute('datetime'))
    const tooltipText = timeInZone.format(this.formatValue)
    $(this.element).tooltip('dispose').tooltip({ title: tooltipText })
  }
}
