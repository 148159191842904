import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    // NOTE: The connect method is unable to dispatch any events.
    // This is because the connect method is called before the element is added to the DOM.
    // This means we need to wait for the element to be added to the DOM before we can dispatch events.
    this.timeout = setTimeout(() => {
      this.dispatchTrigger()
      this.element.remove()
    }, 0)
  }

  disconnect () {
    clearTimeout(this.timeout)
  }

  dispatchTrigger () {
    this.dispatch('trigger', { bubbles: true })
  }
}
