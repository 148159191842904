import { Controller } from '@hotwired/stimulus'

import { digProperty } from '../src/mixins/dig'

// Displays an (optional) string message from a Live Drone or Live Remote
// Defaults to the default value if the message is nil or not provided
export default class extends Controller {
  static values = {
    field: String,
    default: String,
    truthy: String,
    falsey: String
  }

  connect () {}

  disconnect () {}

  update ({ detail }) {
    const value = digProperty(this.fieldValue, detail)
    this.updateFromValue(value)
  }

  updateFromValue (value) {
    if (value !== null) {
      this.element.innerHTML = value ? this.truthyValue : this.falseyValue
    } else {
      this.element.innerHTML = this.defaultValue
    }
  }
}
