import { Controller } from '@hotwired/stimulus'

import { withStatusPolling } from '../../src/mixins/with_status_polling'

/**
 * Retrieves the Dock Status from the Drone Status Service
 * and broadcasts it to the page (via a statusUpdate event)
 */
export default class extends Controller {
  static targets = ['listener']
  static values = {
    refreshRate: {
      type: Number,
      default: 1_000
    },
    url: String
  }

  connect () {
    withStatusPolling(this, this.urlValue, this.refreshRateValue, this.dispatchStatusUpdate.bind(this))
  }

  // dockStatus is an object like this:

  // {
  //   "serial_number" => dock.serial_number,
  //   "current_task" => {
  //     "message" => dock.current_task_message,
  //     "progress_message" => dock.current_task_progress_message,
  //     "progress_percentage" => dock.current_task_progress_percentage
  //   },
  //   "current_task_timestamp" => dock.current_task_timestamp,
  //   "position" => nil,
  //   "device_state" => {
  //     "alarm_state" => nil,
  //     "cover_state" => nil,
  //     "emergency_stop_state" => nil,
  //     "putter_state" => nil,
  //     "supplement_light_state" => nil
  //   },
  //   "hms_notices" => [],
  //   "media_uploads" => nil,
  //   "weather" => {
  //     "humidity" => nil,
  //     "rainfall" => nil,
  //     "internal_temperature" => nil,
  //     "external_temperature" => nil,
  //     "wind_speed" => nil
  //   },
  //   "storage" => nil,
  //   "sub_device" => {
  //     "drone_in_dock" => nil,
  //     "drone_charge_state" => nil
  //   },
  //   "_links" => {
  //     "self" => {
  //       "href" => api_v1_dock_path(dock)
  //     }
  //   },
  //   "_meta" => {
  //     "last_timestamp" => dock.last_timestamp,
  //     "live_status" => true
  //   }
  // }
  dispatchStatusUpdate (res) {
    const dockStatus = res.dock || {} // unknown docks return null, do not allow null to propagate
    const dockMeta = dockStatus._meta || { live_status: false } // unknown docks are not live

    // Broadcast a liveUpdate event to the page
    const liveDockStatus = dockMeta.live_status ? dockStatus : {}

    this.dispatch('liveUpdate', { detail: { dock_status: liveDockStatus, meta: dockMeta } })
  }
}
