import { FlightAdvisory } from '../flight-advisory/flight-advisory'

import {
  addGeolocationButton,
  addInfoButton,
  addSearchBox,
  serializeGooglePolygon
} from '../init/curo-map-utility'

import {
  buildEditablePolygonFromDataPoints
} from './polygon-utils'

import DrawingTool from './drawing-tool'
import { buildMapOfAustralia, fitViewportToBounds, resizeControlToWidth } from './map-utils'

//  ========== Google Map Polygon ==========
// This handles drawing of polygon and circle on Google Map.
// However, when user draw a circle, we convert it to polygon and only save it as polygon.
export class PolygonPicker {
  // Private variables
  #map

  constructor ($mapEl) {
    this.selectedShape = null // The google map object user draw
    this.flightAdvisory = null

    // Setup Map View
    const mapContainer = $mapEl.find('.curo-mappicker__mapview')[0]

    this.#map = buildMapOfAustralia(mapContainer)

    // Remove Satellite/Terrain Control buttons
    this.#map.setOptions({ mapTypeControl: false })

    this.#setupResponsive(mapContainer)

    // Setup Polygon Field
    this.$polygonFormField = $mapEl.find("[data-curo-mappicker-target='polygon']")
  }

  // Simple Flight Advisory that requires additional properties for advisories lookup
  setupFlightAdvisory ($mapEl, { mapboxAccessToken, advisoryHost, operationType, advisoriesLookup }) {
    this.flightAdvisory = new FlightAdvisory($mapEl, {
      map: this.#map,
      mapboxAccessToken,
      advisoryHost,
      operationType,
      advisoriesLookup,
      isStandalone: false
    })
    this.flightAdvisory.showPanel() // show flight advisory by default

    // Map controls
    addGeolocationButton(this.#map)
    addSearchBox(this.#map, this.#displaySearchResults.bind(this))
  }

  // Complex Flight Advisory that provides its own properties for advisories lookup
  setupStandaloneFlightAdvisory ($mapEl, { mapboxAccessToken, advisoryHost, operationType, advisoriesLookup }) {
    this.flightAdvisory = new FlightAdvisory($mapEl, {
      map: this.#map,
      mapboxAccessToken,
      advisoryHost,
      operationType,
      advisoriesLookup,
      isStandalone: true
    })
    this.flightAdvisory.showPanel() // show flight advisory by default

    // Map controls
    addGeolocationButton(this.#map)
    addSearchBox(this.#map, this.#displaySearchResults.bind(this))
    addInfoButton(this.#map)
  }

  setupBasicDrawingManager () {
    this.drawingTool = new DrawingTool({
      map: this.#map,
      title: 'Operation Area',
      enableMarker: false,
      onMarkerSelected: this.#markerSelected.bind(this),
      onPolygonSelected: this.#polygonSelected.bind(this),
      onClearShapes: this.#clearSelection.bind(this)
    })
  }

  setupComplexDrawingManager () {
    this.drawingTool = new DrawingTool({
      map: this.#map,
      title: 'Advisory Area',
      enableMarker: true,
      onMarkerSelected: this.#markerSelected.bind(this),
      onPolygonSelected: this.#polygonSelected.bind(this),
      onClearShapes: this.#clearSelection.bind(this)
    })
  }

  init () {
    // Show existing polygon if set
    const polygon = buildEditablePolygonFromDataPoints(this.$polygonFormField.data('polygon'))
    if (polygon) {
      polygon.setMap(this.#map)
      this.drawingTool.placePolygon(polygon)
    }
  }

  zoomToFitBounds () {
    fitViewportToBounds(this.#map, this.drawingTool.getBounds())
  }

  /*****************************************
   * Flight Advisory Methods
   *****************************************/

  checkFlightAdvisoryForMarker (googleMarker) {
    if (this.flightAdvisory) {
      this.flightAdvisory.checkGoogleMarker(googleMarker)
    }
  }

  checkFlightAdvisoryForPolygon (googlePolygon) {
    if (this.flightAdvisory) {
      this.flightAdvisory.checkGooglePolygon(googlePolygon)
    }
  }

  reloadFlightAdvisories () {
    if (this.flightAdvisory) {
      this.flightAdvisory.reload()
    }
  }

  setOperationType (operationType) {
    if (this.flightAdvisory) {
      this.flightAdvisory.setOperationType(operationType)
    }
  }

  /*****************************************
   * Search Callbacks
   *****************************************/

  #displaySearchResults (places) {
    this.drawingTool.placeSearchResults(places)
    this.zoomToFitBounds()
  }

  /*****************************************
   * Drawing Tool Callbacks
   *****************************************/

  #markerSelected (googleMarker) {
    this.checkFlightAdvisoryForMarker(googleMarker)
  }

  #polygonSelected (googlePolygon) {
    this.checkFlightAdvisoryForPolygon(googlePolygon)

    // NOTE: Will not update the form field for Markers
    this.$polygonFormField.val(serializeGooglePolygon(googlePolygon))
  }

  #clearSelection () {
    this.flightAdvisory.clearFlightArea()

    this.$polygonFormField.val('')
  }

  #setupResponsive (mapContainer) {
    new ResizeObserver((entries) => {
      const width = entries[0].contentRect.width
      resizeControlToWidth(this.#map, width)
      this.drawingTool.resizeToWidth(width)
    }).observe(mapContainer)
  }
}
