import { Controller } from '@hotwired/stimulus'

/**
 * Clicks on each click target when click is called
 *
 * @see https://thoughtbot.com/blog/dynamic-forms-with-turbo
 */
export default class extends Controller {
  static targets = ['click']

  click () {
    this.clickTargets.forEach(target => target.click())
  }
}
