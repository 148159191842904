import { Controller } from '@hotwired/stimulus'
import consumer from '../channels/consumer'

/**
 * Provides the notifications menu item on the top bar of Curo.
 *
 * Uses action cable to update the unread icon status.
 *
 * TODO: Dynamically load notifications, instead of relying on external jQuery
 */
export default class extends Controller {
  static targets = ['unreadIcon', 'frame']
  static values = {
    source: String
  }

  connect () {
    // Connect to the Notifications Channel
    this.channel = consumer.subscriptions.create('NotificationsChannel', {
      connected: this.cableConnected.bind(this),
      received: this.cableReceived.bind(this)
    })
  }

  fetchNotifications () {
    // Load the tubro frame
    this.frameTarget.src = this.sourceValue

    // Reload the slimscroll menu
    this.dispatch('fetched', { detail: { source: this.sourceValue } })
  }

  cableConnected () {
    console.log('Connected notification channel.')
  }

  cableReceived (data) {
    $(this.unreadIconTarget).toggleClass('invisible', data.new_message_size < 1)
  }
}
