import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = ['default']
  static values = {
    submitClasses: Object,
    submitPrompts: Object,
    commentsRequired: Array,
    optionalPlaceholder: String,
    requiredPlaceholder: String
  }

  static targets = ['resultOption', 'optionalComments', 'submitButton']

  connect () {
    this.updateSelection()
  }

  // Updates the submit button and optional input based on the selected result option
  updateSelection () {
    const selectedOption = this.resultOptionTargets.find(option => option.checked)
    const selectedValue = selectedOption ? selectedOption.value : null

    // Update submit button css class
    const submitButton = this.submitButtonTarget
    Object.values(this.submitClassesValue).forEach(cssClass => {
      submitButton.classList.remove(cssClass)
    })
    submitButton.classList.remove(this.defaultClass)
    submitButton.classList.add(this.submitClassesValue[selectedValue] || this.defaultClass)
    submitButton.toggleAttribute('disabled', !selectedValue)

    // Update the confirmation prompt on submit
    const submitPrompt = this.submitPromptsValue[selectedValue]
    if (submitPrompt) {
      this.element.setAttribute('data-turbo-confirm', submitPrompt || '')
    } else {
      this.element.removeAttribute('data-turbo-confirm')
    }

    // Update the optional comments field
    const optionalCommentsField = this.optionalCommentsTarget
    const optionalCommentsRequired = this.commentsRequiredValue.includes(selectedValue)
    optionalCommentsField.setAttribute('placeholder', optionalCommentsRequired ? this.requiredPlaceholderValue : this.optionalPlaceholderValue)
    optionalCommentsField.toggleAttribute('required', optionalCommentsRequired)
  }
}
