import { buildInfoWindowWithCoordinates } from './info-window'
import { buildHighlightedMarker, buildDefaultMarker, removeMarkerHighlight, hightlightMarker } from './marker-utils'

export default class {
  // Private variables
  #map
  #markers
  #infoWindows
  #selectedIndex

  constructor ({ map, onMarkerSelected, onMarkersAdded, onMarkersRemoved }) {
    this.#map = map

    this.onMarkerSelected = onMarkerSelected
    this.onMarkersAdded = onMarkersAdded
    this.onMarkersRemoved = onMarkersRemoved

    this.#markers = [] // A list of all the markers on the map
    this.#infoWindows = [] // A list of all info windows visible

    this.#selectedIndex = -1
  }

  get hasMarkers () {
    return this.#markers.length > 0
  }

  get hasMultipleMarkers () {
    return this.#markers.length > 1
  }

  getBounds () {
    const bounds = new google.maps.LatLngBounds()
    this.#markers.forEach((marker) => {
      bounds.extend(marker.position)
    })
    return bounds
  }

  selectMarker (marker) {
    this.selectMarkerAtIndex(this.#markers.indexOf(marker))
  }

  selectMarkerAtIndex (index) {
    const marker = this.#markers.length > index && this.#markers[index]
    if (marker) {
      const infoWindow = index >= 0 ? this.#infoWindows[index] : null

      this.deselectMarkers()

      if (infoWindow) {
        infoWindow.open({
          anchor: marker,
          map: this.#map,
          shouldFocus: false
        })
      }

      this.#selectedIndex = index

      // Highlight the selected marker
      hightlightMarker(marker)

      // Broadcast to the world the changes
      this.onMarkerSelected(marker)
    }
  }

  // Displays a Marker Placed on a Map
  addPlacedMarker (marker) {
    // Add the Placed Marker
    this.#addMarker(marker)
    this.onMarkersAdded(this.#markers)
  }

  // Display Places provided from Search Results
  addSearchResults (places) {
    // Build All Markers
    places.forEach((place) => {
      const marker = buildDefaultMarker({
        map: this.#map,
        title: place.formatted_address,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng()
      })
      this.#addMarker(marker)
    })

    // Broadcast the change
    this.onMarkersAdded(this.#markers)
  }

  deselectMarkers () {
    this.#infoWindows.forEach((infoWindow) => { infoWindow.close() })
    this.#markers.forEach((marker) => { removeMarkerHighlight(marker) })
    this.#selectedIndex = -1
  }

  // Remove Idle Markes, no love for them
  removeAllMarkers () {
    if (this.#markers.length > 0) {
      this.deselectMarkers()
      this.#infoWindows = []

      this.#markers.forEach((marker) => { marker.setMap(null) })
      this.#markers = []

      this.#selectedIndex = -1

      this.onMarkersRemoved()
    }
  }

  removeIdleMarkers () {
    if (this.#selectedIndex === -1) {
      this.removeAllMarkers()
    } else {
      // It's easier to rebuild that in it is to replace markers
      const selectedMarker = this.#markers[this.#selectedIndex]

      const marker = buildHighlightedMarker({
        title: selectedMarker.title,
        latitude: selectedMarker.position.latitude,
        longitude: selectedMarker.position.longitude
      })

      // Remove All Markers
      this.removeAllMarkers()

      // Add the duplicated marker in, and select it (it was selected before!)
      this.addPlacedMarker(marker)
      this.selectMarkerAtIndex(0)
    }
  }

  #addMarker (marker) {
    this.#markers.push(marker)
    const infoWindow = this.#buildInfoWindowForMarker(marker)
    this.#infoWindows.push(infoWindow)

    marker.addListener('click', () => { this.selectMarker(marker) })
  }

  #buildInfoWindowForMarker (marker) {
    const lat = typeof marker.position.lat === 'function' ? marker.position.lat() : marker.position.lat
    const lng = typeof marker.position.lng === 'function' ? marker.position.lng() : marker.position.lng
    const infoWindow = buildInfoWindowWithCoordinates({
      title: marker.title ? marker.title : 'Placed Marker',
      latitude: lat,
      longitude: lng
    })

    return infoWindow
  }
}
