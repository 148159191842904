import { Controller } from '@hotwired/stimulus'

/**
 * Handles Modals loaded via Turbo Frames
 *
 # Modal can be hooked by via actions to:
 # - open when a turbo-frame within it is loaded, and
 # - close when the form submits successfully.
 */
export default class extends Controller {
  connect () {
    // this.modal = new bootstrap.Modal(this.element)
  }

  open () {
    $(this.element).modal()
  }

  close (event) {
    if (event.detail.success) {
      $(this.element).modal('hide')
    }
  }

  hide () {
    $(this.element).modal('hide')
  }
}
