import { GeoJsonLayer } from '@deck.gl/layers'
import { getLineColor, getFillColor } from './colors'

export const buildGeoJSONLayer = (url, { id }) => {
  return new GeoJsonLayer({
    id,
    data: url,
    getLineWidth: 2,
    lineWidthMinPixels: 1,
    pickable: true,
    getLineColor: (object) => getLineColor(object),
    getFillColor: (object) => getFillColor(object),
    onClick: ({ object }) => console.log(object)
  })
}
