import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.originalElevation = this.element.innerHTML
  }

  disconnect () {
  }

  update ({ detail: { elevation } }) {
    if (elevation) {
      const displayElevation = parseFloat(elevation).toFixed(1)
      this.element.innerHTML = `${displayElevation} m`
    } else {
      this.element.innerHTML = this.originalElevation
    }
  }
}
