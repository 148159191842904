/* global $ */
import { Controller } from '@hotwired/stimulus'

import '../src/init/datatables'

import { handleAjaxError } from '../src/init/ajax'

export default class extends Controller {
  static targets = ['datatable']
  static values = {
    source: String,
    columns: Array,
    order: Array
  }

  connect () {
    this.datatable = $(this.datatableTarget).DataTable({
      serverSide: true,
      ajax: {
        url: this.sourceValue,
        type: 'POST',
        error: handleAjaxError
      },
      columns: this.columnsValue,
      pageLength: 10, // add a data-page-length attribute to override
      ordering: $(this.datatableTarget).is('[data-order]'),
      drawCallback: function (res) {}
    })
  }

  disconnect () {
    this.datatable.destroy()
  }
}
