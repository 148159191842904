const getDegreeEntityPosition = (viewer, entity) => {
  let position
  const point = {}
  if (entity.position) {
    position = entity.position.getValue(viewer.clock.currentTime)
    if (position) {
      position = Cesium.Cartographic.fromCartesian(position)
      point.lon = Cesium.Math.toDegrees(position.longitude).toFixed(5)
      point.lat = Cesium.Math.toDegrees(position.latitude).toFixed(5)
      point.height = position.height
    }
  }
  return point
}

const getCartographicEntityPosition = (viewer, entity) => {
  let position
  if (entity.position) {
    position = entity.position.getValue(viewer.clock.currentTime)
    if (position) {
      position = Cesium.Cartographic.fromCartesian(position)
    }
  }
  return position
}

const getCartesian3EntityPosition = (viewer, entity) => {
  let position
  if (entity.position) {
    position = entity.position.getValue(viewer.clock.currentTime)
  }
  return position
}

const getGeodesicDistance = (p1, p2) => {
  const geodesic = new Cesium.EllipsoidGeodesic()
  geodesic.setEndPoints(p1, p2)

  const geodesicDistance = geodesic.surfaceDistance
  const heightDifference = p2.height - p1.height
  return Math.sqrt(
    geodesicDistance * geodesicDistance + heightDifference * heightDifference
  )
}

const getCartesianDistance = (p1, p2) => {
  return Cesium.Cartesian3.distance(p1, p2)
}

const getSeparationAltitude = (p1, p2) => {
  const result = p1.height - p2.height
  return Math.abs(result)
}

export {
  getGeodesicDistance,
  getCartesianDistance,
  getSeparationAltitude,
  getDegreeEntityPosition,
  getCartographicEntityPosition,
  getCartesian3EntityPosition
}
