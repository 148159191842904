import { Controller } from '@hotwired/stimulus'

import { initDisplayMap } from '../src/init/curo-displaymap'
import { FlightAdvisoryService } from '../src/flight-advisory/flight-advisory-service'

export default class extends Controller {
  static values = {
    advisoryHost: String,
    mapboxAccessToken: String,
    operationType: String,
    operationDate: String,
    startTime: String,
    endTime: String,
    maxAltitude: String,
    paths: Array,
    points: Array,
    polygons: Array
  }

  connect () {
    const advisoryMap = initDisplayMap(this.element, {
      paths: this.pathsValue || [],
      points: this.pointsValue || [],
      polygons: this.polygonsValue || []
    })

    const advisoryHost = this.advisoryHostValue
    const flightAdvisoryService = new FlightAdvisoryService(advisoryHost)
    advisoryMap.addFlightAdvisory({
      advisoryHost,
      mapboxAccessToken: this.mapboxAccessTokenValue,
      operationType: this.operationTypeValue,
      advisoriesLookup: ({ point, areas, operationType }) => {
        return flightAdvisoryService.checkAdvisory({
          point,
          areas,
          operationType,
          operationDate: this.operationDateValue,
          startTime: this.startTimeValue,
          endTime: this.endTimeValue,
          maxAltitude: this.maxAltitudeValue
        })
      }
    })
  }
}
