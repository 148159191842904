import { Controller } from '@hotwired/stimulus'

import { withStatusPolling } from '../../src/mixins/with_status_polling'

/**
 * Retrieves the Dock Status from the Drone Status Service
 * and broadcasts it to the page (via a statusUpdate event)
 */
export default class extends Controller {
  static values = {
    refreshRate: {
      type: Number,
      default: 1_000
    },
    url: String
  }

  connect () {
    withStatusPolling(this, this.urlValue, this.refreshRateValue, this.dispatchStatusUpdate.bind(this))
  }

  dispatchStatusUpdate (res) {
    const droneStatus = res.data || {} // unknown drones return null, do not allow null to propagate
    const droneMeta = res.meta || { live_status: false } // unknown drones should not be live

    // Testing locally:
    // const droneStatus = {
    //   position: {
    //     latitude: -33.9129314 + (new Date().getSeconds()) / 500,
    //     longitude: 151.1942447 + Math.random() / 500
    //   },
    //   elevation: 100 + (new Date().getSeconds()) * 100,
    //   last_timestamp: Date.now()
    // }

    // Broadcast a liveUpdate event to the page
    const liveDroneStatus = droneMeta.live_status ? droneStatus : {}
    this.dispatch('liveUpdate', { detail: { drone_status: liveDroneStatus, meta: droneMeta } })
  }
}
