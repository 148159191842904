// === Google Map related functions ===
export const polygonOpt = {
  fillColor: '#00dafb',
  fillOpacity: 0.33,
  strokeColor: '#00dafb',
  strokeWeight: 3,
  zIndex: 1
}

export const polygonEditOpt = {
  ...polygonOpt,
  editable: true,
  draggable: false
}

// Deserialize database polygon data into an Google Polygon
export const buildPolygonFromDataPoints = (dataPoints) => {
  return new google.maps.Polygon({
    ...polygonOpt,
    paths: buildPathFromDataPoints(dataPoints)
  })
}

// Deserialize database polygon data into an editable Google Polygon
export const buildEditablePolygonFromDataPoints = (dataPoints) => {
  if (dataPoints && dataPoints.length > 2) {
    return new google.maps.Polygon({
      ...polygonEditOpt,
      paths: buildPathFromDataPoints(dataPoints)
    })
  }
}

const buildPathFromDataPoints = (dataPoints) => {
  const polygon = []
  for (const index in dataPoints) {
    const point = dataPoints[index]
    polygon.push(new google.maps.LatLng(point.lat, point.lng))
  }
  return polygon
}

export const extendGetBoundsForPolygon = () => {
  google.maps.Polygon.prototype.getBounds = function () {
    const bounds = new google.maps.LatLngBounds()
    const paths = this.getPaths()
    let path
    for (let i = 0; i < paths.getLength(); i++) {
      path = paths.getAt(i)
      for (let ii = 0; ii < path.getLength(); ii++) {
        bounds.extend(path.getAt(ii))
      }
    }
    return bounds
  }
}

const getHighestWindowPosition = (coors) => {
  let lat = -5000; let lng = 0; let i = 0; const n = coors.length

  for (; i !== n; ++i) {
    if (coors[i].lat() > lat) {
      lat = coors[i].lat()
      lng = coors[i].lng()
    }
  }
  return { lat, lng }
}

export const getHighestWindowPositionForPolygon = (polygon) => {
  return getHighestWindowPosition(polygon.getPath().getArray())
}
