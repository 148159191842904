/* global $ */
import { Controller } from '@hotwired/stimulus'

import { useWindowResize } from 'stimulus-use'

/*
Template Name: Shreyu - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 1.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Js File
*/
export default class extends Controller {
  static targets = ['sidebar']

  connect () {
    // show/hide elements based on mobile/tablet/desktop view
    useWindowResize(this)

    // if the layout is scrollable - let's remove the slimscroll class from menu
    if (this.$body.hasClass('scrollable-layout')) {
      $(this.sidebarTarget).removeClass('slimscroll-menu').removeAttr('data-controller')
    }

    // Left menu show/hide button
    this.toggleMenuBinding = this.toggleMenu.bind(this)
    $('.button-menu-mobile').on('click', this.toggleMenuBinding)

    // click to hide mobile menu
    this.hideMobileMenuBinding = this.hideMobileMenu.bind(this)
    $(document).on('click', 'body', this.hideMobileMenuBinding)
  }

  disconnect () {
    $('.button-menu-mobile').off('click', this.toggleMenuBinding)
  }

  windowResize ({ width }) {
    // in case of small size, add class enlarge to have minimal menu
    if (width >= 768 && width <= 1024) {
      this.$body.addClass('left-side-menu-condensed')
    } else {
      if (this.$body.data('left-keep-condensed') !== true) {
        this.$body.removeClass('left-side-menu-condensed')
      }
    }
  }

  toggleMenu (event) {
    event.preventDefault()

    this.$body.toggleClass('sidebar-enable')
    if (this.$window.width() >= 768) {
      this.$body.toggleClass('left-side-menu-condensed')
    } else {
      this.$body.removeClass('left-side-menu-condensed')
    }

    // sidebar - scroll container
    this.resetSidebarScroll()
  }

  hideMobileMenu (event) {
    if (
      $(event.target).closest('.left-side-menu, .side-nav').length > 0 ||
            $(event.target).hasClass('button-menu-mobile') ||
            $(event.target).closest('.button-menu-mobile').length > 0
    ) {
      // clicked on something that already affects the menu
      return
    }

    // Hides the menu for mobile users
    this.$body.removeClass('sidebar-enable')
  }

  resetSidebarScroll () {
    // reload the slimscroll menu for the sidebar
    this.application.getControllerForElementAndIdentifier(this.sidebarTarget, 'slimscroll').reload()
  }

  get $body () {
    return $('body')
  }

  get $window () {
    return $(window)
  }
}
