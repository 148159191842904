export const FlightAdvisoryResponse = class {
  constructor (jsonRes) {
    this.noFly = {
      count: 0,
      ruleSets: []
    }
    this.caution = {
      count: 0,
      ruleSets: []
    }
    this.warnings = {
      count: 0,
      ruleSets: []
    }
    this.information = {
      count: 0,
      ruleSets: []
    }

    this.meta = jsonRes.meta

    this.parseResponse(jsonRes)
  }

  parseResponse (jsonRes) {
    this.parseData(jsonRes.data.attributes.block_advisories, this.noFly)

    this.parseData(jsonRes.data.attributes.caution_advisories, this.caution)

    this.parseData(jsonRes.data.attributes.warnings, this.warnings)

    this.parseData(jsonRes.data.attributes.information, this.information)
  }

  parseData (apiData, localData) {
    apiData.forEach((dataset, _index) => {
      const ruleSet = new FlightAdvisoryRuleset(dataset.title, dataset.description, dataset.items, dataset.items_total)

      localData.ruleSets.push(ruleSet)
    })
  }
}

const FlightAdvisoryRuleset = class {
  constructor (title, description, items, itemsTotal) {
    this.title = title
    this.description = description
    this.items = items
    this.itemsTotal = itemsTotal
  }
}
