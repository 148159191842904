/**
 * From: https://medium.com/javascript-inside/safely-accessing-deeply-nested-values-in-javascript-99bf72a0855a
 *
 * Usage:
 * ```
 * const props = {
 *   user: {
 *     posts: [
 *       { title: 'Foo', comments: [ 'Good one!', 'Interesting...' ] },
 *       { title: 'Bar', comments: [ 'Ok' ] },
 *       { title: 'Baz', comments: [] },
 *     ]
 *   }
 * }
 * console.log(dig(['user', 'posts', 0, 'comments'], props))
 * => [ 'Good one!', 'Interesting...' ]
 * ```
 */
export const dig = (p, o) =>
  p.reduce((xs, x) =>
    (xs && typeof (xs[x]) !== 'undefined' && xs[x] != null) ? xs[x] : null, o)

/**
 * const props = {
 *   foo: {
 *     bar: "baz"
 *   }
 * }
 * console.log(digProperty(['foo.bar'], props)) // => "baz"
 *
 * @param {String} field
 * @param {Object} o
 * @returns {String}
 */
export const digProperty = (field, o) => dig(field.split('.'), o)
