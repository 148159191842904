import { Controller } from '@hotwired/stimulus'

/**
 * Broadcasts the Drone Status to any listeners
 *
 * Configure the controller to receive any drone:statusUpdate events
 */
export default class extends Controller {
  static targets = ['listener']

  broadcastStatus ({ detail }) {
    // Broadcast the Drone Status to any listeners
    this.listenerTargets.forEach(listener => {
      this.dispatch('statusUpdate', { target: listener, detail })
    })
  }
}
