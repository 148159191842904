/* global Cesium */

/**
 * Adds camera orbiting functionality to a controller using Cesium library.
 *
 * @param {Object} controller - The controller object.
 * @param {Object} options - The options object.
 * @param {Object} options.cesiumViewer - The Cesium viewer object.
 * @returns {Object} - An object with a setTarget method to set the target latitude and longitude.
 */
export const withCameraOrbiter = (controller, { cesiumViewer }) => {
  const SMOOTHNESS = 7200 // it would make one full circle in roughly 7200 frames, or 7200/60 = 120 seconds on a 60Hz screen.
  const range = 1000 // 1000 meters

  let enabled = true
  let targetLatitude = null
  let targetLongitude = null

  // We should orbit the camera if:
  // - the user has not interacted with the map
  // - there is a valid position for targetLongitude and targetLongitude
  function shouldOrbit () {
    return enabled && targetLatitude && targetLongitude && mapInAllowedMode()
  }

  // Updates the camera position when the clock ticks
  const updateOrbitTickListener = () => {
    if (shouldOrbit()) {
      const centre = Cesium.Cartesian3.fromDegrees(targetLongitude, targetLatitude)
      const heading = cesiumViewer.camera.heading + -1 * Math.PI / SMOOTHNESS // -1 for clockwise, +1 for counter-clockwise

      let pitch = cesiumViewer.camera.pitch

      // Move the pitch back to 30 degrees
      if (pitch < Cesium.Math.toRadians(-30)) {
        pitch += Math.PI / (SMOOTHNESS / 2)
      } else if (pitch > Cesium.Math.toRadians(-30)) {
        pitch -= Math.PI / (SMOOTHNESS / 2)
      }

      cesiumViewer.camera.lookAt(centre, new Cesium.HeadingPitchRange(heading, pitch, range))
    }
  }

  // Listen for user interaction
  cesiumViewer.screenSpaceEventHandler.setInputAction(() => {
    enabled = false
  }, Cesium.ScreenSpaceEventType.LEFT_DOWN)

  // Register the orbit tick listener
  cesiumViewer.clock.onTick.addEventListener(updateOrbitTickListener)

  // Override the disconnect method to remove the orbit tick listener
  const controllerDisconnect = controller.disconnect.bind(controller)
  Object.assign(controller, {
    disconnect () {
      cesiumViewer.clock.onTick.removeEventListener(updateOrbitTickListener)
      controllerDisconnect()
    }
  })

  // COLUMBUS_VIEW is 3D also by tilting 2D map so you can also orbiting
  // Orbiting in other mode will cause error and stop rendering
  const mapInAllowedMode = () => {
    const allowedModes = [Cesium.SceneMode.SCENE3D, Cesium.SceneMode.COLUMBUS_VIEW]

    return allowedModes.includes(cesiumViewer.scene.mode)
  }

  return {
    enable: () => {
      enabled = true
    },
    disable: () => {
      enabled = false
    },
    setTarget: ({ latitude, longitude }) => {
      targetLatitude = latitude
      targetLongitude = longitude
    }
  }
}
