import moment from 'moment'

const SEPARATION_CYLINDER_CONFIGS =
  [
    {
      id: 'emergency-separation',
      name: 'Emergency Separation',
      height: 152.4,
      radius: 1500,
      color: [236, 112, 99, 40]
    },
    {
      id: 'alarm-separation',
      name: 'Alarm Separation',
      height: 304.8,
      radius: 5556,
      color: [235, 152, 78, 35]
    },
    {
      id: 'warning-separation',
      name: 'Warning Separation',
      height: 304.8,
      radius: 9260,
      color: [240, 230, 140, 30]
    },
    {
      id: 'notice-separation',
      name: 'Notice Separation',
      height: 914.4,
      radius: 18520,
      color: [135, 206, 250, 25]
    }
  ]

export const SeparationCylinder = class {
  constructor (controller, { cesiumViewer, toolbar }) {
    this.cesiumViewer = cesiumViewer
    this.cylinderConfigs = SEPARATION_CYLINDER_CONFIGS

    // Entities
    this.sepEntity = null
    this.cylinderEntities = []

    // UI related
    this.showCylinders = false
    this.toolbar = toolbar
    this.table = null
    this.parentCheckBox = null

    this.prepareCylinders()
    this.setupLayerToggle()

    const controllerDisconnect = controller.disconnect.bind(controller)
    const removeListener = this.removeListener.bind(this)

    Object.assign(controller, {
      disconnect () {
        removeListener()
        controllerDisconnect()
      }
    })
  }

  // Add the cylinder entities to the cesium viewer without showing them as we have no position yet
  // TODO: make cylinders not pickable.
  prepareCylinders () {
    this.cylinderConfigs.forEach(cylinderItem => {
      const name = cylinderItem.name
      const height = cylinderItem.height
      const radius = cylinderItem.radius
      const color = cylinderItem.color
      const id = cylinderItem.id

      const entity = this.cesiumViewer.entities.add({
        id,
        name,
        show: false,
        pickable: false,
        cylinder: {
          length: height,
          topRadius: radius,
          bottomRadius: radius,
          material: Cesium.Color.fromBytes(...color),
          outline: false
        }
      })

      this.cylinderEntities.push(entity)
    })
  }

  updateCylinderPositions () {
    this.cylinderEntities.forEach(cylinder => {
      if (!this.sepEntity) {
        cylinder.show = false
      } else {
        cylinder.position = this.sepEntity.position.getValue(this.cesiumViewer.clock.currentTime)
        cylinder.show = this.showCylinders
      }
    })
  }

  setSepEntity (entity) {
    this.removeListener()
    this.sepEntity = entity

    if (this.sepEntity) {
      this.cylinderListenerRemover = this.sepEntity.definitionChanged.addEventListener(() => {
        this.updateCylinderPositions()
      })

      this.updateCylinderPositions()
    }

    // TODO: Move the table view in a new toolbar place
    // this.createTableView()
  }

  removeListener () {
    if (this.cylinderListenerRemover) {
      this.cylinderListenerRemover()
    }
  }

  setupLayerToggle () {
    const buttonWrapper = $('<div/>', { class: 'cesium-button sep-cylinder-toggle-button' })
    const sepCylinderToggleLabel = $('<label/>', { for: 'sep-cylinder-layer-toggle', class: 'mb-0 d-flex', text: 'Separation Bubble' })
    const sepCylinderToggleInput = $('<input/>', { type: 'checkbox', id: 'sep-cylinder-layer-toggle', class: 'mr-2', checked: false })

    sepCylinderToggleLabel.prepend(sepCylinderToggleInput)
    buttonWrapper.append(sepCylinderToggleLabel)
    this.toolbar.append(buttonWrapper)

    // TODO: teardown the event listener when the controller is disconnected
    sepCylinderToggleInput.on('change', (e) => {
      if (e.target.checked) {
        this.showSepCylinders()
      } else {
        this.hideSepCylinders()
      }
    })
  }

  showSepCylinders () {
    this.cylinderEntities.forEach(cylinder => {
      cylinder.show = true
      this.showCylinders = true
    })
  }

  hideSepCylinders () {
    this.cylinderEntities.forEach(cylinder => {
      cylinder.show = false
      this.showCylinders = false
    })
  }

  // UI related
  createTableView () {
    const dataTableHtml =
        `<div class='cylinder-table-container' id='separation-cylinder-table-container'>
        <table class='table table-hover table-sm table-dark '>
          <thead>
            <tr class='row-label-caption'>
              <th scope="col">
                <input type='checkbox' id='check-all-cylinder-layer' checked/>
              </th>
              <th scope="col">Name</th>
              <th scope="col">Height (m)</th>
              <th scope="col">Radius (m)</th>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
      </div>`

    const tableContainer = $(dataTableHtml)
    const existingTable = this.toolbar.find('#separation-cylinder-table-container')
    if (existingTable.length) {
      existingTable.remove()
    }
    this.toolbar.append(tableContainer)
    this.table = tableContainer.find('table')
    const tableBody = this.table.find('tbody')
    this.parentCheckBox = this.table.find('#check-all-cylinder-layer')

    this.parentCheckBox.on('change', (e) => {
      this.updateChildStatus(e.target.checked)
    })

    this.cylinderConfigs.forEach((item) => {
      const rowItem = this.createTableRow(item)
      tableBody.append(rowItem)
    })
  }

  createTableRow (item) {
    const rowItemHtml =
        `<tr id='row-${item.name}' class='row-label-text'>
          <td>
            <div class='form-check'>
              <input type='checkbox' id='check-${item.name}' class='form-check-input' checked/>
            </div>
          </td>
          <td>
            ${item.name}
          </td>
          <td>
            ${item.height.toFixed(1)}
          </td>
          <td>
            ${item.radius.toFixed(1)}
          </td>
        </tr>`

    const rowItem = $(rowItemHtml)
    rowItem.on('click', (e) => this.tableRowOnClick(e, item))

    return rowItem
  }

  tableRowOnClick (e, item) {
    const entities = this.separationCylinderDataSource.entities.values
    const entity = entities.find(e => e.id === item.name)

    if (e.target.type === 'checkbox') {
      entity.show = e.target.checked
      this.updateParentStatus(entities)
    } else {
      e.preventDefault()
      this.cesiumViewer.selectedEntity = entity
    }
  }

  updateParentStatus (entities) {
    const trueCount = entities.filter(e => e.show === true).length

    if (trueCount === entities.length) {
      this.parentCheckBox.prop({
        indeterminate: false,
        checked: true
      })
    } else if (trueCount === 0) {
      this.parentCheckBox.prop({
        indeterminate: false,
        checked: false
      })
    } else {
      this.parentCheckBox.prop({
        indeterminate: true
      })
    }
  }

  updateChildStatus (status) {
    const entities = this.separationCylinderDataSource.entities.values
    entities.forEach((entity) => { entity.show = status })
    // separationCylinderDataSource.show = status
    this.table.find('input[type="checkbox"]').prop({ checked: status })
  }

  getCylinderDescription (item) {
    return `
      <table class="cesium-infoBox-defaultTable">
        <tbody>
          <tr>
            <th>Name</th>
            <td>${item.name}</td>
          </tr>
          <tr>
            <th>Target ID</th>
            <td>${this.sepEntity.id}</td>
          </tr>
          <tr>
            <th>Height (m)</th>
            <td>${item.height.toFixed(1)}</td>
          </tr>
          <tr>
            <th>Radius (m)</th>
            <td>${item.radius.toFixed(1)}</td>
          </tr>
        </tbody>
      </table>
    `
  }

  isValidJulianDate (JulianDate) {
    const isoDate = Cesium.JulianDate.toIso8601(JulianDate)
    return moment(isoDate).isValid()
  }
}
