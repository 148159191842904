import { Controller } from '@hotwired/stimulus'

import { digProperty } from '../src/mixins/dig'

// Displays an (optional) string message from a Live Drone or Live Remote
// Converts the string to a value held within lookup.
// Defaults to the default value if the message is nil or not provided
export default class extends Controller {
  static values = {
    field: String,
    default: String,
    lookup: Object
  }

  connect () {}

  disconnect () {}

  update ({ detail }) {
    const value = digProperty(this.fieldValue, detail)
    if (value != null) {
      const display = this.lookupValue[value] || value
      this.element.innerHTML = display
    } else {
      this.element.innerHTML = this.defaultValue
    }
  }
}
