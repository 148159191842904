import '../init/datatables'

import { handleAjaxError } from '../init/ajax'

/**
 * Initializes a basic Datatable
 */

export const withAjaxDatatable = (controller, datatableTarget, { source, columns, order, applyFilters, updateCallback }) => {
  const datatable = $(datatableTarget).DataTable({
    ajax: {
      url: source,
      type: 'POST',
      data: applyFilters,
      error: handleAjaxError
    },
    columns,
    order,
    drawCallback: (data) => {
      if (data && data.json) {
        updateCallback(data.json)
      }
    },
    stateSave: true, // remember the current sort order/page
    stateDuration: -1 // use session (per tab) storage
  })

  const controllerDisconnect = controller.disconnect.bind(controller)

  Object.assign(controller, {
    disconnect () {
      datatable.destroy()

      controllerDisconnect()
    }
  })

  return {
    reload () {
      datatable.ajax.reload()
    }
  }
}
