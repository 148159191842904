import { buildInfoWindowWithDescription } from './info-window'
import { getHighestWindowPositionForPolygon } from './polygon-utils'
import { convertCircleToPolygon } from './circle-utils'

const formatRadius = (radius) => {
  if (radius > 1000) {
    return `${(radius / 1000).toFixed(2)} km`
  } else {
    return `${radius.toFixed(1)} m`
  }
}

export default class {
  // Private variables
  #map
  #title
  #circle
  #circleAsPolygon
  #infoWindow

  constructor ({ map, title, onCircleSelected, onCircleAdded, onCircleRemoved }) {
    this.#map = map
    this.#circle = null
    this.#circleAsPolygon = null
    this.#infoWindow = null
    this.#title = title

    this.onCircleSelected = onCircleSelected
    this.onCircleAdded = onCircleAdded
    this.onCircleRemoved = onCircleRemoved
  }

  get hasCircle () {
    return this.#circle != null
  }

  getBounds () {
    return this.#circle ? this.#circle.getBounds() : new google.maps.LatLngBounds()
  }

  deselectCircle () {
    if (this.#infoWindow) {
      this.#infoWindow.setMap(null)
    }
  }

  placeCircle (googleCircle) {
    // Add Event Listeners
    googleCircle.addListener('click', this.selectCircle.bind(this))
    googleCircle.addListener('center_changed', this.#onCircleChanged.bind(this))
    googleCircle.addListener('radius_changed', this.#onCircleChanged.bind(this))

    // Set and Highlight the Circle
    this.#setCircle(googleCircle)
  }

  selectCircle () {
    if (this.#circle) {
      // TODO: Can we hightlight the circle?

      this.#infoWindow.open({
        map: this.#map,
        shouldFocus: false
      })

      this.onCircleSelected(this.#circle, this.#circleAsPolygon)
    }
  }

  removeCircle () {
    // Remove the Polygon and Info Window
    this.#circleAsPolygon = null

    if (this.#circle) {
      this.deselectCircle()
      this.#infoWindow = null

      const removedCircle = this.#circle
      removedCircle.setMap(null)
      this.#circle = null

      this.onCircleRemoved(removedCircle)
    }
  }

  /****************************
   * Events
   ****************************/

  #onCircleChanged () {
    this.#setCircle(this.#circle) // Force Rebuild
    this.selectCircle() // Force Select
  }

  /****************************
   * Utility Functions
   ****************************/

  #setCircle (circle) {
    // Remove any Modals/Highlights
    this.removeCircle()

    // Assign the Circle
    this.#circle = circle

    // Rebuild the circle and notify the world
    if (circle) {
      circle.setMap(this.#map) // handle reloading the circle

      // Rebuild the Polygon and Info Window
      this.#circleAsPolygon = convertCircleToPolygon(circle)
      const radiusHtml = formatRadius(circle.getRadius())
      this.#infoWindow = buildInfoWindowWithDescription({
        title: this.#title,
        content: `<div><b>Radius:</b> ${radiusHtml}</div>`
      })
      this.#infoWindow.setPosition(getHighestWindowPositionForPolygon(this.#circleAsPolygon))
      this.onCircleAdded(circle)
    }
  }
}
