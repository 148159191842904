/* global DOMParser */
import { Controller } from '@hotwired/stimulus'

import feather from 'feather-icons/dist/feather'

/**
 * Converts the element into a feather icon.
 *
 * Make sure you provide a data-feather attribute with the name of the icon.
 */
export default class extends Controller {
  connect () {
    const elementAttrs = getAttrs(this.element)
    const name = elementAttrs['data-feather']
    delete elementAttrs['data-feather']
    delete elementAttrs['data-controller'] // NOTE: prevents other controllers from being initialized

    const svgString = feather.icons[name].toSvg({
      ...elementAttrs
    })
    const svgDocument = new DOMParser().parseFromString(
      svgString,
      'image/svg+xml'
    )
    const svgElement = svgDocument.querySelector('svg')
    this.element.replaceWith(svgElement)
  }
}

/**
 * Get the attributes of an HTML element.
 * @param {HTMLElement} element
 * @returns {Object}
 */
function getAttrs (element) {
  return Array.from(element.attributes).reduce((attrs, attr) => {
    attrs[attr.name] = attr.value
    return attrs
  }, {})
}
