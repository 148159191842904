import { Controller } from '@hotwired/stimulus'

import { digProperty } from '../src/mixins/dig'

// Displays an (optional) string message from a Live Drone or Live Remote
// Defaults to the default value if the message is nil or not provided
export default class extends Controller {
  static values = {
    field: String,
    default: String,
    prefix: { type: String, default: '' },
    suffix: { type: String, default: '' }
  }

  connect () {}

  disconnect () {}

  update ({ detail }) {
    const value = digProperty(this.fieldValue, detail)
    if (value != null) {
      const display = [this.prefixValue, value.toLocaleString(), this.suffixValue]
      this.element.innerHTML = display.join('')
    } else {
      this.element.innerHTML = this.defaultValue
    }
  }
}
