import { Controller } from '@hotwired/stimulus'

import moment from 'moment'

// Displays the time from now for an element
export default class extends Controller {
  connect () {
    this.reload()
  }

  disconnect () {
    if (this.timeoutRef) clearTimeout(this.timeoutRef)
  }

  reload () {
    const momentTime = moment(this.element.getAttribute('datetime'))
    this.element.innerHTML = momentTime.fromNow()

    // Reload dates on the same day
    if (momentTime.diff(new Date(), 'days') === 0) {
      clearTimeout(this.timeoutRef)
      this.timeoutRef = setTimeout(() => this.reload(), 30 * 1000) // 30 seconds
    }
  }
}
