import { Controller } from '@hotwired/stimulus'

import { PolygonPicker } from '../src/display-map/polygon-picker'
import { FlightAdvisoryService } from '../src/flight-advisory/flight-advisory-service'

export default class extends Controller {
  static targets = ['polygonPicker']

  connect () {
    const $polygonPicker = $(this.polygonPickerTarget)
    const advisoryHost = $polygonPicker.data('curo-advisory-url')
    const flightAdvisoryService = new FlightAdvisoryService(advisoryHost)

    // Setup Standalone Polygon Picker
    const polygonPicker = new PolygonPicker($polygonPicker)
    polygonPicker.setupComplexDrawingManager()
    polygonPicker.setupStandaloneFlightAdvisory($polygonPicker, {
      advisoryHost,
      mapboxAccessToken: $polygonPicker.data('mapbox-access-token'),
      operationType: $polygonPicker.data('operation-type'),
      advisoriesLookup: ({ point, areas, operationType, operationDate, operationTime, maxAltitude }) => {
        return flightAdvisoryService.checkAdvisory({
          point,
          areas,
          operationType,
          operationDate,
          startTime: operationTime,
          endTime: operationTime,
          maxAltitude
        })
      }
    })
    polygonPicker.init()
  }
}
