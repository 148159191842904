// Australia is our home
const defaultStartPoint = { lat: -26.8937628, lng: 133.4914266 }

const MOBILE_TARGET_WIDTH = 768

const buildDefaultCenter = function () {
  return new google.maps.LatLng(defaultStartPoint.lat, defaultStartPoint.lng)
}

export const buildMapOfAustralia = (element) => {
  return new google.maps.Map(element, {
    center: buildDefaultCenter(),
    zoom: 3,
    scaleControl: true,
    mapId: 'mapid', // required to use AdvancedMapMarker
    mapTypeId: 'hybrid', // Hybrid is satellite with label
    mapTypeControlOptions: {
      mapTypeIds: ['satellite', 'roadmap', 'terrain', 'hybrid']
    },
    controlSize: 26
  })
}

export const resetViewportToAustralia = (map) => {
  const extendToBounds = 8

  // extend the bounds to entire Australia
  const australiaBounds = new google.maps.LatLngBounds(
    new google.maps.LatLng(defaultStartPoint.lat - extendToBounds, defaultStartPoint.lng - extendToBounds),
    new google.maps.LatLng(defaultStartPoint.lat + extendToBounds, defaultStartPoint.lng + extendToBounds)
  )

  // fit the map to the bounds
  map.fitBounds(australiaBounds)
}

export const fitViewportToBounds = (map, mapBounds) => {
  if (mapBounds.isEmpty()) return

  // Ensure we don't zoom in too far into the map
  const zoomChangeBoundsListener = google.maps.event.addListener(map, 'bounds_changed', function () {
    google.maps.event.removeListener(zoomChangeBoundsListener)
    map.setZoom(Math.min(19, map.getZoom()))
  })

  map.fitBounds(mapBounds)
}

export const resizeControlToWidth = (map, width) => {
  const enableFullscreen = width > MOBILE_TARGET_WIDTH

  map.setOptions({ fullscreenControl: enableFullscreen })
}
