export const withClickableRows = (clickableElement) => {
  $(clickableElement).on('click', 'tbody td', function (event) {
    event.preventDefault()

    // Ignore no-results table rows
    if ($(event.currentTarget).hasClass('dataTables_empty')) return

    let newUrl

    const clickedTargetLink = $(event.currentTarget).find('a')
    if (clickedTargetLink.length > 0) {
      // Visit the link in the the target cell
      newUrl = $(clickedTargetLink).attr('href')
    } else {
      // Visit the link in the first cell of the table
      const defaultRowLink = $(
        event.currentTarget.parentElement.firstElementChild
      ).find('a')

      if (defaultRowLink.length > 0) {
        newUrl = $(defaultRowLink).attr('href')
      }
    }

    // Return if no link found
    if (!newUrl) return

    // Open in new tab if ctrl/meta key is pressed
    if (event.ctrlKey || event.metaKey) {
      window.open(newUrl, '_blank')
    } else {
      Turbo.visit(newUrl)
    }
  })
}
