import { Controller } from '@hotwired/stimulus'

/**
 * Updates the page title based on the number of records in the datatable.
 * Assumes the page_title helper has been used to render the page title
 */
export default class extends Controller {
  static values = {
    title: String
  }

  connect () {
    this.titleValue ||= $('.page-title__heading').first().text()
  }

  updateFromDatatable ({ detail: { recordsTotal } }) {
    $('.page-title__heading').html(this.titleValue + ' (' + recordsTotal + ')')
  }
}
