import { FlightAdvisoryOverlay } from './flight-advisory-overlay'
import { FlightAdvisoryPanel } from './flight-advisory-panel'

const convertGooglePolygonToAdvisoryPolygon = (googlePolygon) => {
  const points = googlePolygon.getPath().getArray()
  return points.map((p) => { return [p.lat(), p.lng()] })
}

export const FlightAdvisory = class {
  constructor ($mapContainer, { map, mapboxAccessToken, advisoryHost, operationType, advisoriesLookup, isStandalone }) {
    this.advisoriesLookup = advisoriesLookup

    this.advisoryOverlay = new FlightAdvisoryOverlay($mapContainer, {
      map,
      mapboxAccessToken,
      advisoryHost,
      operationType,
      isStandalone: isStandalone || false
    })

    this.advisoryPanel = new FlightAdvisoryPanel($mapContainer, {
      advisoryHost
    })

    // Remember the current advisory area/point when switching operation type
    this.polygon = null
    this.point = null

    // Hide the flight advisory when requested
    $mapContainer.on('flight-advisory-hide', this.handleHideEvent.bind(this))
    $mapContainer.on('flight-advisory-resume', this.handleResumeEvent.bind(this))
    $mapContainer.on('flight-advisory-reload', this.reload.bind(this))
  }

  // Hides the advisory panel
  hidePanel () {
    this.advisoryPanel.hide()
  }

  // Displays the advisory panel
  showPanel () {
    this.advisoryPanel.show()
  }

  checkPolygon (polygon) {
    this.setPolygonState(polygon)
    this.checkFlightAdvisory({ areas: [polygon] })
  }

  checkPoint (point) {
    this.setPointState(point)
    this.checkFlightAdvisory({ point })
  }

  checkGoogleMarker (googleMarker) {
    this.checkPoint({
      latitude: googleMarker.position.lat,
      longitude: googleMarker.position.lng
    })
  }

  checkGooglePolygon (googlePolygon) {
    this.checkPolygon(
      convertGooglePolygonToAdvisoryPolygon(googlePolygon)
    )
  }

  setOperationType (operationType) {
    // Sets the operation type, triggering all event callbacks
    this.advisoryOverlay.changeOperationType(operationType)
  }

  checkFlightAdvisory ({ point, areas }) {
    const advisoryPrams = {
      operationType: this.operationType(),
      operationDate: this.operationDate(),
      operationTime: this.operationTime(),
      maxAltitude: this.maxAltitude()
    }
    if (point) advisoryPrams.point = point
    if (areas) advisoryPrams.areas = areas

    this.advisoriesLookup(advisoryPrams)
      .done((res) => {
        this.advisoryPanel.handleAdvisorySuccess(res)
      }).fail((err) => {
        this.advisoryPanel.handleAdvisoryError(err)
      })
  }

  // Resets the Flight Advisory to the default placeholder text
  clearFlightArea () {
    this.polygon = null
    this.point = null

    this.advisoryPanel.reset()
  }

  operationType () {
    return this.advisoryOverlay.operationType
  }

  operationDate () {
    return this.advisoryOverlay.operationDate()
  }

  operationTime () {
    return this.advisoryOverlay.operationTime()
  }

  maxAltitude () {
    return this.advisoryOverlay.maxAltitude()
  }

  // Hides the overlay panel, and remembers its previous state
  handleHideEvent () {
    this.was_shown = this.advisoryPanel.isVisible()
    this.hidePanel()
  }

  // Displays the Flight Advisory if previously hidden by a hide event
  handleResumeEvent () {
    if (this.was_shown) this.showPanel()
  }

  // Reloads the Flight Advisory if previously provided with a point or polygon
  reload () {
    if (this.polygon) this.checkPolygon(this.polygon)
    else if (this.point) this.checkPoint(this.point)
  }

  setPolygonState (polygon) {
    this.polygon = polygon
    this.point = null
  }

  setPointState (point) {
    this.point = point
    this.polygon = null
  }
}
