// NOTE: select2 will not work if a modal has not been displayed (shown)
// However, the modal does not always emit the shown.bs.modal event, so we fall back to a timeout
export const onModalShown = (controller, connectCallback) => {
  const $modalParent = $(controller.element).closest('.modal')

  // Check to see if we are actually within a modal
  if ($modalParent.length === 0) {
    // Invoke the callback
    connectCallback()

    // No need for a callback here
    return
  }

  const shownCallback = () => {
    // Prevent the shown callback from being called again
    clearTimeout(shownTimeout)
    $modalParent.off('shown.bs.modal', shownCallback)

    // Connect the Controller
    connectCallback()
  }

  // Listen to legacy shown events, or fallback to a timeout
  $modalParent.on('shown.bs.modal', shownCallback)
  const shownTimeout = setTimeout(shownCallback, 300) // should be longer than open animation

  const controllerDisconnect = controller.disconnect.bind(controller)

  Object.assign(controller, {
    disconnect () {
      clearTimeout(shownTimeout)
      $modalParent.off('shown.bs.modal', shownCallback)

      // Disconnect the Controller
      controllerDisconnect()
    }
  })
}
