import { Controller } from '@hotwired/stimulus'

/**
 * Retrieves the Hub Status from the HeteroConnect Service
 */
export default class extends Controller {
  static targets = ['listener']

  broadcastStatus ({ detail }) {
    // Broadcast the Hub Status to any listeners
    this.listenerTargets.forEach(listener => {
      this.dispatch('statusUpdate', { target: listener, detail })
    })
  }

  broadcastAdsb ({ detail }) {
    // Broadcast the ASDB (near this hub) to any listeners
    this.listenerTargets.forEach(listener => {
      this.dispatch('adsbUpdate', { target: listener, detail })
    })
  }
}
