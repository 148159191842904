import { Controller } from '@hotwired/stimulus'

import { withDatatableFilters } from '../src/mixins/with_datatable_filters'
import { withAjaxDatatable } from '../src/mixins/with_ajax_datatable'
import { withClickableRows } from '../src/mixins/with_clickable_rows'

export default class extends Controller {
  static targets = ['datatable', 'filter', 'summary', 'listener']
  static values = {
    basePath: String,
    source: String,
    columns: Array,
    order: Array,
    clickable: {
      type: Boolean,
      default: true
    }
  }

  connect () {
    // Add Datatable Filters
    const datatableFilters = withDatatableFilters(
      this,
      this.filterTarget,
      () => {
        this.ajaxDatatable.reload()
        if (history && history.replaceState && this.hasBasePathValue) {
          const params = new URLSearchParams()

          for (const [key, value] of Object.entries(datatableFilters.filters)) {
            params.append(`filter[${key}]`, value)
          }

          const url = new URL(this.basePathValue, window.location.origin)
          url.search = params.toString()

          const relativeUrl = url.pathname + url.search
          history.replaceState({ turbo: true, relativeUrl }, '', relativeUrl)
        }
      }
    )

    // Setup Ajax Datatable
    this.ajaxDatatable = withAjaxDatatable(this, this.datatableTarget, {
      source: this.sourceValue,
      columns: this.columnsValue,
      order: this.orderValue || [[0, 'asc']],
      applyFilters: (data) => {
        const filters = datatableFilters.filters
        for (const filter in filters) {
          data.filter ||= {}
          data.filter[filter] = filters[filter]
        }
      },
      updateCallback: (data) => {
        this.summaryTarget.innerHTML = data.filter_description
        this.broadcastDatatableUpdated(data)
      }
    })

    // Add Clickable Rows
    if (this.clickableValue) {
      withClickableRows(this.datatableTarget)
    }
  }

  broadcastDatatableUpdated (data) {
    // Broadcast to Listeners
    this.listenerTargets.forEach(listener => {
      this.dispatch('datatableUpdated', {
        target: listener,
        detail: data
      })
    })

    // Legacy Broadcast Globally
    this.dispatch('datatableUpdated', { detail: data })
  }
}
