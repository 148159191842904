import { Controller } from '@hotwired/stimulus'

/**
 * Displays a collection of expandable (and hideable) cards.
 *
 * Each child card should have a `data-expandable-cards-target` of `card`,
 * and should listen to `expandable-cards:expand` and `expandable-cards:hide` events
 */
export default class extends Controller {
  static targets = ['card']

  showAll () {
    this.cardTargets.forEach(card => {
      this.dispatch('show', {
        target: card,
        detail: {}
      })
    })
  }

  hideAll () {
    this.cardTargets.forEach(card => {
      this.dispatch('hide', {
        target: card,
        detail: {}
      })
    })
  }
}
