export const withStatusPolling = (controller, url, refreshRate, callback) => {
  if (!url) {
    throw new Error('url is required')
  }

  function fetchStatus () {
    $.ajax({
      url,
      method: 'GET',
      dataType: 'json',
      cache: 'no-cache',
      success: (res) => {
        callback(res)
      },
      error: function (error) {
        console.log('Unable to retreive: ' + url, error)
      }
    })
  }

  // Fetch the initial status
  setTimeout(fetchStatus, 0)

  // Setup the polling interval
  const pollingInterval = setInterval(fetchStatus, refreshRate)

  const controllerDisconnect = controller.disconnect.bind(controller)

  Object.assign(controller, {
    disconnect () {
      // Clear the polling interval
      clearInterval(pollingInterval)

      controllerDisconnect()
    }
  })
}
