/* global $ */
import { Controller } from '@hotwired/stimulus'

import toastr from '../src/init/toastr'

/**
 * Populates the incident asset form with the details of the selected drone
 *
 * TODO: This code was copied from jQuery and needs to be refactored
 */
export default class extends Controller {
  static values = {
    droneUrl: String
  }

  connect () {
    $(this.element).on('select2:select', '#incident_asset_sf_asset_id', this.selectDrone.bind(this))
    $(this.element).on('select2:unselect', '#incident_asset_sf_asset_id', this.unselectDrone.bind(this))
  }

  selectDrone (event) {
    $(this.element).find('input,select')
      .not(':input[type=submit],#incident_asset_sf_asset_id,#incident_asset_incident_user_id')
      .prop('disabled', true)
      .prop('readonly', true)

    const data = event.params.data
    const droneSFID = data.id
    const droneUrl = [this.droneUrlValue, droneSFID].join('')

    // Populate fields with the drone details
    $.ajax({ url: droneUrl })
      .fail(function (msg) {
        toastr.error('Unable to retreive Asset details!')
      })
      .done((data) => {
        const drone = data.drone
        for (const attribute in drone) {
          $(this.element).find(`[name="incident_asset[${attribute}]"]`).val(drone[attribute]).trigger('change')
        }
      })
  }

  unselectDrone (event) {
    $(this.element).find('input,select')
      .not(':input[type=submit],#incident_asset_sf_asset_id,#incident_asset_incident_user_id')
      .prop('disabled', false)
      .prop('readonly', false)
  }
}
