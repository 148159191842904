import MarkerDrone from '../../../images/marker-drone.svg'

export const DroneMarker = class {
  constructor (cesiumViewer, { droneId, droneName }) {
    this.entity = null
    this.dataSource = new Cesium.CzmlDataSource(`drone-data-source-${droneId}`)
    cesiumViewer.dataSources.add(this.dataSource)
    this.droneId = droneId
    this.droneName = droneName
  }

  addPosition (lat, lon, alt, timestamp) {
    const czmlPackage = this.buildDroneCzmlPackage(lat, lon, alt, timestamp)

    return this.dataSource.process(czmlPackage).then(() => {
      if (!this.entity) this.entity = this.dataSource.entities.getById(this.droneId)
    })
  }

  buildDroneCzmlPackage (lat, lon, alt, timestamp) {
    const currentTime = new Date(timestamp)
    const currentTimeIso = currentTime.toISOString()

    // 120 minute ago
    const startTimeIso = new Date(currentTime.getTime() - 120 * 60 * 1000).toISOString()
    // 1 minute from now
    const endTimeIso = new Date(currentTime.getTime() + 120 * 60 * 1000).toISOString()

    const documentInterval = startTimeIso + '/' + endTimeIso

    // Construct the CZML object for the path
    return [
      {
        id: 'document',
        version: '1.0',
        clock: {
          interval: documentInterval,
          currentTime: currentTimeIso,
          multiplier: 1
        }
      },
      {
        id: this.droneId,
        name: this.droneName,
        availability: documentInterval,
        path: {
          material: {
            solidColor: {
              color: {
                rgba: [255, 255, 0, 188]
              }
            }
          },
          width: 3,
          show: true
        },
        position: {
          interpolationAlgorithm: 'LAGRANGE',
          interpolationDegree: 2,
          forwardExtrapolationType: 'HOLD',
          epoch: startTimeIso,
          cartographicDegrees: [
            currentTimeIso, lon, lat, alt
          ]
        },
        billboard: {
          image: MarkerDrone,
          scaleByDistance: {
            nearFarScalar: [1.5e2, 0.3, 1.5e6, 0.15]
          }
        }
      }
    ]
  }
}
