import { Controller } from '@hotwired/stimulus'
import { useResize } from 'stimulus-use'

import {
  addGeolocationButton,
  addSearchBox
} from '../src/init/curo-map-utility'

import { buildHighlightedMarker } from '../src/display-map/marker-utils'

import PointDrawingTool from '../src/display-map/point-drawing-tool'
import { buildMapOfAustralia, fitViewportToBounds, resizeControlToWidth } from '../src/display-map/map-utils'

const parseCoordinate = function (value) {
  if (value && value !== '') {
    return Number(value)
  }
  return NaN
}

export default class extends Controller {
  static targets = ['lat', 'lng', 'map']
  static values = {
    title: String
  }

  #map

  connect () {
    this.#map = buildMapOfAustralia(this.mapTarget)
    this.pointDrawingTool = new PointDrawingTool({
      map: this.#map,
      onPointSelected: (googleMarker) => {
        $(this.latTarget).val(googleMarker.position.lat)
        $(this.lngTarget).val(googleMarker.position.lng)
      },
      onPointsRemoved: () => {
        $(this.latTarget).val('')
        $(this.lngTarget).val('')
      }
    })

    // Map control
    addGeolocationButton(this.#map)
    addSearchBox(this.#map, (googlePlaces) => {
      this.pointDrawingTool.placeSearchResults(googlePlaces)
      fitViewportToBounds(this.#map, this.pointDrawingTool.getBounds())
    })

    const initialMarker = this.markerFromInputs
    if (initialMarker) {
      this.pointDrawingTool.placeMarker(initialMarker)
      fitViewportToBounds(this.#map, this.pointDrawingTool.getBounds())
    }

    useResize(this, { element: this.mapTarget })
  }

  disconnect () {
    this.#map = undefined
  }

  get markerFromInputs () {
    const latitude = parseCoordinate($(this.latTarget).val())
    const longitude = parseCoordinate($(this.lngTarget).val())
    if (isNaN(latitude) || isNaN(longitude)) {
      return null
    }

    return buildHighlightedMarker({
      map: this.#map,
      title: this.titleValue || 'Placed Marker',
      latitude,
      longitude
    })
  }

  resize ({ width }) {
    if (this.#map) {
      resizeControlToWidth(this.#map, width)
      this.pointDrawingTool.resizeToWidth(width)
    }
  }
}
