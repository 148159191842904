import { Controller } from '@hotwired/stimulus'

import ApexCharts from 'apexcharts/dist/apexcharts'

export default class extends Controller {
  static targets = ['usersChart']

  disconnect () {
    if (this.usersChart) {
      this.usersChart.destroy()
    }
  }

  update ({ detail: { groupsUserTotal } }) {
    if (this.usersChart) this.usersChart.destroy()
    this.usersChart = this.buildUsersChart(groupsUserTotal)
    this.usersChart.render()
  }

  buildUsersChart (groupsUserTotal) {
    const options = {
      title: {
        text: 'Total Users per Group'
      },
      chart: {
        height: 191,
        type: 'bar',
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      series: groupsUserTotal,
      xaxis: {
        type: 'category',
        labels: { show: false }
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toFixed(0)
          }
        }
      },
      legend: {
        show: true,
        position: 'right',
        width: 260,
        itemMargin: {
          horizontal: 2,
          vertical: 2
        },
        showForSingleSeries: true
      }
    }

    return new ApexCharts(
      this.usersChartTarget,
      options
    )
  }
}
