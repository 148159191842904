import { Controller } from '@hotwired/stimulus'

import { withAjaxDatatable } from '../../src/mixins/with_ajax_datatable'
import { withClickableRows } from '../../src/mixins/with_clickable_rows'

export default class extends Controller {
  static targets = ['datatable']
  static values = {
    dateRange: String,
    source: String
  }

  connect () {
    withAjaxDatatable(this, this.datatableTarget, {
      source: this.sourceValue,
      columns: [
        { data: 'number' },
        { data: 'type' },
        { data: 'service_type' },
        { data: 'open_date' },
        { data: 'contact' },
        { data: 'group' },
        { data: 'status' }
      ],
      order: [[3, 'desc']],
      applyFilters: (data) => {
        data.filter ||= {}
        data.filter.created_between = this.dateRangeValue
      },
      updateCallback: (data) => {}
    })

    // Add Clickable Rows
    withClickableRows(this.datatableTarget)
  }
}
