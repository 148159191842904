import 'datatables.net-bs4'
import 'datatables.net-responsive-bs4'
import 'datatables.net-buttons-bs4'

// Init Datatables before we start the turbolink:load race
$.extend(true, $.fn.dataTable.defaults, {
  processing: true,
  serverSide: true,
  searchDelay: 800,
  deferRender: true,
  pageLength: 10,
  responsive: false,
  pagingType: 'full_numbers',
  language: {
    paginate: {
      previous: '<i class="uil uil-angle-left"></i>',
      next: '<i class="uil uil-angle-right"></i>'
    }
  },

  drawCallback: function () {
    $('.dataTables_paginate > .pagination').addClass('pagination-rounded')
  }
})

$.fn.dataTable.ext.errMode = 'throw'
