import { Controller } from '@hotwired/stimulus'

import '../src/init/datatables'

/**
 * Render a table in the style of DataTable, but without any features.
 */
export default class extends Controller {
  // datatable is a separate target as the element is replaced on init
  static targets = ['datatable']

  connect () {
    this.datatable = $(this.datatableTarget).DataTable({
      serverSide: false,
      searching: false,
      paging: false,
      ordering: false,
      info: false
    })
  }

  disconnect () {
    this.datatable.destroy()
  }
}
