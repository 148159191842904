// Input date is local date
// Output datetime is UTC

// point: { "latitude": -44.048989097823444, "longitude": 145.48849691249998 }
// area: [[lat,lon], [lat,lon], [lat,lon] ...]
// areas: [
//   area, area ...
// ]

export const FlightAdvisoryService = class {
  constructor (baseUrl) {
    this.baseUrl = baseUrl
    this.checkAdvisory = this.checkAdvisory.bind(this)
  }

  checkAdvisory ({
    operationType, maxAltitude, operationDate, startTime, endTime, point, areas
  }) {
    const url = this.baseUrl + '/v1/advisories_lookup'

    return $.ajax({
      dataType: 'json',
      url,
      method: 'POST',
      data: {
        advisories_lookup: {
          operation_type: operationType,
          point,
          areas: JSON.stringify(areas),
          maximum_altitude: maxAltitude,
          operation_date: operationDate,
          start_time: startTime,
          end_time: endTime
        }
      }
    })
  }

  checkAssessment ({
    operationType, maxAltitude, startDate, endDate, point, areas
  }) {
    const url = this.baseUrl + '/v1/assessment_lookup'

    return $.ajax({
      dataType: 'json',
      url,
      method: 'POST',
      data: {
        assessment_lookup: {
          operation_type: operationType,
          point,
          areas: JSON.stringify(areas),
          maximum_altitude: maxAltitude,
          start_date: startDate,
          end_date: endDate
        }
      }
    })
  }
}
