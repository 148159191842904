import { Controller } from '@hotwired/stimulus'

import { withDaterangepicker } from '../src/mixins/with_daterangepicker'

export default class extends Controller {
  static values = {
    format: { type: String, default: 'DD MMM YYYY' }
  }

  connect () {
    this.daterangepicker = withDaterangepicker(this, this.element, {
      autoUpdateInput: false,
      timePicker: false,
      singleDatePicker: false,
      locale: {
        cancelLabel: 'Clear',
        format: this.formatValue
      }
    })

    // Handle the Update Input Functions
    this.applyCallback = this.updateInputFromPicker.bind(this)
    this.clearCallback = this.clearInput.bind(this)
    $(this.element).on(
      'apply.daterangepicker', this.applyCallback
    ).on(
      'cancel.daterangepicker', this.clearCallback
    )

    if ($(this.element).val() !== '') {
      // Visually format the provided date
      this.updateInputFromPicker()
    }
  }

  disconnect () {
    // Disconnect the Handle Input Functions
    $(this.element).off(
      'apply.daterangepicker', this.applyCallback
    ).off(
      'cancel.daterangepicker', this.cancelCallback
    )
  }

  updateInputFromPicker () {
    this.updateInput(this.daterangepicker.startDate.format(this.formatValue) + ' - ' + this.daterangepicker.endDate.format(this.formatValue))
  }

  // Applies the value to the input element
  updateInput (value) {
    $(this.element).val(value).trigger('change')
  }

  clearInput () {
    this.updateInput('')
  }
}
