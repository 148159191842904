// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import delegate from './src/delegate'

// Provide jQuery to the global scope
window.$ = $

import '@rails/ujs' // eslint-disable-line import/first

import '@hotwired/turbo-rails' // eslint-disable-line import/first

// Setup ActionCable
require('./channels')

import 'bootstrap/dist/js/bootstrap' // eslint-disable-line import/first

import './src/init/toastr' // eslint-disable-line import/first

// Bootstrap Dropdowns
delegate('show.bs.dropdown', { parameters: ['event'] })

// Select2 changes
delegate('change', { parameters: ['event'] })

window.$ = $

// Setup Stimulus
import './controllers' // eslint-disable-line import/first
