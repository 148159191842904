import { Controller } from '@hotwired/stimulus'

import { withSummaryItems } from '../src/mixins/with_summary_items'

export default class extends Controller {
  static targets = ['totalFlights', 'averageDistance', 'averageAirtime', 'totalPilots']

  connect () {
    withSummaryItems(this)
  }

  update ({ detail: { totalFlights, averageDistance, averageAirtime, totalPilots } }) {
    this.presentSummaryItemNumber(this.totalFlightsTarget, totalFlights)
    this.presentSummaryItemNumber(this.averageDistanceTarget, averageDistance)
    this.presentSummaryItemNumber(this.averageAirtimeTarget, averageAirtime)
    this.presentSummaryItemNumber(this.totalPilotsTarget, totalPilots)
  }
}
