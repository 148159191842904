import toastr from '../init/toastr'

import { addClearMarkersButton, addClearShapeButton } from '../init/curo-map-utility'

import MarkerManager from './marker-manager'
import { highlightMarkerOpt } from './marker-utils'

const createDrawingManager = (map) => {
  const drawingManager = new google.maps.drawing.DrawingManager({
    drawingControl: true,
    drawingControlOptions: {
      position: google.maps.ControlPosition.TOP_CENTER,
      drawingModes: [google.maps.drawing.OverlayType.MARKER]
    },
    drawingMode: google.maps.drawing.OverlayType.MARKER, // Default Drawing Mode on init
    markerOptions: highlightMarkerOpt()
  })
  drawingManager.setMap(map)

  return drawingManager
}

class PointDrawingTool {
  #drawingManager
  #markerManager
  #map

  constructor ({ map, onPointSelected, onPointsRemoved }) {
    this.#drawingManager = createDrawingManager(map)
    this.#map = map
    this.#markerManager = new MarkerManager({
      map,
      onMarkerSelected: onPointSelected,
      onMarkersAdded: this.#refreshClearButtons.bind(this),
      onMarkersRemoved: () => {
        this.#refreshClearButtons.bind(this)
        onPointsRemoved()
      }
    })

    google.maps.event.addListener(
      this.#drawingManager,
      'markercomplete',
      this.placeMarker.bind(this)
    )
  }

  placeMarker = (marker) => {
    this.#markerManager.removeAllMarkers()
    this.#markerManager.addPlacedMarker(marker)
    this.#markerManager.selectMarkerAtIndex(0)
  }

  placeSearchResults (googlePlaces) {
    const placesWithGeometry = googlePlaces.filter((place) => place.geometry)

    if (placesWithGeometry.length === 0) {
      toastr.info('Nothing found. Maybe you can zoom to a more specific state/city and search again')
      return
    }

    this.#markerManager.removeAllMarkers()
    this.#markerManager.addSearchResults(placesWithGeometry)
    this.#markerManager.selectMarkerAtIndex(0)
    if (placesWithGeometry.length > 1) {
      this.#drawingManager.setDrawingMode(null) // hand drawing mode
    }
  }

  getBounds () {
    return this.#markerManager.getBounds()
  }

  rebuildClearButtons (position = google.maps.ControlPosition.TOP_CENTER) {
    if (this.clearButton) this.clearButton.remove()
    if (this.clearMarkersButton) this.clearMarkersButton.remove()

    this.clearButton = addClearShapeButton(this.#map, 'Remove Point', position)
    $(this.clearButton).on('click', () => { this.#markerManager.removeAllMarkers() })

    this.clearMarkersButton = addClearMarkersButton(this.#map, position)
    $(this.clearMarkersButton).on('click', () => { this.#markerManager.removeIdleMarkers() })

    this.#refreshClearButtons()
  }

  #refreshClearButtons () {
    if (this.#markerManager.hasMarkers) {
      $(this.clearButton).show()

      if (this.#markerManager.hasMultipleMarkers) $(this.clearMarkersButton).show()
    } else {
      $(this.clearButton).hide()
      $(this.clearMarkersButton).hide()
    }
  }

  resizeToWidth (width) {
    if (width < 768) {
      const position = google.maps.ControlPosition.LEFT_TOP

      this.#drawingManager.setOptions({
        drawingControlOptions: {
          position,
          drawingModes: [google.maps.drawing.OverlayType.MARKER]
        }
      })

      this.rebuildClearButtons(position)
    } else {
      const position = google.maps.ControlPosition.TOP_CENTER

      this.#drawingManager.setOptions({
        drawingControlOptions: {
          position,
          drawingModes: [google.maps.drawing.OverlayType.MARKER]
        }
      })
      this.rebuildClearButtons(position)
    }
  }
}

export default PointDrawingTool
