import { Controller } from '@hotwired/stimulus'

import { initDisplayMap } from '../src/init/curo-displaymap'
import { FlightAdvisoryService } from '../src/flight-advisory/flight-advisory-service'

export default class extends Controller {
  static values = {
    advisoryHost: String,
    mapboxAccessToken: String,
    operationType: String,
    startDate: String,
    endDate: String,
    maxAltitude: String,
    polygons: Array
  }

  connect () {
    const assessmentMap = initDisplayMap(this.element, {
      paths: [],
      points: [],
      polygons: this.polygonsValue || []
    })

    const advisoryHost = this.advisoryHostValue
    const flightAdvisoryService = new FlightAdvisoryService(advisoryHost)
    assessmentMap.addFlightAdvisory({
      advisoryHost,
      mapboxAccessToken: this.mapboxAccessTokenValue,
      operationType: this.operationTypeValue,
      advisoriesLookup: ({ point, areas, operationType }) => {
        return flightAdvisoryService.checkAssessment({
          point,
          areas,
          operationType,
          startDate: this.startDateValue,
          endDate: this.endDateValue,
          maxAltitude: this.maxAltitudeValue
        })
      }
    })
  }
}
