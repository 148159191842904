import { buildSimpleInfoWindow } from './info-window'
import { extendGetBoundsForPolygon, getHighestWindowPositionForPolygon } from './polygon-utils'

export default class {
  // Private variables
  #map
  #polygon
  #infoWindow

  constructor ({ map, onPolygonSelected, onPolygonAdded, onPolygonRemoved, title }) {
    this.#map = map
    this.#polygon = null
    this.#infoWindow = null
    this.title = title

    this.onPolygonSelected = onPolygonSelected
    this.onPolygonAdded = onPolygonAdded
    this.onPolygonRemoved = onPolygonRemoved

    extendGetBoundsForPolygon()
  }

  get hasPolygon () {
    return !!this.#polygon
  }

  getBounds () {
    return this.#polygon ? this.#polygon.getBounds() : new google.maps.LatLngBounds()
  }

  showPlacedPolygon (polygon) {
    polygon.addListener('click', this.selectPolygon.bind(this))

    polygon.addListener('mouseup', (e) => {
      if (e.path !== null) { this.#onPolygonChanged() }
    })

    this.#setPolygon(polygon)
  }

  deselectPolygon () {
    // TODO: Hide me

    if (this.#infoWindow) {
      this.#infoWindow.setMap(null)
    }
  }

  removePolygon () {
    if (this.#polygon) {
      this.deselectPolygon()
      this.#infoWindow = null

      const removedPolygon = this.#polygon
      removedPolygon.setMap(null)
      this.#polygon = null

      this.onPolygonRemoved(removedPolygon)
    }
  }

  selectPolygon (e) {
    if (this.#polygon) {
      // TODO: Highlight

      this.#infoWindow.setPosition(e ? e.latLng : getHighestWindowPositionForPolygon(this.#polygon))
      this.#infoWindow.open({
        map: this.#map,
        shouldFocus: false
      })

      this.onPolygonSelected(this.#polygon)
    }
  }

  #onPolygonChanged () {
    this.#rebuildPolygon()
    this.selectPolygon()
  }

  #rebuildPolygon () {
    // Remove any Modals/Highlights
    this.deselectPolygon()

    // Create the Info Window
    this.#infoWindow = buildSimpleInfoWindow(this.title)
  }

  #setPolygon (polygon) {
    this.removePolygon()

    this.#polygon = polygon

    if (polygon) {
      this.#rebuildPolygon()
      this.onPolygonAdded(polygon)
    }
  }
}
